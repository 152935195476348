import React, {useEffect, useRef, useState} from "react";
import AGGrid from "../../Components/AGGrid";
import {IAGrid} from "../../Components/IInterfaces";
import useStateWithCallback from "use-state-with-callback";
import OrgCellRenderer from "./OrgCellRenderer";
import {NetworkManager} from "../../Components/NetworkManager";
import {StatusCodeUtil} from "../../utils/StatusCodeUtil";
import ToastManager from "../../Components/ToastManager";
import DraggableComponent from "../../Components/DraggableComponent";
import {Modal} from "react-bootstrap";
import {CommonUtil} from "../../utils/CommonUtil";
import SingleSelectComp from "../../Components/SingleSelectComp";
import orgUserRenderer from "./OrgUserRenderer";
import OrgRoleRenderer from "./OrgRoleRenderer";
import orgUserStatucRenderer from "./OrgUserStatucRenderer";
import Confirm from "../../Components/confirm";
import '../../organization.css'
import $ from 'jquery'
import {Events} from "../Languages/Events";
import {groupByKey} from "../../utils/GroupByKey";
import {Constants} from "../Languages/Constants";
import DetectDevice from "../../utils/DetectDevice";
import MGrid from "../../Components/MGrid";
import { reAdjust, leftClickScroll, rightClickScroll} from "../../utils/ScrollHandler";
import PhoneInput from "react-phone-input-2";
var userMode:string = "";
var userObj:any = null;
var roleObject:any = null;
var rolePerms:any = [];
var isPermissionsUpdated:boolean = false;
var hidWidth;
const OrganizationDetails = (props:any)=>{
    //console.log(props);
    const { isMobile } = DetectDevice();
    const [showRolePopup,setShowRolePopup] = useState(false);
    const [showPermPopup,setShowPermPopup] = useState(false);
    const [showUserPopup,setShowUserPopup] = useState(false);
    const [showUserStatusPopup,setShowUerStatusPopup] = useState(false);
    const oprInterchange = useRef<HTMLInputElement>(null)
    const [interchangeChecked, setInterchangeChecked] = useState(false)
    const [genTab,setGenTab] = useState(false);
    const [roleTab,setRoleTab] = useState(false);
    const [userTab,setUserTab] = useState(true);

    const [orgName,setOrgName] = useState("");
    const [legalName,setLegalName] = useState("");
    const [address1,setAddress1] = useState("");
    const [city,setCity] = useState("");
    const [province,setProvince] = useState("");
    const [country,setCountry] = useState("");
    const [postalCode,setPostalCode] = useState("");
    const [phoneNumber,setPhoneNumber] = useState("");
    const [isLogoVisible,setLogoVisible] = useState(false);
    const [imageSrc,setImageSrc] = useState("");//../../NoLogo.jpg

    const [roleName,setRoleName] = useState("");

    const genref = useRef<HTMLButtonElement>(null);
    const roleref = useRef<HTMLButtonElement>(null);
    const usersref = useRef<HTMLButtonElement>(null);

    const roleRef = useRef<HTMLInputElement>(null);

    const divGenRef = useRef<HTMLDivElement>(null);
    const divRoleRef = useRef<HTMLDivElement>(null);
    const divUserRef = useRef<HTMLDivElement>(null);

    const [departments,setDepartments] = useState(CommonUtil.onlyAdminDepartment);
    const [roles,setRoles] = useState([]);


    const fnRef = useRef<HTMLInputElement>(null);
    const lnRef = useRef<HTMLInputElement>(null);
    const emailRef = useRef<HTMLInputElement>(null);
    const contactNoRef = useRef<HTMLInputElement>(null);

    const [phoneClass, setPhoneClass] = useState('mandatory');

    const [firstName,setFirstName] = useState("");
    const [lastName,setLastName] = useState("");
    const [email,setEmailId] = useState("");
    const [contactNumber,setContactNumber] = useState("");
    const [depObj,setDepObj] = useState<any>(null);
    const [roleObj,setRoleObj] = useState<any>(null);
    const [rolePermissions,setPermissions] = useState([]);
    const [page,setPage] = useState('');


    const roleGridRef = useRef<IAGrid>(null);
    const userGridRef = useRef<IAGrid>(null);
    const [rowData,setRowData] = useStateWithCallback([],()=>{});
    const [users,setUsers] = useStateWithCallback([],()=>{});

    const [userStatus,setUserStatus] = useState("");


    const [columnDefs] = useState([
        { field: 'roleName', headerName:CommonUtil.getMessageText(Constants.ROLE,'Role'),resizable:true,width:100,sortable:true,flex:1,tooltip: (params:any) => params.value,
            cellRenderer: OrgRoleRenderer,filter: 'agTextColumnFilter'},
        { field: 'permissionCount', headerName:CommonUtil.getMessageText(Constants.PERMISSION,'Permission'),resizable:true,flex:1,width:100,sortable:true,filter:'agTextColumnFilter'},
        { field: 'createdBy', headerName:CommonUtil.getMessageText(Constants.CREATED_BY,'Created By'),resizable:true,flex:1,width:100,sortable:true,filter: 'agNumberColumnFilter'},
        { field: 'creationDate', headerName:CommonUtil.getMessageText(Constants.CREATION_DATE,'Creation Date'),resizable:true,flex:1,width:100,sortable:true,filter: 'agNumberColumnFilter'},
        { field: 'lastUpdatedBy', headerName:CommonUtil.getMessageText(Constants.LAST_UPDATED_BY,'Last Updated By'),resizable:true,flex:1,width:100,sortable:true,filter: 'agNumberColumnFilter'},
        { field: 'lastUpdateDate', headerName:CommonUtil.getMessageText(Constants.LAST_UPDATED_ON,'Last Updated On'),resizable:true,flex:1,width:100,sortable:true,filter: 'agNumberColumnFilter'},
    ])

    const [userColumns] = useState([
        { field: 'loginId', headerName:CommonUtil.getMessageText(Constants.LOGIN_ID,'Login Id'),resizable:true,flex:1,width:100,sortable:true,tooltip: (params:any) => params.value,
            cellRenderer: orgUserRenderer,filter: 'agTextColumnFilter'},
        { field: 'roleName', headerName:CommonUtil.getMessageText(Constants.ROLE,'Role'),resizable:true,flex:1,width:100,sortable:true,filter: 'agNumberColumnFilter'},
        { field: 'firstName', headerName:CommonUtil.getMessageText(Constants.FIRST_NAME,'First Name'),resizable:true,flex:1,width:100,sortable:true,filter: 'agNumberColumnFilter'},
        { field: 'lastName', headerName:CommonUtil.getMessageText(Constants.LAST_NAME,'Last Name'),resizable:true,flex:1,width:100,sortable:true,filter: 'agNumberColumnFilter'},
        { field: 'department', headerName:CommonUtil.getMessageText(Constants.DEPARTMENT,'Department'),resizable:true,flex:1,width:100,sortable:true,filter: 'agNumberColumnFilter'},
        { field: 'status', headerName:CommonUtil.getMessageText(Constants.STATUS,'Status'),resizable:true,flex:1,width:100,sortable:true,filter: 'agNumberColumnFilter',cellRenderer: orgUserStatucRenderer},
    ])
    const requiredformat = ['Dashboard','Configuration','Reports', 'API HUB'];
    const sortPermissions = (sortData:any) => {
        const sorted = Object.keys(sortData)
            .sort()
            .reduce((accumulator:any, key:any, index:any) => {
                accumulator[requiredformat[index]] = sortData[requiredformat[index]];

                return accumulator;
            }, {});
        return sorted;
    }


    useEffect(()=>{
        reAdjust();
        userMode = CommonUtil.CREATE_MODE;
        //onClickGeneral();
        //onClickRoles();
        //divGenRef.current?.classList.add("orgShow");
        onClickUsers();
        var qParam = null;//localStorage.getItem("orgId");
        if(CommonUtil.isRootUser()){
            qParam = CommonUtil.getSaasOrgId();
        }else{
            qParam = CommonUtil.getSaasOrgId();
        }
        var orgUrl = "api/organization/"+qParam;
        NetworkManager.sendJsonRequest(orgUrl,"","","GET",true,onOrgDetailsSuccess,onLoginError);

        document.addEventListener(Events.SWITCH_CUSTOMER_EVENT, onSwitchCustomer);
        window.addEventListener('resize',function(e){
            reAdjust();
        })
        return () => {
            document.removeEventListener(Events.SWITCH_CUSTOMER_EVENT, onSwitchCustomer);
            window.removeEventListener('resize', function(){
                reAdjust();
            })
        }

        //api/organization/
    },[]);

    let onSwitchCustomer = ()=>{
        if(CommonUtil.isRootUser()){
            //navigate("orgList1");
        }else{
            //navigate("orgList1");
            Events.dispatchBreadCrumbEvent();
        }
        //unsubscribe(Events.SWITCH_CUSTOMER_EVENT,()=>{onSwitchCustomer1()});
        //navigate("/menu");
    }

    let onOrgDetailsSuccess = (data:any)=>{
        //console.log(data);
        if(data) {
            var restData = JSON.parse(data);
            if(restData.statusCode){
                ToastManager.showToast(StatusCodeUtil.getCodeMessage(restData.statusCode),"Error");
            }else{
                setOrgName(restData.orgName);
                setLegalName(restData.legalName);
                setAddress1(restData.address1);
                setCity(restData.city);
                setProvince(restData.province);
                setCountry(restData.country);
                setPostalCode(restData.postalCode);
                setPhoneNumber(restData.phoneNumber);
                if(restData.logoPath){
                    var qParam:string = "objectPath="+restData.logoPath;
                    NetworkManager.sendJsonRequest("api/session/downloadUrl","",qParam,"GET",true,onDownloadSuccess,onLoginError);
                }

            }
        }


    }
    let onDownloadSuccess = (data:any)=>{
        //console.log(data);
        setImageSrc(data);
    }
    let deSelectButtons = ()=>{
        //genref.current?.classList.remove("clsTabSelected");
        //roleref.current?.classList.remove("clsTabSelected");
        // usersref.current?.classList.remove("clsTabSelected");
        /*divGenRef.current?.classList.remove("orgShow");
        divRoleRef.current?.classList.remove("orgShow");
        divUserRef.current?.classList.remove("orgShow");
        divGenRef.current?.classList.add("orgHide");
        divRoleRef.current?.classList.add("orgHide");
        divUserRef.current?.classList.add("orgHide");*/

        $("#navUsers").removeClass("active");
        $("#navRoles").removeClass("active");
        $("#navGen").removeClass("active");
    }
    let onLoginError = (err:any)=>{
        console.log(err);
    }

    let onClickGeneral = ()=>{
        deSelectButtons();
        //genref.current?.classList.add("clsTabSelected");
        // divGenRef.current?.classList.add("orgShow");*/
        $("#navGen").addClass("active");
        setGenTab(true);
        setRoleTab(false);
        setUserTab(false);
    }

    let onClickRoles = ()=>{
        deSelectButtons();
        $("#navRoles").addClass("active");
        //divRoleRef.current?.classList.add("orgShow");*/
        setGenTab(false);
        setRoleTab(true);
        setUserTab(false);
        getAllRoles();

    }

    let onClickUsers = ()=>{
        setGenTab(false);
        setRoleTab(false);
        setUserTab(true);
        getAllUsers();
        getRolesBySaasOrg();
        deSelectButtons();
        $("#navUsers").addClass("active");
        //usersref.current?.classList.add("clsTabSelected");
        //divUserRef.current?.classList.add("orgShow");*/
    }

    let getRolesBySaasOrg = ()=>{
        var qParam = CommonUtil.getSaasOrgId();
        var orgUrl = "api/role/all?saasOrgId="+qParam;
        NetworkManager.sendJsonRequest(orgUrl,"","","GET",true,onSaasOrgRoles,onLoginError);
    }

    let onSaasOrgRoles = (dataObj:any)=>{
        if(dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                var roles = [];
                if(Array.isArray(restData.roles)){
                    roles = restData.roles;
                }else{
                    roles.push(restData.roles);
                }
                setRoles(roles);
            }
        }
    }

    let getAllRoles = ()=>{
        var qParam = CommonUtil.getSaasOrgId();
        var orgUrl = "api/role/all?saasOrgId="+qParam;
        NetworkManager.sendJsonRequest(orgUrl,"","","GET",true,onOrgGetAllRows,onLoginError);
    }

    let onOrgGetAllRows = (dataObj:any)=>{
        //console.log(dataObj);
        if(dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                var roles = [];
                if(Array.isArray(restData.roles)){
                    roles = restData.roles;
                }else{
                    roles.push(restData.roles);
                }
                //setUsers(users);
                setRowData(roles);
            }
        }
    }
    let getAllUsers = ()=>{
        setUsers([]);
        var qParam = CommonUtil.getSaasOrgId();
        var orgUrl = "api/organization/users?saasOrgId="+qParam;
        NetworkManager.sendJsonRequest(orgUrl,"","","GET",true,onOrgGetAllUsers,onLoginError);
    }

    let onOrgGetAllUsers = (dataObj:any)=>{
        //console.log(dataObj);
        if(dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                var users = [];
                if(Array.isArray(restData.users)){
                    users = restData.users;
                }else{
                    users.push(restData.users);
                }
                setUsers(users);
            }
        }
    }

    let onCloseRolePopup = ()=>{
        setShowRolePopup(false);
    }

    let onClickAddRole = ()=>{
        setRoleName("");
        setShowRolePopup(true);
        //setShowPermPopup(true);
    }

    let onClosePermPopup = ()=>{
        setShowPermPopup(false);
        if(isPermissionsUpdated){
            ToastManager.showToast(CommonUtil.getMessageText(Constants.PERMISSIONS_UPDATED,'Permission(s) Updated Successfully'),"Info");
        }
        isPermissionsUpdated = false;
        getAllRoles();
    }

    let onDepChange = (data:any)=>{
        setDepObj(data);
    }
    let onRoleChange = (data:any)=>{
        setRoleObj(data);
    }

    let onSaveRole = ()=>{
        var isRole:boolean =  CommonUtil.isMandatory(roleRef);
        if(isRole){
            var restObj:any = {};
            if(CommonUtil.isRootUser()){
                restObj.orgId = CommonUtil.getSaasOrgId();
                restObj.saasOrgId =  CommonUtil.getSaasOrgId();
            }else{
                restObj.orgId =  CommonUtil.getSaasOrgId();
                restObj.saasOrgId =  CommonUtil.getSaasOrgId();
            }
            restObj.roleName = roleRef.current?.value;
            restObj.roleDescription = "";
            restObj.status = "Active";
            restObj.isDefault =  CommonUtil.CHECKED;
            NetworkManager.sendJsonRequest("api/role/createRole",restObj,"","POST",true,onOrgRoleCreate,onLoginError);
        }
    }

    let onOrgRoleCreate = (data:any)=> {
        // console.log(data);
        if (data) {
            var restData = CommonUtil.parseData(data);
            if (restData) {
                onCloseRolePopup();
                ToastManager.showToast(CommonUtil.getMessageText(Constants.ROLE_CREATED,'Role Created Successfully'), "Info");
                getAllRoles();
            }
        }
    }

    let onClickRoleDetails = (dataObj:any)=>{
        console.log(dataObj);
        roleObject = dataObj;
        var roleUrl = "";
        roleUrl = "api/role/permission/all?saasOrgId="+CommonUtil.getSaasOrgId();
        NetworkManager.sendJsonRequest(roleUrl,"","","GET",true,onGetRolePermissions,onLoginError);
    }

    let onGetRolePermissions = (dataObj:any)=>{
        //console.log(dataObj);
        var permissions:any = [];
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                rolePerms = [];
                if(Array.isArray(restData.permissions)){
                    rolePerms = restData.permissions;
                }else{
                    rolePerms.push(restData.permissions);
                }

                let dashboards:any = [];
                let configurations:any = [];
                let reports:any = [];
                let apihubs:any = [];

                let tempArray:any = [];

                rolePerms.map((item:any,idx:number)=>{
                    item.checked = false;
                    if(item.permisionGroup == "Configuration"){
                        configurations.push(item);
                    }
                    if(item.permisionGroup == "Reports"){
                        reports.push(item);
                    }
                    if(item.permisionGroup == "API HUB"){
                        apihubs.push(item);
                    }
                    if(item.permisionGroup == "Dashboard"){
                        dashboards.push(item);
                    }
                });

                /*tempArray = tempArray.concat(dashboards);
                tempArray = tempArray.concat(configurations);
                tempArray = tempArray.concat(reports);
                tempArray = tempArray.concat(apihubs);

                console.log(tempArray);*/

                let sortData = groupByKey(rolePerms, 'permisionGroup');
                let sorted = sortPermissions(sortData);
                setPermissions(sorted);

                setShowPermPopup(true);
                getPermissionsByRole();

            }
        }
    }

    let getPermissionsByRole = ()=>{
        var roleUrl = "";
        roleUrl = "api/role/rolePermission/all?roleId="+roleObject.roleId;
        NetworkManager.sendJsonRequest(roleUrl,"","","GET",true,onGetPermissionsByRole,onLoginError);
    }

    let onGetPermissionsByRole = (dataObj:any)=>{
        //console.log(dataObj);
        var permArray:any = [];
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                if(Array.isArray(restData)){
                    permArray = restData;
                }else{
                    permArray.push(restData);
                }
            }
        }
        // var permissions:any = rolePermissions;//permissions;
        if(permArray && permArray.length>0){
            permArray.forEach((item:any,idx:number)=>{
                // console.log(item);
                for(var i=0;i<rolePerms.length;i++){
                    if(rolePerms[i].permissionId == item.permissionMasterId){
                        rolePerms[i].rolePermissionId = item.rolePermissionId;
                        rolePerms[i].checked = true;
                        break;
                    }
                }
            });
        }
        let sortData = groupByKey(rolePerms, 'permisionGroup');
        //console.log(rolePerms);
        let sorted = sortPermissions(sortData);
        setPermissions(sorted);
        setPage(""+Math.random()*1000000);
        /*var permissions:any = rolePermissions;//permissions;
        for(var i=0;i<permissions.length;i++){
            if(permissions[i].permissionId == item.permissionId){
                permissions[i].checked = e.currentTarget.checked;
                break;
            }
        }
        setPermissions(rolePermissions);
        setPage(""+Math.random()*1000000);*/
    }

    let onChangePermission = (item:any,e:any, perm:any)=>{
        var roleUrl = "";
        roleUrl = "api/role/rolePermission";
        if(e.currentTarget.checked){
            var restObj:any = {};
            restObj.permissionMasterId = item.permissionId;
            restObj.roleId = roleObject.roleId;
            restObj.saasOrgId = CommonUtil.getSaasOrgId();
            NetworkManager.sendJsonRequest(roleUrl,restObj,"","POST",true,onCreatePermissions,onLoginError);
        }else{
            roleUrl = "api/role/rolePermission/"+item.rolePermissionId;
            NetworkManager.sendJsonRequest(roleUrl,"","","DELETE",true,onDeletePermissions,onLoginError);
        }


        //console.log(item,e.currentTarget.checked);
        var permissions:any = rolePermissions;//permissions;
        for(var i=0;i<permissions[perm].length;i++){
            if(permissions[perm][i].permissionId == item.permissionId){
                permissions[perm][i].checked = e.currentTarget.checked;
                break;
            }
        }
        setPermissions(rolePermissions);
        setPage(""+Math.random()*1000000);
    }


    let onCreatePermissions = (dataObj:any)=> {
        // console.log(dataObj);
        isPermissionsUpdated = true;
        if (dataObj) {
            var restData = JSON.parse(dataObj);
            if (restData) {
                if (restData.statusCode) {
                    //ToastManager.showToast(StatusCodeUtil.getCodeMessage(restData.statusCode),"Error");
                } else {
                    //ToastManager.showToast("Permission Updated Successfully","Info");
                }
            }
        }
        getPermissionsByRole();
    }

    let onDeletePermissions = (dataObj:any)=>{
        isPermissionsUpdated = true;
        console.log(dataObj);
    }

    let onCloseUserPopup = ()=>{
        setShowUserPopup(false);
    }
    let onClickAddUser = ()=>{
        clearUserData();
        userMode = CommonUtil.CREATE_MODE;
        userObj = null;
        setShowUserPopup(true);
    }
    let onCreateUser = ()=>{
        var isFn:boolean = CommonUtil.isMandatory(fnRef);
        var isLN:boolean = CommonUtil.isMandatory(lnRef);
       // var isContact:boolean = CommonUtil.isMandatory(contactNoRef);
        var isMail:boolean = CommonUtil.isEmailMandatory(emailRef);

        var isContact:boolean = true;
        if (!contactNumber.trim() || contactNumber.length < 11) {
            isContact = false
            setPhoneClass('is-invalid')
        }

        if(isFn && isLN && isContact && isMail){
            var restObj:any = {};
            restObj.firstName = firstName;
            restObj.lastName = lastName;
            restObj.loginId = email;
            restObj.phone = contactNumber;
            restObj.department = depObj?depObj.Key:"";
            restObj.roleName = roleObj?roleObj.roleName:"";
            restObj.status = "Active";
            restObj.accessInterchangeMobileApplication = interchangeChecked?"Y":"N";
            if(userMode == CommonUtil.UPDATE_MODE){
                if(userObj){
                    restObj.userId = userObj.userId;
                }
            }
            var userUrl = "";
            var method = "POST";
            if(userMode == CommonUtil.CREATE_MODE){
                userUrl = "api/organization/inviteUser?saasOrgId="+CommonUtil.getSaasOrgId();
            }else{
                method = "PUT";
                userUrl = "api/organization/editUser?saasOrgId="+CommonUtil.getSaasOrgId();
            }

            NetworkManager.sendJsonRequest(userUrl,restObj,"",method,true,onUserCreate,onLoginError);
        }
    }

    let onUserCreate = (data:any)=>{
        if (data) {
            var restData = CommonUtil.parseData(data);
            if (restData) {
                onCloseUserPopup();
                var msg:string = "";
                if(userMode == CommonUtil.CREATE_MODE){
                    msg = CommonUtil.getMessageText(Constants.USER_CREATED_SUCCESS,'User Created Successfully');
                }else{
                    msg = CommonUtil.getMessageText(Constants.USER_UPDATED_SUCEESS,'User Updated Successfully');
                }
                ToastManager.showToast(msg, "Info");
                getAllUsers();
            }
        }
    }


    let onChangeInterchange = (e: any) => {
        setInterchangeChecked(e.currentTarget.checked)
        //onChangeOprBorder();
    }
    let onClickUserDetails = (userData:any)=>{
        console.log(userData);
        //console.log(userGridRef.current?.getSelectedRows());
        userMode = CommonUtil.UPDATE_MODE;
        userObj = userData;
        setFirstName(userData.firstName);
        setLastName(userData.lastName);
        setEmailId(userData.loginId);
        setContactNumber(userData.phone);
        setRoleObj({roleId:userData.roleId,roleName:userData.roleName});
        setInterchangeChecked((userData.accessInterchangeMobileApplication) == "Y"?true:false);
        //--regarding V20-1059 setting admin options
        // setDepObj({Key:userData.department,Value:userData.department});
        try{
            setDepObj(departments[0]);
        }catch(e){console.log("departments not found")}
        setShowUserPopup(true);
    }
    let onClickActiveUser = (userData:any)=>{
        //console.log(userData);
        if(userData.status == CommonUtil.ACTIVE || userData.status == CommonUtil.IN_ACTIVE){
            userObj = userData;
            setUserStatus(userObj.status);
            setTimeout(()=>{
                setShowUerStatusPopup(true);
            })
        }
    }
    let onClickUserStatus = ()=>{
        //console.log(userObj);
        if(userObj){
            let restObj:any = {};
            restObj.department = userObj.department;
            restObj.firstName = userObj.firstName;
            restObj.language = userObj.language;
            restObj.lastName = userObj.lastName;
            restObj.loginId = userObj.loginId;
            restObj.roleName = userObj.roleName;
            restObj.phone = userObj.phone;
            restObj.userId = userObj.userId;
            restObj.status = userObj.status == CommonUtil.ACTIVE?CommonUtil.IN_ACTIVE:CommonUtil.ACTIVE;

            let changeStatusAPI:string = "api/organization/changeUserStatus?saasOrgId="+CommonUtil.getSaasOrgId();
            NetworkManager.sendJsonRequest(changeStatusAPI,restObj,"","PUT",true,onChangeUserStatus,onLoginError);
        }
    }

    let onChangeUserStatus = (dataObj:any)=>{
        if(dataObj) {
            var restData = JSON.parse(dataObj);
            if (restData) {
                if (restData.statusCode) {
                    //setErrorMsg(StatusCodeUtil.getCodeMessage(restData.statusCode));
                    ToastManager.showToast(StatusCodeUtil.getCodeMessage(restData.statusCode),"Error");
                } else {
                    setShowUerStatusPopup(false);
                    ToastManager.showToast(CommonUtil.getMessageText(Constants.USER_STATUS_UPDATED_SUCEESS,'User Status Updated Successfully'),"Info");
                    getAllUsers();
                }
            }
        }
    }
    let onClickResend = (userData:any)=>{
        userObj = userData;
        Confirm.show(CommonUtil.getMessageText(Constants.CONFIRM,'Confirm'), CommonUtil.getMessageText(Constants.DO_WANT_RESEND_IVITATION,'Do you want to Resend Invitation?'), [{label:"Yes", callback:function(){
                console.log(userData);
                let restObj:any = {};
                restObj.department = userData.department;
                restObj.firstName = userData.firstName;
                restObj.language = userData.language;
                restObj.lastName = userData.lastName;
                restObj.loginId = userData.loginId;
                restObj.roleName = userData.roleName;
                restObj.phone = userData.phone;
                restObj.userId = userData.userId;

                let resendAPI:string = "api/organization/resendInvitation?saasOrgId="+CommonUtil.getSaasOrgId();
                NetworkManager.sendJsonRequest(resendAPI,restObj,"","POST",true,onReInviteUser,onLoginError);

            }}, {label:"No"}]);

    }



    let onReInviteUser = (dataObj:any)=>{
        if(dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                ToastManager.showToast(CommonUtil.getMessageText(Constants.RESEND_INVITATION_SUCCESS,'Resend Invitation Successfully'),"Info");
            }
        }
    }
    let onCloseUserStausPopup = ()=>{
        setShowUerStatusPopup(false);
    }
    let clearUserData = ()=>{
        setFirstName("");
        setLastName("");
        setEmailId("");
        setContactNumber("");
        setDepObj(null);
        setRoleObj(null);
    }
    const handleInputPhone = (e: any) => {
        setContactNumber(e);
    }
    let addNewRole = ()=>{
        //console.log("new Record");
        onClickAddRole();
    }

    return(
        <>
            <Modal show={showUserStatusPopup} dialogAs={DraggableComponent}>
                <div className="modal-content" style={{ width: '600px', height: '200px' }}>
                    <div className="modal-header">
                        <h6 className="modal-title darkgrey-text fw-600">{userStatus == CommonUtil.ACTIVE ? CommonUtil.getMessageText(Constants.INACTIVE_USER,'Inactivate User') : CommonUtil.getMessageText(Constants.ACTIVE_USER,'Activate User')}</h6>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                onClick={onCloseUserStausPopup}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row fs-7">
                            { userStatus == CommonUtil.ACTIVE ? (<span className="dark-text fs-7">
                                {CommonUtil.getMessageText(Constants.PREVENT_ORG,'This action will prevent the user from using the vHub Application')}
                                </span>) : ( <span className="dark-text fs-7">
                                            {CommonUtil.getMessageText(Constants.ACCESS_ORG,'This action will allow the user to access the vHub Application')}
                                </span>
                            )}
                        </div>
                    </div>
                    <div className="modal-footer clsModalPermissionsFooter">
                        <button type="button" className="btn btn-outline-dark clsBoldText me-2 cancel-btn fw-600"
                                data-bs-dismiss="modal" onClick={onCloseUserStausPopup}>
                            Cancel
                        </button>
                        <button type="button" className="btn clsBoldText driver-btn fw-600" onClick={onClickUserStatus}>
                            {userStatus == CommonUtil.ACTIVE ? CommonUtil.getMessageText(Constants.BLOCK_USER,'Block User') : CommonUtil.getMessageText(Constants.ACTIVATE,'Activate')}
                        </button>
                    </div>
                </div>
            </Modal>
            <Modal show={showRolePopup} dialogAs={DraggableComponent}>
                <div className="modal-content" style={{ width: '500px', height: '300px' }}>
                    <div className="modal-header">
                        <h6 className="modal-title darkgrey-text fw-600">
                            {CommonUtil.getMessageText(Constants.ADD_NEW_ROLE,'Add New Role')}
                        </h6>
                        <button type="button" className="close" data-dismiss="modal"
                                aria-label="Close" onClick={onCloseRolePopup}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="clsModalBodyPermissions">
                            <div className="mb-2 mt-3">
                                    <span className="grey-text clsStandardText css-16ihq8s">
                                     {CommonUtil.getMessageText(Constants.ROLE_NAME,'Role Name')}
                                    </span>
                                <input ref={roleRef} type="text"
                                       className="form-control clsStandardText clsFormInputControl" placeholder={CommonUtil.getMessageText(Constants.ENTER_ROLE_NAME,'Enter Role Name')}
                                       aria-label="Role" value={roleName}
                                       onChange={(e) => {
                                           setRoleName(e.currentTarget.value)
                                           CommonUtil.isMandatory(roleRef)
                                       }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer clsModalPermissionsFootr">
                        <button type="button" className="btn btn-outline-dark"
                                data-bs-dismiss="modal" onClick={onCloseRolePopup}>
                            {CommonUtil.getMessageText(Constants.CLOSE,'Close')}
                        </button>
                        <button type="button" className="btn btn-success" onClick={onSaveRole}>
                            {CommonUtil.getMessageText(Constants.SAVE,'Save')}
                        </button>
                    </div>
                </div>
            </Modal>
            <Modal show={showUserPopup} dialogAs={DraggableComponent}>
                <div className="modal-content" style={{"width": "600px","height":"500px"}}>
                    <div className="modal-header">
                        <h5 className="modal-title mt-0"> {userMode === CommonUtil.UPDATE_MODE ? CommonUtil.getMessageText(Constants.EDIT_USER,'Edit User') : CommonUtil.getMessageText(Constants.ADD_NEW_USER,'Add New User')} </h5>
                        <button type="button" className="close" data-dismiss="modal"
                                aria-label="Close" onClick={onCloseUserPopup}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div className="modal-body">
                        <div className="row">
                            <div className="col-md-6 col-12">
                                <div className="form-group row">
                                    <label className="col-sm-12 col-form-label">
                                        {CommonUtil.getMessageText(Constants.FIRST_NAME,'First Name')}  <span className="text-danger"></span>
                                    </label>
                                    <div className="col-sm-12">
                                        <input ref={fnRef} type="text" className={firstName?"form-control clsStandardText":"form-control clsStandardText  clsFormInputControl"} placeholder={CommonUtil.getMessageText(Constants.FIRST_NAME,'First Name')} value={firstName}
                                               onChange={(e) => {
                                                   setFirstName(e.currentTarget.value)
                                                   CommonUtil.isMandatory(fnRef)
                                               }}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6 col-12">
                                <div className="form-group row">
                                    <label className="col-sm-12 col-form-label">
                                        {CommonUtil.getMessageText(Constants.LAST_NAME,'Last Name')} <span className="text-danger"></span>
                                    </label>
                                    <div className="col-sm-12">
                                        <input ref={lnRef} type="text" className={lastName?"form-control clsStandardText":"form-control clsStandardText  clsFormInputControl"}
                                               placeholder={CommonUtil.getMessageText(Constants.LAST_NAME,'Last Name')} value={lastName}
                                               onChange={(e) => {
                                                   setLastName(e.currentTarget.value)
                                                   CommonUtil.isMandatory(lnRef)
                                               }}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6 col-12">
                                <div className="form-group row">
                                    <label className="col-sm-12 col-form-label">
                                        {CommonUtil.getMessageText(Constants.CONTACT_NO,'Contact Number')} <span className="text-danger"></span>
                                    </label>
                                    <div className="col-sm-12">
                                        <PhoneInput
                                            country={'us'}
                                            countryCodeEditable={false}
                                            containerClass={phoneClass}
                                            value={contactNumber}
                                            placeholder={CommonUtil.getMessageText(Constants.ENTER_MOBILE_NUMBER, "Enter Phone Number")}
                                            inputClass={`phone-valid ${contactNumber && contactNumber.toString().length === 11 ? '' : 'inputMandatory'}`}
                                            onChange={(e) => {
                                                handleInputPhone(e)
                                            }}
                                            containerStyle={{
                                                width: '100%',
                                            }}
                                            disableCountryGuess={true}
                                            disableDropdown={true}
                                            disableSearchIcon={true}
                                        />
                                        {/* <input ref={contactNoRef} type="text"
                                               className="form-control clsStandardText  clsFormInputControl"
                                               placeholder="Contact Number"  aria-label="Contact Number" value={contactNumber}
                                               onChange={(e)=>{setContactNumber(e.currentTarget.value);CommonUtil.isMandatory(contactNoRef);}} /> */}
                                    </div>
                                </div>
                            </div>


                            <div className="col-md-6 col-12">
                                <div className="form-group row">
                                    <label className="col-sm-12 col-form-label">
                                        {CommonUtil.getMessageText(Constants.EMAIL_ID,'Email ID')}  <span className="text-danger"></span>
                                    </label>
                                    <div className="col-sm-12">
                                        <input ref={emailRef} type="text" className={email?"form-control clsStandardText":"form-control clsStandardText  clsFormInputControl"}
                                               placeholder={CommonUtil.getMessageText(Constants.ENTER_EMAIL,'Enter Email')} aria-label="Email" value={email}
                                               disabled={userMode === CommonUtil.UPDATE_MODE}
                                               onChange={(e) => {
                                                   setEmailId(e.currentTarget.value)
                                                   CommonUtil.isEmailMandatory(emailRef)
                                               }}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6 col-12" style={{display:"none"}}>
                                <div className="form-group row">
                                    <label className="col-sm-12 col-form-label">
                                        {CommonUtil.getMessageText(Constants.DEPARTMENT,'Department')} <span className="text-danger"></span>
                                    </label>
                                    <div className="col-sm-12">
                                        <SingleSelectComp dataSource={departments} selectedValue={depObj}
                                                          handleChange={onDepChange} width={'100%'} value={'Key'} label={'Value'}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-12">
                                <div className="form-group row">
                                    <label className="col-sm-12 col-form-label">
                                        {CommonUtil.getMessageText(Constants.ROLE,'Role')} <span className="text-danger"></span>
                                    </label>
                                    <div className="col-sm-12" style={{ zIndex: 9 }}>
                                        <SingleSelectComp dataSource={roles} selectedValue={roleObj}
                                                          handleChange={onRoleChange} width={'100%'} value={'roleId'} label={'roleName'}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 col-12">
                                <div className="flex">
                                    <input
                                        ref={oprInterchange}
                                        type="checkbox"
                                        className="checkBox"
                                        id="chkOperateCanada"
                                        name="OperateCanada"
                                        value="OperateCanada"
                                        onChange={(e) => {
                                            onChangeInterchange(e)
                                        }}
                                        checked={interchangeChecked ? true : false}
                                    />&nbsp;
                                    <label className="" htmlFor="customCheck1">Access Interchange Mobile Application</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-outline-dark"
                                data-dismiss="modal" onClick={onCloseUserPopup}>
                            {CommonUtil.getMessageText(Constants.CLOSE,'Cancel')}
                        </button>
                        <button type="submit" className="btn btn-success" onClick={onCreateUser}>
                            {CommonUtil.getMessageText(Constants.SAVE,'Save')}
                        </button>
                    </div>
                </div>
            </Modal>
            <Modal show={showPermPopup} dialogAs={DraggableComponent}>
                <div className="modal-content" style={{ width: '500px', height: '500px' }}>
                    <div className="modal-header">
                        <h6 className="modal-title darkgrey-text fw-600">
                            {CommonUtil.getMessageText(Constants.UPDATE_PERMISSIONS,'Update Permissions')}
                        </h6>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onClosePermPopup}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body" style={{ overflow: 'auto' }}>
                        <form className="px-4" >
                            <div className="mb-4 mt-3 px-2">
                                {rolePermissions && Object.keys(rolePermissions).map((perm:any, index:any)=>{
                                    let list:any = rolePermissions[perm];
                                    return (
                                        <>
                                            <label>{perm === 'Configuration' ? 'Administration' : perm}</label>
                                            <ul style={{ listStyleType:'none' }} >
                                                {list.length > 0  && list.map((listItem:any)=>{
                                                    return (
                                                        <li className="form-switch ps-0 py-2 mx-2 col-md-12 col-11">
                                                            <span className="d-inline">{listItem.permissionName}</span>
                                                            <input className="form-check-input float-end" type="checkbox" role="switch" style={{ position:'relative' }}
                                                                   onChange={(e) => {
                                                                       onChangePermission(listItem, e, perm)
                                                                   }}
                                                                   checked={listItem.checked ? true : false}
                                                            />
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                        </>
                                    )
                                    console.log('permss', rolePermissions[perm])
                                })}
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer clsModalPermissionsFooter">
                        {/* <button type="button" className="btn clsBoldText me-2 cancel-btn" data-bs-dismiss="modal"
                                onClick={onClosePermPopup}>Cancel</button>
                        <button type="button" className="btn clsBoldText driver-btn">Save</button>*/}
                    </div>
                </div>
            </Modal>


            <div className="page-content-wrapper">
                <div className="container-fluid container-fluid-padding">
                    {/* <div className="list-details-tabs"> */}
                        <div className={`profile-tabs row`} style={{ justifyContent:'center' }} >
                                <div className="scroller scroller-left float-left" onClick={leftClickScroll} style={{display:'none', width:'fit-content'}}><i className="fa fa-chevron-left" aria-hidden="true"></i></div>
                                <div className="wrapper-nav m-0" id="wrapper-nav">
                                    <nav className="nav nav-tabs list col-12 px-3" id="myTab" role="tablist">
                                        <a id={"navUsers"} className={`nav-item nav-link active ${isMobile ? '' : 'col'}`} onClick={onClickUsers} data-bs-toggle="tab" role="tab" aria-controls="public" aria-expanded="true" aria-selected="true" >{CommonUtil.getMessageText(Constants.USERS,'Users')}</a>
                                        <a id={"navRoles"} className={`nav-item nav-link ${isMobile ? '' : 'col'}`} onClick={onClickRoles} data-bs-toggle="tab" role="tab" aria-controls="public" aria-expanded="true" aria-selected="true"> {CommonUtil.getMessageText(Constants.ROLES_AND_PERMISSIONS,'Roles & Permissions')}</a>
                                        <a id={"navGen"} className={`nav-item nav-link ${isMobile ? '' : 'col'}`} onClick={onClickGeneral} data-bs-toggle="tab" role="tab" aria-controls="public" aria-expanded="true" aria-selected="true">  {CommonUtil.getMessageText(Constants.GENERAL_INFORMATION,'General Information')} </a>
                                    </nav>
                                </div>
                                <div className="scroller scroller-right float-right" onClick={rightClickScroll} style={{display:'none', width:'fit-content'}}><i className="fa fa-chevron-right" aria-hidden="true"></i></div>
                        {/* <div className="d-md-block mt-1">
                            <ul className="nav nav-tabs nav-justified bg-primary pl-3 pr-3" role="tablist">
                                <li className="nav-item waves-effect waves-light" onClick={onClickUsers}>
                                    <a id={"navUsers"} className="nav-link active" data-toggle="tab" role="tab" >{CommonUtil.getMessageText(Constants.USERS,'Users')}</a>

                                </li>
                                <li className="nav-item waves-effect waves-light" onClick={onClickRoles}>
                                    <a id={"navRoles"} className="nav-link" data-toggle="tab" role="tab"> {CommonUtil.getMessageText(Constants.ROLES_AND_PERMISSIONS,'Roles & Permissions')}</a>

                                </li>
                                <li className="nav-item waves-effect waves-light" onClick={onClickGeneral}>
                                    <a id={"navGen"} className="nav-link" data-toggle="tab" role="tab" >  {CommonUtil.getMessageText(Constants.GENERAL_INFORMATION,'General Information')} </a>

                                </li>
                            </ul>
                        </div> */}
                    {genTab && (
                        <div className="tab-content p-0 pt-3 pb-3 three-tab">
                            <div role="tabpanel" className="tab-pane fade active show mt-2" id="General-Information"
                                 aria-labelledby="public-tab" aria-expanded="true">
                                <div className="org-container">
                                    <section>
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12 text-center">
                                                <div className="picture-container">
                                                    <div className="picture">
                                                        <img src={imageSrc} alt="" style={{ width: '130px' }} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12" style={{paddingTop:"30px"}}>
                                                <div className="row">
                                                    <div className="col-12 col-sm-4">
                                                        <div className="form-group row">
                                                            <label className="col-sm-12 col-form-label">
                                                                <a data-toggle="modal"
                                                                   style={{textDecoration: 'none', color: '#007bff', cursor: 'pointer'}}
                                                                   data-target=".bs-example-modal-center-register">
                                                                    {CommonUtil.getMessageText(Constants.COMPANY_NAME_DBA,'Company Name (DBA)')}
                                                                </a>
                                                            </label>
                                                            <div className="col-sm-12">
                                                                <input type="text" className="form-control" placeholder={CommonUtil.getMessageText(Constants.COMPANY_NAME_DBA,'Company Name (DBA)')}
                                                                       value={orgName} disabled={true} readOnly
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-4">
                                                        <div className="form-group row">
                                                            <label className="col-sm-12 col-form-label">
                                                                {CommonUtil.getMessageText(Constants.LEGAL_NAME,'Legal Name')}
                                                            </label>
                                                            <div className="col-sm-12">
                                                                <input type="text" className="form-control" placeholder=""
                                                                       value={legalName} aria-label="Legal Name" disabled={true} readOnly
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-4"></div>

                                                    <div className="col-md-4 col-12">
                                                        <div className="form-group row">
                                                            <label className="col-sm-12 col-form-label">
                                                                {CommonUtil.getMessageText(Constants.ADDRESS,'Address')}
                                                            </label>
                                                            <div className="col-sm-12">
                                                                <input type="text" className="form-control" placeholder=""
                                                                       aria-label="Address1" value={address1} disabled={true} readOnly
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-4 col-12">
                                                        <div className="form-group row">
                                                            <label className="col-sm-12 col-form-label">
                                                                {CommonUtil.getMessageText(Constants.CITY,'City')}
                                                            </label>
                                                            <div className="col-sm-12">
                                                                <input type="text" className="form-control" placeholder=""
                                                                       aria-label="City" value={city} disabled={true} readOnly
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-12">
                                                        <div className="form-group row">
                                                            <label className="col-sm-12 col-form-label">
                                                                {CommonUtil.getMessageText(Constants.PROVINCE,'Province')}
                                                            </label>
                                                            <div className="col-sm-12">
                                                                <input type="text" className="form-control" placeholder=""
                                                                       aria-label="City" value={province} disabled={true} readOnly
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-4 col-12">
                                                        <div className="form-group row">
                                                            <label className="col-sm-12 col-form-label">
                                                                {CommonUtil.getMessageText(Constants.POSTAL_CODE,'Postal Code')}
                                                            </label>
                                                            <div className="col-sm-12">
                                                                <input type="text" className="form-control" placeholder=""
                                                                       aria-label="Postal Code" value={postalCode} disabled={true} readOnly
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-4 col-12">
                                                        <div className="form-group row">
                                                            <label className="col-sm-12 col-form-label">
                                                                {CommonUtil.getMessageText(Constants.COUNTRY,'Country')}
                                                            </label>
                                                            <div className="col-sm-12">
                                                                <input type="text" className="form-control" placeholder=""
                                                                       aria-label="Postal Code" value={country} disabled={true} readOnly
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-4 col-12">
                                                        <div className="form-group row">
                                                            <label className="col-sm-12 col-form-label">
                                                                {CommonUtil.getMessageText(Constants.PHONE_NO,'Phone Number')}
                                                            </label>
                                                            <div className="col-sm-12">
                                                                <input type="text" className="form-control" placeholder=""
                                                                       aria-label="Phone Number" value={phoneNumber} disabled={true} readOnly
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                            <div className="row fs-7 clsOrgGenFormButtonRow" style={{ display: 'none' }}>
                                <div className="col-md-2"></div>
                                <div className="col-md-3"></div>
                                <div className="col-md-2"></div>
                                <div className="col-md-3 clsAlignRight">
                                    <button className="btn btn-outline-dark">
                                        {CommonUtil.getMessageText(Constants.DISCARD_CHANGES,'Discard Changes')}
                                    </button>
                                    <button type="submit" className="btn btn-success">
                                        {CommonUtil.getMessageText(Constants.SAVE,'Save')}
                                    </button>
                                </div>
                                <div className="col-md-2"></div>
                            </div>
                        </div>
                    )}
                    {/* {genTab && (
            <div ref={divGenRef} className="container-fluid">
              <div className="container-fluid mt-4">
                <div className="row fs-7">
                  <div className="col-md-12 clsOrgGenLogo">
                    <img src={imageSrc} />
                  </div>
                </div>
              </div>
              <div className="container-fluid mt-4">
                <div className="row fs-7">
                  <div className="col-md-2"></div>
                  <div className="col-md-3" style={{ paddingLeft: '5px' }}>
                    <span className="grey-text css-16ihq8s">
                      Company Name (DBA)
                    </span>
                    <input
                      type="text"
                      className="form-control clsStandardText clsBoldText"
                      placeholder="Company Name(DBA)"
                      value={orgName}
                      aria-label="Company Name(DBA)"
                      disabled={true}
                    />
                  </div>
                  <div className="col-md-3">
                    <span className="grey-text css-16ihq8s">Legal Name</span>
                    <input
                      type="text"
                      className="form-control clsStandardText clsBoldText"
                      placeholder="Legal Name"
                      value={legalName}
                      aria-label="Legal Name"
                      disabled={true}
                    />
                  </div>
                  <div className="col-md-2"></div>
                  <div className="col-md-2"></div>
                </div>
              </div>
              <div className="row fs-7 clsOrgGenAddressRow">
                <div className="col-md-2"></div>
                <div className="col-md-3">
                  <span className="grey-text css-16ihq8s">Address</span>
                  <input
                    type="text"
                    className="form-control clsStandardText"
                    placeholder="Enter Address"
                    aria-label="Address1"
                    value={address1}
                    disabled={true}
                  />
                </div>
                <div className="col-md-3">
                  <span className="grey-text css-16ihq8s">City</span>
                  <input
                    type="text"
                    className="form-control clsStandardText"
                    placeholder="Enter City"
                    aria-label="City"
                    value={city}
                    disabled={true}
                  />
                </div>
                <div className="col-md-2">
                  <span className="grey-text">Province</span>
                  <input
                    type="text"
                    className="form-control clsStandardText"
                    placeholder="Enter City"
                    aria-label="City"
                    value={province}
                    disabled={true}
                  />
                </div>
                <div className="col-md-2"></div>
              </div>
              <div className="row fs-7">
                <div className="col-md-2"></div>
                <div className="col-md-3">
                  <span className="grey-text css-16ihq8s">Postal Code</span>
                  <input
                    type="text"
                    className="form-control clsStandardText"
                    placeholder="Enter Postal Code"
                    aria-label="Postal Code"
                    value={postalCode}
                    disabled={true}
                  />
                </div>
                <div className="col-md-3">
                  <span className="grey-text css-16ihq8s">Country</span>
                  <input
                    type="text"
                    className="form-control clsStandardText"
                    placeholder="Enter Country"
                    aria-label="Postal Code"
                    value={country}
                    disabled={true}
                  />
                </div>
                <div className="col-md-2">
                  <span className="grey-text css-16ihq8s">Phone Number</span>
                  <input
                    type="text"
                    className="form-control clsStandardText"
                    placeholder="Enter Phone Number"
                    aria-label="Phone Number"
                    value={phoneNumber}
                    disabled={true}
                  />
                </div>
                <div className="col-md-2"></div>
              </div>
              <div
                className="row fs-7 clsOrgGenFormButtonRow"
                style={{ display: 'none' }}
              >
                <div className="col-md-2"></div>
                <div className="col-md-3"></div>
                <div className="col-md-2"></div>
                <div className="col-md-3 clsAlignRight">
                  <button className="btn clsFormButtonNormal">
                    Discard Changes
                  </button>
                  <button type="submit" className="btn clsFormButtonDefault">
                    Save
                  </button>
                </div>
                <div className="col-md-2"></div>
              </div>
            </div>
          )} */}
                    {roleTab && (
                        <div className={`tab-content p-0 pt-3 three-tab ${isMobile ? '' : 'pb-3'} px-3`}>
                            <div ref={divRoleRef} className="container-fluid clsOrgRolesSearchRow">
                                <div className="row fs-7" style={{ display: 'none' }}>
                                    <div className="col-md-3"></div>
                                    <div className="col-md-2"></div>
                                    <div className="col-md-3"></div>
                                    <div className="col-md-4 clsSearchCntr">
                                        <div className="row">
                                            <div className="col-md-12 clsAlignRight">
                                                <div className="clsOrgRolesSearchCntr">
                                                    <div className="input-group" style={{ display: 'none' }}>
                                                        <span className="input-group-text" id="basic-addon1">
                                                          <i className="fa-solid fa-magnifying-glass"></i>
                                                        </span>
                                                        <input type="text" className="form-control clsStandardText"
                                                               placeholder="Search" aria-label="Search" aria-describedby="basic-addon1"
                                                        />
                                                    </div>
                                                </div>
                                                <button className="btn clsFormButtonDefault clsOrgRolesAddBtn" onClick={onClickAddRole}>
                                                    {CommonUtil.getMessageText(Constants.ADD_ROLE,'Add Role')}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row fs-7" style={{ paddingTop: isMobile ? '' : '5px' }}>
                                    {
                                        isMobile ? (
                                            <MGrid id="OrgRoleGrid" cols={columnDefs} rows={rowData} pageSize={10}
                                                addNew={true} tooltip={'Add Role'} headerHeight={CommonUtil.GRID_HEADER_HEIGHT}
                                                rowHeight={CommonUtil.GRID_ROW_HEIGHT} ref={roleGridRef} method={{ onClickRoleDetails }} addNewRecord={onClickAddRole} />
                                        ):(
                                            <div className="ag-theme-alpine gridHeight gridpadding" style={{ height: 'calc(100vh - 200px)', width: '100%' }}>
                                                <AGGrid id="OrgRoleGrid" cols={columnDefs} rows={rowData} pageSize={500}
                                                        addNew={true} tooltip={'Add Role'} headerHeight={CommonUtil.GRID_HEADER_HEIGHT}
                                                        rowHeight={CommonUtil.GRID_ROW_HEIGHT} ref={roleGridRef} method={{ onClickRoleDetails }} addNewRecord={onClickAddRole}
                                                />
                                            </div>)
                                    }
                                </div>
                            </div>
                        </div>
                    )}
                    {userTab && (
                        <div className={`tab-content p-0 pb-3 three-tab ${isMobile ? '' : 'pt-3'} px-3`}>
                            <div ref={divUserRef} className="container-fluid clsOrgRolesSearchRow">
                                <div className="row fs-7" style={{ display: 'none' }}>
                                    <div className="col-md-3"></div>
                                    <div className="col-md-2"></div>
                                    <div className="col-md-3"></div>
                                    <div className="col-md-4 clsSearchCntr">
                                        <div className="row">
                                            <div className="col-md-12 clsAlignRight">
                                                <div className="clsOrgRolesSearchCntr">
                                                    <div className="input-group" style={{ display: 'none' }}>
                                                            <span className="input-group-text" id="basic-addon1">
                                                              <i className="fa-solid fa-magnifying-glass"></i>
                                                            </span>
                                                        <input type="text" className="form-control clsStandardText"
                                                               placeholder="Search" aria-label="Search" aria-describedby="basic-addon1" />
                                                    </div>
                                                </div>
                                                <button className="btn clsFormButtonDefault clsOrgRolesAddBtn" onClick={onClickAddUser}>
                                                    {CommonUtil.getMessageText(Constants.ADD_USER,'Add User')}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row fs-7" style={{ paddingTop: isMobile ? '' : '2px' }}>
                                    {
                                        isMobile ? (
                                            <MGrid id="OrgUserGrid" cols={userColumns} rows={users} pageSize={10} addNew={true} tooltip={'Add User'}
                                                headerHeight={CommonUtil.GRID_HEADER_HEIGHT} rowHeight={CommonUtil.GRID_ROW_HEIGHT} ref={userGridRef}
                                                method={{onClickUserDetails, onClickActiveUser, onClickResend,}} addNewRecord={onClickAddUser}/>
                                        ):(
                                            <div className="ag-theme-alpine gridHeight gridpadding" style={{ height: 'calc(100vh - 200px)', width: '100%' }}>
                                        <AGGrid id="OrgUserGrid" cols={userColumns} rows={users} pageSize={500} addNew={true} tooltip={'Add User'}
                                                headerHeight={CommonUtil.GRID_HEADER_HEIGHT} rowHeight={CommonUtil.GRID_ROW_HEIGHT} ref={userGridRef}
                                                method={{onClickUserDetails, onClickActiveUser, onClickResend,}} addNewRecord={onClickAddUser}/>
                                    </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    )}

                    </div>
                </div>
            </div>
        </>
    )
}

export default OrganizationDetails;
