import React, {useEffect, useRef, useState} from "react";
import {CommonUtil} from "../../utils/CommonUtil";
import {NetworkManager} from "../../Components/NetworkManager";
import BusyLoader from "../../Components/BusyLoader";
import {loadMapApi} from "../../utils/GoogleMapsUtils";
import $ from "jquery";
import {StatusCodeUtil} from "../../utils/StatusCodeUtil";
import ToastManager from "../../Components/ToastManager";
import {useNavigate} from "react-router-dom";
import Confirm from "../../Components/confirm";
import {Constants} from "../Languages/Constants";
import {DragNDrop} from "../../utils/DragDrop";

var departureLotId:string = "";
var departureLat:number = 0;
var departureLng:number = 0;
var departureAddress:string = "";

var arrivalLotId:string = "";
var arrivalLat:number = 0;
var arrivalLng:number = 0;
var arrivalAddress:string = "";

var currLat:number = 0;
var currLng:number = 0;
var gMap:any = {};


var equiImages:any = [];
var attchIdx:number = 0;

var equiImages1:any = [];
var attchIdx1:number = 0;

var attachmentList: any = [];
var attachmentList1: any = [];
var damageImagesCount:number = 0;
var imageBinaryData:any = null;
var imagePath:string = "";
var imageFileName:string = "";

var documentType:string = "";
var category:string = "";
var description:string = "";
var unitMeasurement:string = "Kilometers";
var addDamageImages:boolean = false;

var licensePath:string = "";
var licenseNumber:string = "";

var hodoMeterPath:string = "";
var hodometerNumber:string = "";

var reeferPath:string = "";
var reeferNumber:string = "";
var unitNumber:string = "";

var podPath:string = "";

var rating:string = "0";
var formattedAddress:string = "";
var picWidth:number = 500;
var picHeight:number = 250;
var myLatlng1:any = null;
var myLatlng:any = null;
var distanceInMeters = 0;
var equipLicensePlateNumber = "";

let uploadType = "";
let UPLOAD_TYPE_CAMERA = "CAMERA";
let UPLOAD_TYPE_GALLARY = "GALLARY";

let panelType = "";
let LICENSE_PANEL = "LICENSE";
let DAMAGES_PANEL = "DAMAGES";
let HODOMETER_PANEL = "HODOMETER";
let POD_PANEL = "POD";
let REEFER_PANEL = "REEFER";
let INSPECTION_PANEL = "INSPECTION";

let inspectionScreenTitleCopy = "";
//user,environment
const width = window.innerWidth;
const height = window.innerHeight;
const aspectRatio = width / height;
const reverseAspectRatio = height / width;
//width < 600 ? mobileAspectRatio : aspectRatio
const mobileAspectRatio = reverseAspectRatio > 1.5
    ? reverseAspectRatio + (reverseAspectRatio * 12 / 100)
    : reverseAspectRatio

var videoConstraints = {
    video: {
        facingMode: {exact: 'environment'},
        width: {max: 800},
        height: {max: 768},
        aspectRatio: 1.5,
        audio:false,
        sharpness: true,
        brightness: true
    }
}




//var inspectionScreenTitle:string = "";
//var inspectionImageFormat:string = "";


const ReservationActivationLocation = (props:any)=>{
    const [dropLocation,setDropLocation] = useState(false);
    const mapRef = useRef<HTMLDivElement>(null);

    const [address,setAddress] = useState("");
    const [phoneNum,setPhoneNum] = useState("");
    const [parkingLot,setParkingLot] = useState("");
    const [instructions,setInstructions] = useState("");
    const [twicAccess,setTwicAccess] = useState(false);

    const [userName,setUserName] = useState("");
    const [userPhoneNum,setUserPhoneNum] = useState("");
    const [userEmail,setUserEmail] = useState("");
    const [orgName,setOrgName] = useState("");

    const [equipmentData,setEquipmentData] = useState<any>(null);
    const [assetType,setAssetType] = useState("");
    const [equipType,setEquipType] = useState("");
    const [unitNumber,setUnitNumber] = useState("");
    const [vinNumber,setVinNumber] = useState("");

    const [equipName,setEquipName] = useState("");

    const [brand,setBrand] = useState("");
    const [model,setModel] = useState("");
    const [year,setYear] = useState("");

    const [numOfAxes,setNumOfAxes] = useState("");
    const [trailerLength,setTrailerLength] = useState("");
    const [license,setELicense] = useState("");
    const [expDate,setExpDate] = useState("");

    const [axelSpread,setAxelSpread] = useState("");
    const [tireInflation,setTireInflation] = useState("");
    const [skirts,setSkirts] = useState("");
    const [pintleHook,setPintleHook] = useState("");
    const [hazmat,setHazmat] = useState("");
    const [suspension,setSuspension] = useState("");

    const [rearDoor,setRearDoor] = useState("");
    const [sideDoor,setSideDoor] = useState("");
    const [roof,setRoof] = useState("");
    const [floor,setFloor] = useState("");
    const [floorType,setFloorType] = useState("");
    const [interior,setInterior] = useState("");
    const [logistics,setLogistics] = useState("");
    const [deckBeams,setDeckBeams] = useState("");

    const [liftType,setLiftType] = useState("");
    const [lift,setLift] = useState("");
    const [liftCap,setLiftCap] = useState("");

    const [unitCapacity,setUnitCapacity] = useState("");
    const [unitMake,setUnitMake] = useState("");
    const [unitModal,setUnitModal] = useState("");
    const [unitYear,setUnitYear] = useState("");
    const [multiTemp,setMultiTemp] = useState("");

    const [equipImagesPath, setEquipImagesPath] = useState<any>([]);
    const [equipDriverSidePath, setEquipDriverSideImagesPath] = useState<any>([]);

    const [inspectionScreenTitle,setInspectionScreenTitle] = useState("");
    const [inspectionImageFormat,setInspectionImageFormat] = useState("");

    const videoRef = useRef<HTMLVideoElement>(null);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const imgLicenseRef = useRef<HTMLImageElement>(null);



    const videodmgRef = useRef<HTMLVideoElement>(null);
    const canvasdmgRef = useRef<HTMLCanvasElement>(null);
    const imgdmgRef = useRef<HTMLImageElement>(null);



    const videoHodoRef = useRef<HTMLVideoElement>(null);
    const canvasHodoRef = useRef<HTMLCanvasElement>(null);
    const imgHodoRef = useRef<HTMLImageElement>(null);



    const videoEngineRef = useRef<HTMLVideoElement>(null);
    const canvasEngineRef = useRef<HTMLCanvasElement>(null);
    const imgEngineRef = useRef<HTMLImageElement>(null);

    const videoPODRef = useRef<HTMLVideoElement>(null);
    const canvasPODRef = useRef<HTMLCanvasElement>(null);
    const imgPODRef = useRef<HTMLImageElement>(null);


    const videoDSideRef = useRef<HTMLVideoElement>(null);
    const canvasDSideRef = useRef<HTMLCanvasElement>(null);

    const imgDSideRef = useRef<HTMLImageElement>(null);



    const fileref = useRef<HTMLInputElement>(null);

    const chkLocal = useRef<HTMLInputElement>(null);
    const chkConfirm = useRef<HTMLInputElement>(null);

    const licenseRef = useRef(null);
    const [licenseNum,setLicenseNum] = useState("");

    const vinRef = useRef(null);
    const [trailerIdNum,setTrailerIdNum] = useState("");

    const descRef = useRef(null);
    const hodometerRef = useRef(null);

    const engineHoursRef = useRef(null);

    const [desc,setDesc] = useState("");
    const [hodometer,setHodometer] = useState("");
    const [enginehours,setEngineHours] = useState("");


    const [page,setPage] = useState("");
    const [damages,setDamages] = useState(false);

    const [deActiveInstruction,setDeActive] = useState("");
    const [activeInstruction,setActivative] = useState("");

    const [fileNmae, setFileName] = useState('');
    const [uploadImagePath, setUploadImagePath] = useState<any>(null);

    const dropFileObj = React.useRef<any>(null);


    const navigate = useNavigate();


    useEffect(()=>{
        //console.log(CommonUtil.SELECT_EQUIP_AVAIL_ID);

        console.log(width,height,aspectRatio,reverseAspectRatio,mobileAspectRatio,videoConstraints);
        let galArray = [imgLicenseRef,videoRef,videodmgRef,imgdmgRef,imgHodoRef,videoHodoRef,videoPODRef,imgPODRef,imgEngineRef,videoEngineRef,imgDSideRef,videoDSideRef];
        galArray.forEach((item:any,idx:number)=>{
            DragNDrop({drop:item, handleDragOver, handleDrop, handleDragEnter, handleDragLeave});
        })
       // DragNDrop({drop:imgLicenseRef, handleDragOver, handleDrop, handleDragEnter, handleDragLeave});
       // DragNDrop({drop:videoRef, handleDragOver, handleDrop, handleDragEnter, handleDragLeave})

       // const videodmgRef = useRef<HTMLVideoElement>(null);
        //const canvasdmgRef = useRef<HTMLCanvasElement>(null);
        //const imgdmgRef = useRef<HTMLImageElement>(null);

        if(CommonUtil.getMesurementByCountry() == "MILES"){
            unitMeasurement = "Miles";
        }else{
            unitMeasurement = "Kilometers";
        }
        licensePath = "";
        reeferPath = "";
        hodoMeterPath = "";
        podPath = "";
        equipLicensePlateNumber = "";
        distanceInMeters = 0;
        formattedAddress = "";
        rating = "0";
        licenseNumber = "";
        hodometerNumber = "";
        departureLotId = "";
        arrivalLotId = "";
        arrivalLat = 0;
        arrivalLng = 0;
        arrivalAddress = "";
        panelType = LICENSE_PANEL;
        setEngineHours("");

        getAvailabilityDetails();
        hidePanels();

        //getEquipmentDetails();
       // getEquipmentnspectionDetails();
        //showGoogleMap();
       // getOrganizationInfo();
    },[]);


    let getAvailabilityDetails = ()=>{
        if(CommonUtil.SELECT_EQUIP_AVAIL_ID){
            let reservationAPI:string = "api/session/rp/reservation/"+CommonUtil.SELECT_RESERVATION_ID+"?rpKey="+CommonUtil.RP_KEY;
            NetworkManager.sendJsonRequest(reservationAPI,"","","GET",true,onGetReservationDetails,onLoginError);

            //let parkingAPI:string = "api/session/rp/availability/"+CommonUtil.SELECT_EQUIP_AVAIL_ID+"?rpKey="+CommonUtil.RP_KEY;
           // NetworkManager.sendJsonRequest(parkingAPI,"","","GET",true,onGetAvailabilityDetails,onLoginError);
        }
    }

    let onGetAvailabilityDetails = (dataObj:any)=> {
       // console.log(dataObj);
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                console.log(restData);
                departureLotId = restData.departureParkingLotId;
                arrivalLotId = restData.arrivalParkingLotId;
                departureLat = Number(restData.departureParkingLotLatitude);
                departureLng = Number(restData.departureParkingLotLongitude);
                departureAddress = restData.departureParkingLotAddress;

                arrivalLat = Number(restData.arrivalParkingLotLatitude);
                arrivalLng = Number(restData.arrivalParkingLotLongitude);
                arrivalAddress = restData.arrivalParkingLotAddress;

                getParkingLotDetails();
                showGoogleMap();
            }
        }
    }
    let onGetReservationDetails = (dataObj:any)=>{
        //console.log(dataObj);
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                departureLotId = restData.departureParkingLotId;
                arrivalLotId = restData.arrivalParkingLotId;
                departureLat = Number(restData.departureParkingLotLatitude);
                departureLng = Number(restData.departureParkingLotLongitude);
                departureAddress = restData.departureParkingLotAddress;

                arrivalLat = Number(restData.arrivalParkingLotLatitude);
                arrivalLng = Number(restData.arrivalParkingLotLongitude);
                arrivalAddress = restData.arrivalParkingLotAddress;

                getParkingLotDetails();
                showGoogleMap();

            }
        }
    }

    let showGoogleMap = ()=>{
        if (window.google) {
            loadGoogleMap();
        } else {
            const googleMapScript = loadMapApi();
            if (googleMapScript) {
                googleMapScript.addEventListener('load', function () {
                    loadGoogleMap();
                });
            }
        }
    }

    let loadGoogleMap = () => {
        if (mapRef.current) {
            gMap = new google.maps.Map(mapRef.current, {
                zoom: 15, center: new google.maps.LatLng(departureLat, departureLng),
                gestureHandling: 'greedy', streetViewControl: true, zoomControl: true, fullscreenControl: true,
                draggableCursor: 'pointer',
                fullscreenControlOptions: {position: google.maps.ControlPosition.TOP_RIGHT},
                mapTypeControlOptions: {position: google.maps.ControlPosition.BOTTOM_RIGHT},
            });
            //console.log(gMap);
            if (gMap) {
                var latlngbounds:any = null;
                var marker:any = null;
                latlngbounds = new google.maps.LatLngBounds();
               // gMap.addListener("idle", () => {
                if(CommonUtil.RESERVATION_CATEGORY == CommonUtil.RESERVATION_ACTIVATION) {
                    myLatlng = new google.maps.LatLng(departureLat, departureLng);
                    marker = new google.maps.Marker({position: myLatlng, map: gMap, title: departureAddress});
                }else{
                    myLatlng = new google.maps.LatLng(arrivalLat, arrivalLng);
                    marker = new google.maps.Marker({position: myLatlng, map: gMap, title: arrivalAddress});
                }
                     latlngbounds.extend(myLatlng);
                    if (navigator.geolocation) {
                        navigator.geolocation.getCurrentPosition((position) => {
                            //console.log(position);
                            currLat = position.coords.latitude;
                            currLng = position.coords.longitude;
                             myLatlng1 = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
                            let marker1 = new google.maps.Marker({position: myLatlng1, map: gMap,
                                icon: {url: "https://maps.google.com/mapfiles/ms/icons/blue-dot.png"},title:"Driver Location"});
                            latlngbounds.extend(myLatlng1);
                            getGoogleMapAddress();
                            var mapCenter = latlngbounds.getCenter();
                            if (latlngbounds) {
                                if (gMap) {
                                    gMap.fitBounds(latlngbounds);
                                    gMap.setCenter(mapCenter);
                                    resizeMap();
                                }
                            }
                        },(error:any)=>{
                            //console.log(error);
                        });
                    }
               // });



            }
        }
    }

    let getGoogleMapAddress = ()=>{
        if(myLatlng1 && !formattedAddress){
            const geocoder = new google.maps.Geocoder();
            geocoder
                .geocode({ location: myLatlng1 })
                .then((response) => {
                    if (response.results[0]) {
                        console.log(response);
                        formattedAddress = response.results[0].formatted_address;
                    } else {
                        //window.alert("No results found");
                    }
                })
                .catch((e) => console.log("Geocoder failed due to: " + e));



            CommonUtil.getDistanceBetweenPoints(myLatlng,myLatlng1,(dataObj:any)=>{
                if(dataObj && dataObj.distance && dataObj.distance.value){
                    distanceInMeters = dataObj.distance.value;
                }
            });
        }

    }

    let resizeMap = ()=>{
        if(gMap){
           // var google = window.google;
            var center = gMap.getCenter();
            google.maps.event.trigger(gMap, "resize");
            gMap.setCenter(center);
            //console.log(gMap.getBounds());
            if(gMap.getBounds()){
                //gMap.fitBounds(gMap.getBounds());
            }
        }
    }

    let getParkingLotDetails = ()=>{
       // getUserDetails();
        let lotId = arrivalLotId;
        if(CommonUtil.RESERVATION_CATEGORY == CommonUtil.RESERVATION_ACTIVATION){
            lotId = departureLotId;
        }
        if(lotId){
            let parkingAPI:string = "api/session/rp/parkinglot/"+lotId+"?rpKey="+CommonUtil.RP_KEY;;
            NetworkManager.sendJsonRequest(parkingAPI,"","","GET",true,onGetParkingLotDetails,onLoginError);
        }
    }

    let onGetParkingLotDetails = (dataObj:any)=>{
        //console.log(dataObj);
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
               // console.log(restData);
                setAddress(restData.address);
                setParkingLot(restData.parkingLotName);
                setPhoneNum(restData.phoneNumber);
                setInstructions(restData.instructions);
                setTwicAccess((restData.twicAccessRequired === "Y")?true:false);
            }
        }
        getUserDetails();
    }

    let getUserDetails = () => {
        let getUserAPI: string = 'api/session/rp/organization/user/'+CommonUtil.getUserId() +'?rpKey='+CommonUtil.RP_KEY;
        NetworkManager.sendJsonRequest(getUserAPI, '', '', 'GET', true, onGetUserDetails, onLoginError)
    }
    let onGetUserDetails = (dataObj: any) => {
        //console.log(dataObj)
        BusyLoader.hideLoader();
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                setUserName(restData.firstName+" "+restData.lastName);
                //setMail(restData.loginId);
                setUserPhoneNum(restData.phone);
                setUserEmail(restData.loginId);
            }
        }

        getEquipmentnspectionDetails();
    }

    let getEquipmentDetails = ()=>{
        equiImages = [];
        let equipmentAPI:string = "api/equipment/"+CommonUtil.SELECT_EQUIP_ID;
        NetworkManager.sendJsonRequest(equipmentAPI,"","","GET",true,onGetEquipmentDetails,onLoginError);
    }
    let onGetEquipmentDetails = (dataObj:any)=>{
        if(dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                setEquipmentData(restData);
                setEquipType(restData.equipmentType ? restData.equipmentType : "-");
                setEquipName(restData.genericEquipmentName ? restData.genericEquipmentName : "-");
                setUnitNumber(restData.unitNumber ? restData.unitNumber : "-");
                setVinNumber(restData.VIN ? restData.VIN : "-");

                setYear(restData.year ? restData.year : "-");
                setModel(restData.model ? restData.model : "-");
                setBrand(restData.brand ? restData.brand : "-");
                setNumOfAxes(restData.numberOfAxles ? restData.numberOfAxles : "-");
                setTrailerLength(restData.lengthOfTrailer ? restData.lengthOfTrailer : "-");
                setELicense(restData.licensePlateNumber ? restData.licensePlateNumber : "-");
                setExpDate(restData.expDate ? restData.expDate : "-");
                setAssetType(restData.assetType ? restData.assetType : "-");
                setExpDate(restData.annualMechanicalInspExpDate ? restData.annualMechanicalInspExpDate : "-");

                if (restData.specifications) {
                    if (restData.specifications.general) {
                        setAxelSpread(restData.specifications.general.axleSpread ? restData.specifications.general.axleSpread : "-");
                        setTireInflation(restData.specifications.general.tireInflationSystem ? restData.specifications.general.tireInflationSystem : "-");
                        setSkirts(restData.specifications.general.skirts ? restData.specifications.general.skirts : "-");
                        setPintleHook(restData.specifications.general.pintleHook ? restData.specifications.general.pintleHook : "-");
                        setHazmat(restData.specifications.general.hazmatPlacardHolder ? restData.specifications.general.hazmatPlacardHolder : "-");
                        setSuspension(restData.specifications.general.suspensionType ? restData.specifications.general.suspensionType : "-");
                    }
                    if (restData.specifications.trailer) {
                        setRearDoor(restData.specifications.trailer.rearDoor ? restData.specifications.trailer.rearDoor : "-");
                        setSideDoor(restData.specifications.trailer.sideDoor ? restData.specifications.trailer.sideDoor : "-");
                        setRoof(restData.specifications.trailer.roof ? restData.specifications.trailer.roof : "-");
                        setFloor(restData.specifications.trailer.floor ? restData.specifications.trailer.floor : "-");
                        setFloorType(restData.specifications.trailer.floorType ? restData.specifications.trailer.floorType : "-");
                        setInterior(restData.specifications.trailer.interiorFinish ? restData.specifications.trailer.interiorFinish : "-");
                        setLogistics(restData.specifications.trailer.logisticPostsDistance ? restData.specifications.trailer.logisticPostsDistance : "-");
                        setDeckBeams(restData.specifications.trailer.doubleDeckBeams ? restData.specifications.trailer.doubleDeckBeams : "-");
                    }
                    if (restData.specifications.lift) {
                        setLift(restData.specifications.lift.lift);
                        setLiftType(restData.specifications.lift.liftType);
                        setLiftCap(restData.specifications.lift.liftCapacity);
                    }

                    if (restData.specifications.reefer) {
                        setMultiTemp(restData.specifications.reefer.multiTemperature);
                        setUnitModal(restData.specifications.reefer.unitMake);
                        setUnitMake(restData.specifications.reefer.unitModel);
                        setUnitYear(restData.specifications.reefer.unitYear);
                    }
                }
                if (restData.attachments) {
                    var list = [];
                    attachmentList = []
                    attchIdx = 0;
                    if (Array.isArray(restData.attachments)) {
                        list = restData.attachments
                    } else {
                        list.push(restData.attachments)
                    }
                    list.forEach((item:any,idx:number)=>{
                        if(item.documentType == CommonUtil.EQUIP_DAMAGES){
                            attachmentList.push(item);
                        }
                    })
                    if (attachmentList.length > 0) {
                        //BusyLoader.showLoader()
                        //showAttachments()
                    }
                }
            }
        }
    }

    let showAttachments = () => {
        if (attachmentList.length > attchIdx) {
            let qParam: string = 'objectPath=' + attachmentList[attchIdx].fullFilePath
            NetworkManager.sendJsonRequest('api/session/downloadUrl', '', qParam, 'GET', false, (dataObj: any) => {
                    let obj: any = {};
                    obj.Type = attachmentList[attchIdx].documentType;
                    obj.Path = dataObj;
                    obj.attachementId = attachmentList[attchIdx].attachementId;
                    obj.attachmentDescription = attachmentList[attchIdx].attachmentDescription;
                    equiImages.push(obj);
                    attchIdx = attchIdx + 1;
                    showAttachments();
                },
                onLoginError,
            )
        } else {
            BusyLoader.hideLoader();
            setEquipImagesPath(equiImages);
            if(addDamageImages){
                $("#damageImages").show();
                $("#damageNewImages").hide();
            }
            addDamageImages = false;
        }

    }



    let onClickBackKnowImages = ()=>{
        if(CommonUtil.SELECT_RESERVATION_STATUS == CommonUtil.RESERVATION_UPCOMING || CommonUtil.SELECT_RESERVATION_STATUS == CommonUtil.RESERVATION_ACTIVATION_DUE){
            licensePath = "";
            $("#damageImages").hide();
            $("#licensePlate").show();
            panelType = LICENSE_PANEL;
            onClickStartCamera();
            //startWebCamera(videoRef);
        }else{
           // licensePath = "";
            $("#damageImages").hide();
            $("#pnlInspection").show();
        }

    }

    let onClickNextDropLocation = ()=>{
        $("#dropLocation").hide();
        $("#licensePlate").show();
        licensePath = "";
        panelType = LICENSE_PANEL;
        onClickStartCamera();
    }

    let onClickStartCamera = ()=>{
        $("#divTakePhoto").removeClass("disable-form");
        var video = document.getElementById('output');
        $("#output").show();
        $("#output2").hide();
        startWebCamera(videoRef);
    }

    let startWebCamera = (ref:any)=>{
        navigator.mediaDevices.getUserMedia(videoConstraints)
            .then(function(stream) {
                if(ref){
                    if(ref && ref.current){
                        ref.current.srcObject = stream;
                        ref.current.play();
                       // ref.current.setAttribute('playsinline', '');
                        ref.current.muted = true;
                    }
                }
            })
            .catch(function(err) {});
    }
    let stopWebCamera = (ref:any)=>{
        if(ref && ref.current){
            let stream:any = ref.current.srcObject;// as MediaStream;
            if(stream){
                let tracks = stream.getTracks();
                // console.log(tracks);
                tracks.forEach((track:any,idx:number)=>{
                    track.stop();
                })
                ref.current.srcObject = null;
            }
        }
    }

    let onClickBrowse = ()=>{
        if (fileref.current) {
            fileref.current.value = '';
            // fileref.current.onclick();
            $('#inputGroupFile').click()
        }
        setFileName('');
    }
    let onClickUploadLicensePhoto = ()=>{
        panelType = LICENSE_PANEL;
        onClickBrowse();



        /*imageFileName = new Date().getTime()+".png";
        imagePath = 'images/equipment/license/'+ imageFileName;
        licensePath = imagePath;
        licenseNumber = licenseNum;
        documentType = CommonUtil.LICENSE_IMAGES;*/

        //imagesUpload();

    }
    let onClickTakeLicensePhoto = ()=>{
        $("#divTakePhoto").addClass("disable-form");
        var canvas = document.getElementById('output1');
        var photo = document.getElementById('output2');
        var video = document.getElementById('output');
        var context = null;
        if(canvasRef && canvasRef.current){
            context = canvasRef.current.getContext('2d');
            canvasRef.current.width = picWidth;
            canvasRef.current.height = picHeight;
            if(context && video && videoRef.current && photo){
                context.imageSmoothingEnabled = true;
               // context.webkitImageSmoothingEnabled = false;
               // context.mozImageSmoothingEnabled = false;
                context.drawImage(videoRef.current, 0, 0, picWidth, picHeight);
                var data = canvasRef.current.toDataURL('image/png');
               // console.log(data);
                photo.setAttribute('src', data);
                imageBinaryData = data;
                $("#output").hide();
                $("#output2").show();

                stopWebCamera(videoRef);

                uploadType = UPLOAD_TYPE_CAMERA;
                imageFileName = new Date().getTime()+".png";
                imagePath = 'images/equipment/license/'+ imageFileName;
                licensePath = imagePath;
                licenseNumber = licenseNum;
                documentType = CommonUtil.LICENSE_IMAGES;


                imagesUpload();

               // imageBinaryData = data;

                //var file = dataURLtoFile(imageBinaryData, 'a.png');
                //console.log(file);
                /*imageFileName = new Date().getTime()+".png";
                imagePath = 'images/equipment/license/'+ imageFileName;
                documentType = CommonUtil.LICENSE_IMAGES;
                category = CommonUtil.RESERVATION_CATEGORY;
                description = licenseNum;
                unitMeasurement = "";*/

                //console.log(description);

                //imagesUpload();
            }
        }
    }

    let onClickTakePhoto = ()=>{
        if(videoRef && videoRef.current){
            //const video = document.querySelector('output');
            let stream:any = videoRef.current.srcObject;// as MediaStream;
            if(stream){
                let tracks = stream.getTracks();
               // console.log(tracks);
                tracks.forEach((track:any,idx:number)=>{
                    track.stop();
                })
                videoRef.current.srcObject = null;
                //tracks.forEach(track:any => track.stop());
                //video.srcObject = null;
            }

        }


       // var canvas = document.getElementById('output1');
        /*var photo = document.getElementById('output2');
        var video = document.getElementById('output');
        var context = null;
        if(canvasRef && canvasRef.current){
            context = canvasRef.current.getContext('2d');
            canvasRef.current.width = 100;
            canvasRef.current.height = 150;
            if(context && video && videoRef.current && photo){
                context.imageSmoothingEnabled = true;
               // context.webkitImageSmoothingEnabled = false;
               // context.mozImageSmoothingEnabled = false;
                context.drawImage(videoRef.current, 0, 0, 100, 150);
                var data = canvasRef.current.toDataURL('image/png');
               // console.log(data);
                photo.setAttribute('src', data);
                imageBinaryData = data;

                //var file = dataURLtoFile(imageBinaryData, 'a.png');
                //console.log(file);
                imageFileName = new Date().getTime()+".png";
                imagePath = 'images/equipment/license/'+ imageFileName;
                documentType = CommonUtil.LICENSE_IMAGES;
                category = CommonUtil.RESERVATION_CATEGORY;
                description = licenseNum;
                unitMeasurement = "";

                //console.log(description);

                imagesUpload();
            }
        }*/
    }

    let imagesUpload = ()=>{
        BusyLoader.showLoader();
        var qParam: string = 'objectPath=' + imagePath //images/equipment/"+CommonUtil.getSelectedOrgId()+"/"+fileName;;
        NetworkManager.sendJsonRequest('api/session/uploadUrl', '', qParam, 'GET', false, onUploadImage, onLoginError)
    }

    let dataURLtoFile = (dataurl:any, filename:any)=>{
        var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, {type:mime});
    }


    let onUploadImage = (dataObj: any) => {
        if (dataObj) {
            var strUploadPath = dataObj;
            const formData = new FormData();
          //  formData.append("content", imageBinaryData);
            var myHeaders = new Headers();
            //var file = filedocref.current.files[0];
           // var imagebase64data = imageBinaryData.replace('data:image/png;base64,', '');
            //dropFileObj.current
            var file = null;//dataURLtoFile(imageBinaryData, imageFileName);
            if(uploadType == UPLOAD_TYPE_CAMERA){
                file = dataURLtoFile(imageBinaryData, imageFileName);
            }else{
                file = dropFileObj.current;
            }
            //console.log(file);
            var requestOptions: any = {method: 'PUT', headers: myHeaders, body: file, redirect: 'follow'};
            fetch(strUploadPath, requestOptions)
                .then((response) => response.text())
                .then((result) => {
                    BusyLoader.hideLoader();
                   // console.log(result);
                    //uploadLiceImages();
                })
                .catch((error) => {
                      BusyLoader.hideLoader();
                    console.log('error', error)
                })
        }
    }

    let uploadLiceImages = ()=>{
        let reqObj: any = {}
        let obj: any = {}
        obj.documentType = documentType;//CommonUtil.LICENSE_IMAGES;
        obj.Type = documentType;//CommonUtil.LICENSE_IMAGES;;
        obj.fullFilePath = imagePath;
        obj.saasOrgId = CommonUtil.getSelectedOrgId();
        obj.entityId = CommonUtil.SELECT_EQUIP_ID;
        obj.reservationCode = CommonUtil.SELECT_RESERVATION_CODE;
        obj.reservationId = CommonUtil.SELECT_RESERVATION_ID;
        obj.attachmentCategory = CommonUtil.RESERVATION_CATEGORY;//CommonUtil.LICENSE_IMAGES;
        obj.attachmentDescription = description;//licenseNum;
        obj.UOM = unitMeasurement;
        obj.visibility = "public";

        reqObj.attachments = [obj];
        let arr: any = [];
        arr.push(obj);

        let attachmentUrl: string = 'api/session/rp/equipment/attachments?rpKey=' + CommonUtil.RP_KEY;//localStorage.getItem('saasorgId');
        NetworkManager.sendJsonRequest(attachmentUrl, arr, '', 'PUT', true, onUpdateEquipImageSuccess, onLoginError);
    }
    let onUpdateEquipImageSuccess = (dataObj: any) => {
        //console.log(dataObj);
        BusyLoader.hideLoader();
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if(restData){
                let qParam: string = 'objectPath=' + imagePath;
                NetworkManager.sendJsonRequest('api/session/downloadUrl', '', qParam, 'GET', true, (dataObj: any) => {
                        let obj: any = {};
                        obj.Type = documentType;
                        obj.Path = dataObj;
                        obj.attachmentCategory = CommonUtil.RESERVATION_CATEGORY;
                       // obj.attachementId = attachmentList1[attchIdx1].attachementId;
                        obj.attachmentDescription = description;//attachmentList1[attchIdx1].attachmentDescription;
                        equiImages1.push(obj);
                        setDamages(true);
                        setEquipDriverSideImagesPath(equiImages1);
                        setPage(""+Math.random()*100000);
                        $("#damageImages").show();
                        $("#damageNewImages").hide();
                       // attchIdx1 = attchIdx1 + 1;
                        //showDriverSideAttachments();
                    },
                    onLoginError,
                )
            } else {
                BusyLoader.hideLoader();
                //setEquipDriverSideImagesPath(equiImages1);

            }
                /*if(addDamageImages){
                    getEquipmentnspectionDetails();

                }*/
            }
        }
       // addDamageImages = false;


    let onClickDmgStartCamera = ()=>{
        var video = document.getElementById('dmgoutput');
        navigator.mediaDevices.getUserMedia({video: true, audio: false})
            // on success, stream it in video tag
            .then(function(stream) {
                if(video){
                    if(videodmgRef && videodmgRef.current){
                        videodmgRef.current.srcObject = stream;
                        videodmgRef.current.play();
                    }
                }
            })
            .catch(function(err) {
                //alert(err);
            });
    }

    let onClickDmgReTakePhoto = ()=>{
        $("#divDamageNewImages").removeClass("disable-form");
        onClickStartDamageCamera();
    }

    let onClickUploadDmgPhoto = ()=>{
        panelType = DAMAGES_PANEL;
        onClickBrowse();
    }

    let onClickDmgTakePhoto = ()=>{
        $("#divDamageNewImages").addClass("disable-form");
        var photo = document.getElementById('dgmoutput2');
        var video = document.getElementById('dmgoutput');
        var context = null;
        if(canvasdmgRef && canvasdmgRef.current){
            context = canvasdmgRef.current.getContext('2d');
            canvasdmgRef.current.width = picWidth;
            canvasdmgRef.current.height = picHeight;
            if(context && video && videodmgRef.current && photo){
                context.drawImage(videodmgRef.current, 0, 0, picWidth, picHeight);
                var data = canvasdmgRef.current.toDataURL('image/png');
                //console.log(data);
                photo.setAttribute('src', data);
                $("#dmgoutput").hide();
                $("#dgmoutput2").show();
                stopWebCamera(videodmgRef);
                imageBinaryData = data;

                imageFileName = new Date().getTime()+".png";
                imagePath = 'images/equipment/license/'+ imageFileName;
                documentType = CommonUtil.EQUIP_DAMAGES;
                // category = CommonUtil.RESERVATION_CATEGORY;
                description = desc;
               // unitMeasurement = "";
                uploadType = UPLOAD_TYPE_CAMERA;

                imagesUpload();
            }
        }
    }

    let onClickNextLicense = ()=>{
        if(CommonUtil.IS_VIN_EXIST){
            if(equipLicensePlateNumber){
                if(licenseNum == equipLicensePlateNumber){
                    stopWebCamera(videoRef);
                    if(CommonUtil.SELECT_RESERVATION_STATUS == CommonUtil.RESERVATION_UPCOMING || CommonUtil.SELECT_RESERVATION_STATUS == CommonUtil.RESERVATION_ACTIVATION_DUE){
                        $("#licensePlate").hide();
                        $("#damageImages").show();
                    }else{
                        $("#licensePlate").hide();
                        $("#pnlInspection").show();
                    }
                }else{
                    let strUserStatus = "Equipment License Plate Number and Entered License Plate Number is not Same. Please enter the Equipment License Plate Number";
                    ToastManager.showToast(strUserStatus,"Error");
                }

            }else{
                let strUserStatus = "Equipment License Number is mandatory for reservation process";
                ToastManager.showToast(strUserStatus,"Error");
            }
        }else{
            if(trailerIdNum){
                stopWebCamera(videoRef);
                if(CommonUtil.SELECT_RESERVATION_STATUS == CommonUtil.RESERVATION_UPCOMING || CommonUtil.SELECT_RESERVATION_STATUS == CommonUtil.RESERVATION_ACTIVATION_DUE){
                    $("#licensePlate").hide();
                    $("#damageImages").show();
                }else{
                    $("#licensePlate").hide();
                    $("#pnlInspection").show();
                }
            }else{
                let strUserStatus = "Equipment VIN or Trailer Id is mandatory for reservation process";
                ToastManager.showToast(strUserStatus,"Error");
            }

        }
    }

    let onClickBackInspection = ()=>{
        //startWebCamera(videoRef);
        licensePath = "";
        panelType = LICENSE_PANEL;
        $("#licensePlate").show();
        $("#pnlInspection").hide();
        onClickStartCamera();
    }

    let onClickNextInspection = ()=>{
        $("#pnlInspection").hide();
        $("#damageImages").show();
    }

    let onClickAddNewDamagesPanel = ()=>{
        $("#damageImages").hide();
        $("#damageNewImages").show();
        panelType = DAMAGES_PANEL;
        //getEquipmentDetails();
        onClickStartDamageCamera();
    }

    let onClickStartDamageCamera = ()=>{
        $("#dmgoutput").show();
        $("#dgmoutput2").hide();
        $("#divDamageNewImages").removeClass("disable-form");
        startWebCamera(videodmgRef);
    }

    let onClickAddDamages = ()=>{
       // documentType = CommonUtil.EQUIP_DAMAGES;
       // category = CommonUtil.ACTIVATION;
        description = desc;
       // unitMeasurement = "";
        addDamageImages = false;

       // imagesUpload();
        uploadLiceImages();

        //$("#damageImages").show();
       // $("#damageNewImages").hide();
    }

    let onClickNextDamages = ()=>{
        $("#damageImages").hide();
        hodoMeterPath = "";
        $("#pnlHodometer").show();
        panelType = HODOMETER_PANEL;
        $("#divHodometer").removeClass("disable-form");
        startWebCamera(videoHodoRef);
    }

    let deselectLinks = ()=>{
        $("#lnkKM").removeClass("active");
        $("#lnkMiles").removeClass("active");
    }

    let onClickKM = ()=>{
        unitMeasurement =  "Kilometers";
        deselectLinks();
        $("#lnkKM").addClass("active");
    }

    let onClickMiles = ()=>{
        unitMeasurement =  "Miles";
        deselectLinks();
        $("#lnkMiles").addClass("active");
    }

    let onClickUploadPODPhoto = ()=>{
        panelType = POD_PANEL;
        onClickBrowse();
    }

    let onClickPODStartCamera = ()=>{
        $("#divPODmeter").addClass("disable-form");

        var photo = document.getElementById('podooutput2');
        var video = document.getElementById('podoutput');

        var context = null;
        if(canvasPODRef && canvasPODRef.current){
            context = canvasPODRef.current.getContext('2d');
            canvasPODRef.current.width = picWidth;
            canvasPODRef.current.height = picHeight;
            if(context && video && videoPODRef.current && photo){
                context.drawImage(videoPODRef.current, 0, 0, picWidth, picHeight);
                var data = canvasPODRef.current.toDataURL('image/png');
                //console.log(data);
                imageBinaryData = data;
                photo.setAttribute('src', data);

                $("#podoutput").hide();
                $("#podooutput2").show();

                stopWebCamera(videoPODRef);

                imageFileName = new Date().getTime()+".png";
                imagePath = 'images/equipment/pod/'+ imageFileName;
                category = CommonUtil.RESERVATION_CATEGORY;
                description = "";
                documentType = CommonUtil.EQUIP_POD;

                podPath = imagePath;
                hodometerNumber = "";
                uploadType = UPLOAD_TYPE_CAMERA;
                imagesUpload();
            }
        }

    }

    let onClickEngineUploadPhoto = ()=>{
        panelType = REEFER_PANEL;
        onClickBrowse();
    }

    let onClickEngineStartCamera = ()=>{
        $("#divEngineHour").addClass("disable-form");
        var photo = document.getElementById('engineoutput2');
        var video = document.getElementById('engineoutput');
        var context = null;
        if(canvasEngineRef && canvasEngineRef.current){
            context = canvasEngineRef.current.getContext('2d');
            canvasEngineRef.current.width = picWidth;
            canvasEngineRef.current.height = picHeight;
            if(context && video && videoEngineRef.current && photo){
                context.drawImage(videoEngineRef.current, 0, 0, picWidth, picHeight);
                var data = canvasEngineRef.current.toDataURL('image/png');
                //console.log(data);
                imageBinaryData = data;
                photo.setAttribute('src', data);

                $("#engineoutput").hide();
                $("#engineoutput2").show();

                stopWebCamera(videoEngineRef);

                imageFileName = new Date().getTime()+".png";
                imagePath = 'images/equipment/refer/'+ imageFileName;
                category = CommonUtil.RESERVATION_CATEGORY;
                description = "";
                documentType = CommonUtil.EQUIP_REEFER_TYPE;

                reeferPath = imagePath;
                reeferNumber = enginehours;

                imagesUpload();
            }
        }
    }

    let onClickUploadHodometer = ()=>{
        panelType = HODOMETER_PANEL;
        onClickBrowse();
    }

    let onClickHodoStartCamera = ()=>{
        $("#divHodometer").addClass("disable-form");
        /*var video = document.getElementById('hodooutput');
        navigator.mediaDevices.getUserMedia({video: true, audio: false})
            // on success, stream it in video tag
            .then(function(stream) {
                if(video){
                    if(videoHodoRef && videoHodoRef.current){
                        videoHodoRef.current.srcObject = stream;
                        videoHodoRef.current.play();
                    }
                }
            })
            .catch(function(err) {
                //alert(err);
            });*/

        var photo = document.getElementById('hodooutput2');
        var video = document.getElementById('hodooutput');
        var context = null;
        if(canvasHodoRef && canvasHodoRef.current){
            context = canvasHodoRef.current.getContext('2d');
            canvasHodoRef.current.width = picWidth;
            canvasHodoRef.current.height = picHeight;
            if(context && video && videoHodoRef.current && photo){
                context.drawImage(videoHodoRef.current, 0, 0, picWidth, picHeight);
                var data = canvasHodoRef.current.toDataURL('image/png');
                //console.log(data);
                imageBinaryData = data;
                photo.setAttribute('src', data);

                $("#hodooutput").hide();
                $("#hodooutput2").show();

                stopWebCamera(videoHodoRef);

                imageFileName = new Date().getTime()+".png";
                imagePath = 'images/equipment/vodometer/'+ imageFileName;
                category = CommonUtil.RESERVATION_CATEGORY;
                description = "";
                documentType = CommonUtil.EQUIP_HODOMETER;

                hodoMeterPath = imagePath;
                hodometerNumber = hodometer;
                uploadType = UPLOAD_TYPE_CAMERA;
                imagesUpload();
            }
        }
    }

    let onClickPODTakePhoto = ()=>{
        $("#divPODmeter").removeClass("disable-form");
        $("#podoutput").show();
        $("#podooutput2").hide();
        startWebCamera(videoPODRef);
    }

    let onClickEngineTakePhoto = ()=>{
        $("#divEngineHour").removeClass("disable-form");
        $("#engineoutput").show();
        $("#engineoutput2").hide();
        startWebCamera(videoEngineRef);
    }

    let onClickHodoTakePhoto = ()=>{
        $("#divHodometer").removeClass("disable-form");
        $("#hodooutput").show();
        $("#hodooutput2").hide();
       startWebCamera(videoHodoRef);
       /* var photo = document.getElementById('hodooutput2');
        var video = document.getElementById('hodooutput');
        var context = null;
        if(canvasHodoRef && canvasHodoRef.current){
            context = canvasHodoRef.current.getContext('2d');
            canvasHodoRef.current.width = 100;
            canvasHodoRef.current.height = 150;
            if(context && video && videoHodoRef.current && photo){
                context.drawImage(videoHodoRef.current, 0, 0, 100, 150);
                var data = canvasHodoRef.current.toDataURL('image/png');
                //console.log(data);
                imageBinaryData = data;
                photo.setAttribute('src', data);
                imageFileName = new Date().getTime()+".png";
                imagePath = 'images/equipment/license/'+ imageFileName;
                category = CommonUtil.RESERVATION_CATEGORY;
                description = "";
                documentType = CommonUtil.EQUIP_HODOMETER;
                //imagesUpload();
            }
        }*/
    }

    let onClickNextPODmeter = ()=> {
        //$("#pnlConfirmation").show();
        $("#pnlPOD").hide();
        stopWebCamera(videoPODRef);
        if (CommonUtil.RESERVATION_CATEGORY == CommonUtil.RESERVATION_ACTIVATION) {
            if (CommonUtil.SELECT_EQUIPMENT_TYPE == CommonUtil.REEFER_EQUIP_TYPE) {
                reeferPath = "";
                $("#pnlReefer").show();
                panelType = REEFER_PANEL;
                // $("#pnlHodometer").hide();
                startWebCamera(videoEngineRef);
            } else {
                $("#pnlConfirmation").show();
                //$("#pnlHodometer").hide();
            }
        } else {
            // $("#pnlConfirmation").show();
            if (CommonUtil.SELECT_EQUIPMENT_TYPE == CommonUtil.REEFER_EQUIP_TYPE) {
                reeferPath = "";
                $("#pnlReefer").show();
                panelType = REEFER_PANEL;
                // $("#pnlHodometer").hide();
                startWebCamera(videoEngineRef);
            } else {
                $("#pnlConfirmation").show();
            }
        }
    }

    let onClickNextEngineHours = ()=>{
        if(enginehours){
            stopWebCamera(videoEngineRef);
            if(CommonUtil.RESERVATION_CATEGORY == CommonUtil.RESERVATION_ACTIVATION){
                $("#pnlConfirmation").show();
                $("#pnlReefer").hide();
            }else{
                $("#pnlConfirmation").show();
                $("#pnlReefer").hide();
            }
        }else{
            let strUserStatus = "Please enter the engine hours";
            ToastManager.showToast(strUserStatus,"Error");
        }
    }
    let onClickNextHodometer = ()=>{
        let flag:boolean = true;
        console.log(CommonUtil.EQUIP_LISTING_TYPE);
        console.log(CommonUtil.EQUIP_TRACKING_TYPE);
        if((CommonUtil.EQUIP_LISTING_TYPE == CommonUtil.EQUIP_AVAIL_RENTAL_TYPE || CommonUtil.EQUIP_LISTING_TYPE == CommonUtil.EQUIP_AVAIL_REPOSITION_TYPE) && CommonUtil.EQUIP_TRACKING_TYPE == CommonUtil.EQUIP_HUBODOMETER){
            if(hodometer == ""){
                flag = false;
                ToastManager.showToast('Please enter the Hubodometer value', 'Error');
            }else{
                if(CommonUtil.RESERVATION_CATEGORY != CommonUtil.RESERVATION_ACTIVATION){
                    let activationValue = CommonUtil.ACTIVATION_HODO_METER;
                    if(activationValue){
                        activationValue = Number(activationValue);
                        if(Number(hodometer)<activationValue){
                            flag = false;
                            ToastManager.showToast('The value entered is lower than the activation value. Please enter the correct Hubodometer value.', 'Error');
                        }
                    }

                }
            }
        }
        if(flag){
            stopWebCamera(videoHodoRef);
            if(CommonUtil.RESERVATION_CATEGORY == CommonUtil.RESERVATION_ACTIVATION){
                $("#pnlHodometer").hide();
                $("#pnlPOD").show();
                panelType = POD_PANEL;
                startWebCamera(videoPODRef);
                /*if(CommonUtil.SELECT_EQUIPMENT_TYPE == CommonUtil.REEFER_EQUIP_TYPE){
                    reeferPath = "";
                    $("#pnlReefer").show();
                    panelType = REEFER_PANEL;
                    $("#pnlHodometer").hide();
                    startWebCamera(videoEngineRef);
                }else{
                    $("#pnlConfirmation").show();
                    $("#pnlHodometer").hide();
                }*/
            }else{
                $("#pnlHodometer").hide();
                $("#pnlPOD").show();
                panelType = POD_PANEL;
                startWebCamera(videoPODRef);
                /*if(CommonUtil.SELECT_EQUIPMENT_TYPE == CommonUtil.REEFER_EQUIP_TYPE){
                    reeferPath = "";
                    $("#pnlReefer").show();
                    panelType = REEFER_PANEL;
                    $("#pnlHodometer").hide();
                    startWebCamera(videoEngineRef);
                }else{
                    $("#pnlHodometer").hide();
                    $("#pnlPOD").show();
                    startWebCamera(videoPODRef);
                }*/

            }
        }

    }

    let onClickEndMyRental = ()=>{

        //console.log(licensePath,licenseNumber,hodoMeterPath,hodometer);
       // var arr:any = [];
        $("#pnlCheckboxes").hide();
        let flag = true;
        if(CommonUtil.RESERVATION_CATEGORY == CommonUtil.RESERVATION_ACTIVATION){
            if(activeInstruction){
                if(chkLocal.current?.checked){
                    flag = true;
                }else{
                    flag = false;
                }
            }

        }else if(CommonUtil.RESERVATION_CATEGORY == CommonUtil.RESERVATION_IN_ACTIVATION){
            if(deActiveInstruction){
                if(chkLocal.current?.checked){
                    flag = true;
                }else{
                    flag = false;
                }
            }
        }
        if(flag && chkConfirm.current?.checked){
            let arr: any = [];
            let obj: any = {}
            obj.documentType = CommonUtil.LICENSE_IMAGES;
            obj.Type = CommonUtil.LICENSE_IMAGES;
            obj.fullFilePath = licensePath;
            obj.saasOrgId = CommonUtil.getSelectedOrgId();
            obj.entityId = CommonUtil.SELECT_EQUIP_ID;
            obj.reservationCode = CommonUtil.SELECT_RESERVATION_CODE;
            obj.reservationId = CommonUtil.SELECT_RESERVATION_ID;
            obj.attachmentCategory = CommonUtil.RESERVATION_CATEGORY;//CommonUtil.LICENSE_IMAGES;
            obj.attachmentDescription = licenseNum;//licenseNum;
            obj.documentName = licenseNum;
            obj.UOM = unitMeasurement;
            obj.visibility = "public";
            arr.push(obj);

            obj = {};
            obj.documentType = CommonUtil.EQUIP_HODOMETER;
            obj.Type = CommonUtil.EQUIP_HODOMETER;
            obj.fullFilePath = hodoMeterPath;
            obj.saasOrgId = CommonUtil.getSelectedOrgId();
            obj.entityId = CommonUtil.SELECT_EQUIP_ID;
            obj.reservationCode = CommonUtil.SELECT_RESERVATION_CODE;
            obj.reservationId = CommonUtil.SELECT_RESERVATION_ID;
            obj.attachmentCategory = CommonUtil.RESERVATION_CATEGORY;;//CommonUtil.LICENSE_IMAGES;
            obj.attachmentDescription = hodometer;//licenseNum;
            obj.documentName = hodometer;
            obj.UOM = unitMeasurement;
            obj.visibility = "public";
            arr.push(obj);

            obj = {};
            obj.documentType = CommonUtil.EQUIP_POD;
            obj.Type = CommonUtil.EQUIP_POD;
            obj.fullFilePath = podPath;
            obj.saasOrgId = CommonUtil.getSelectedOrgId();
            obj.entityId = CommonUtil.SELECT_EQUIP_ID;
            obj.reservationCode = CommonUtil.SELECT_RESERVATION_CODE;
            obj.reservationId = CommonUtil.SELECT_RESERVATION_ID;
            obj.attachmentCategory = CommonUtil.RESERVATION_CATEGORY;;//CommonUtil.LICENSE_IMAGES;
            obj.attachmentDescription = "";//licenseNum;
            obj.documentName = "";
            obj.UOM = "";
            obj.visibility = "public";
            arr.push(obj);

            obj = {};
            obj.documentType = CommonUtil.EQUIP_REEFER_TYPE;
            obj.Type = CommonUtil.EQUIP_REEFER_TYPE;
            obj.fullFilePath = reeferPath;
            obj.saasOrgId = CommonUtil.getSelectedOrgId();
            obj.entityId = CommonUtil.SELECT_EQUIP_ID;
            obj.reservationCode = CommonUtil.SELECT_RESERVATION_CODE;
            obj.reservationId = CommonUtil.SELECT_RESERVATION_ID;
            obj.attachmentCategory = CommonUtil.RESERVATION_CATEGORY;;//CommonUtil.LICENSE_IMAGES;
            obj.attachmentDescription = enginehours;//licenseNum;
            obj.documentName = enginehours;
            obj.UOM = "";
            obj.visibility = "public";
            arr.push(obj);

            let attachmentUrl: string = 'api/session/rp/equipment/attachments?rpKey=' + CommonUtil.RP_KEY;//localStorage.getItem('saasorgId');
            NetworkManager.sendJsonRequest(attachmentUrl, arr, '', 'PUT', true, onUpdateImagesSuccess, onLoginError);
        }else{
           $("#pnlCheckboxes").show();
        }
        //let reqObj: any = {}

    }

    let onUpdateImagesSuccess = (dataObj:any)=>{
        //BusyLoader.hideLoader();
        if (dataObj) {
                    var restData = CommonUtil.parseData(dataObj);
                    if(restData){
                        if(CommonUtil.RESERVATION_CATEGORY == CommonUtil.RESERVATION_ACTIVATION){
                            let assignAPI:string = "api/session/rp/reservation/activation/"+CommonUtil.SELECT_RESERVATION_ID+"?rpKey="+CommonUtil.RP_KEY;
                            let reqObj:any = {};
                            reqObj.activationLPN = licenseNum;
                            reqObj.activationDate = CommonUtil.getDateTimeString(new Date());
                            reqObj.activationOdometer = hodometer;
                             reqObj.reservationId = CommonUtil.SELECT_RESERVATION_ID;
                            reqObj.equipmentListingId = CommonUtil.SELECT_EQUIP_AVAIL_ID;
                            reqObj.activationAddress = formattedAddress;
                            reqObj.activationLatitude = currLat;
                            reqObj.activationLongitude = currLng;
                            reqObj.activationRating = rating;
                            reqObj.calculatedEngineHours = 0;
                            reqObj.unitOrVinNumber = trailerIdNum;
                            reqObj.activationEngineHours  = enginehours;
                             NetworkManager.sendJsonRequest(assignAPI, reqObj, "", "PUT", true, onGetActivationReservation, onLoginError);
                        }else{
                            let assignAPI:string = "api/session/rp/reservation/deactivation/"+CommonUtil.SELECT_RESERVATION_ID+"?rpKey="+CommonUtil.RP_KEY;
                            let reqObj:any = {};
                            //reqObj.deativationLPN = license;
                            reqObj.deactivationDate = CommonUtil.getDateTimeString(new Date());
                            reqObj.deactivationAddress = formattedAddress;
                            reqObj.calculatedDistance = distanceInMeters;
                            reqObj.calculatedEngineHours = 0;
                            reqObj.deactivationLatitude = currLat;
                            reqObj.deactivationLongitude = currLng;
                            reqObj.deativationLPN = licenseNum;
                            reqObj.deactivationOdometer = hodometer;
                            reqObj.reservationId = CommonUtil.SELECT_RESERVATION_ID;
                            reqObj.equipmentListingId = CommonUtil.SELECT_EQUIP_AVAIL_ID;
                            reqObj.deactivationRating = rating;
                            reqObj.unitOrVinNumber = trailerIdNum;
                            reqObj.deactivationEngineHours = enginehours;

                            NetworkManager.sendJsonRequest(assignAPI, reqObj, "", "PUT", true, onGetDeActivationReservation, onLoginError);
                        }
                }
            }
        }

    let onGetDeActivationReservation = (dataObj:any)=>{
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj)
            if (restData) {
                ToastManager.showToast('Reservation Deactivated successfully', 'Info');
                $("#pnlConfirmation").hide();
                $("#pnlComplete").show();

                CommonUtil.SELECT_RESERVATION_STATUS= restData.reservationStatus;
                if(restData.reservationStatus == CommonUtil.RESERVATION_UPCOMING || restData.reservationStatus == CommonUtil.RESERVATION_ACTIVATION_DUE){
                    CommonUtil.RESERVATION_CATEGORY = CommonUtil.RESERVATION_ACTIVATION;
                }else if(restData.reservationStatus == CommonUtil.RESERVATION_INPROGRESS){
                    CommonUtil.RESERVATION_CATEGORY = CommonUtil.RESERVATION_IN_ACTIVATION;
                }
            }
        }
    }
    let onGetActivationReservation = (dataObj:any)=>{
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj)
            if (restData) {
                ToastManager.showToast('Reservation activated successfully', 'Info');
                $("#pnlConfirmation").hide();
                $("#pnlComplete").show();

                CommonUtil.SELECT_RESERVATION_STATUS= restData.reservationStatus;
                if(restData.reservationStatus == CommonUtil.RESERVATION_UPCOMING || restData.reservationStatus == CommonUtil.RESERVATION_ACTIVATION_DUE){
                    CommonUtil.RESERVATION_CATEGORY = CommonUtil.RESERVATION_ACTIVATION;
                }else if(restData.reservationStatus == CommonUtil.RESERVATION_INPROGRESS){
                    CommonUtil.RESERVATION_CATEGORY = CommonUtil.RESERVATION_IN_ACTIVATION;
                }
            }
        }
    }

    let onClickBackPickLoc = ()=>{
        props.onBack();
    }
    let onClickBackLicense = ()=>{
        stopWebCamera(videoRef);
        $("#dropLocation").show();
        $("#licensePlate").hide();
    }

    let onClickBackConfirmation = ()=>{
        $("#pnlConfirmation").hide();
        if(CommonUtil.RESERVATION_CATEGORY == CommonUtil.RESERVATION_ACTIVATION) {
            if(CommonUtil.SELECT_EQUIPMENT_TYPE == CommonUtil.REEFER_EQUIP_TYPE){
                reeferPath = "";
                $("#pnlReefer").show();
                panelType = REEFER_PANEL;
                startWebCamera(videoEngineRef);
                $("#divEngineHour").removeClass("disable-form");
            }else{
                hodoMeterPath = "";
                $("#pnlHodometer").show();
                panelType = HODOMETER_PANEL;
                $("#divHodometer").removeClass("disable-form");
                startWebCamera(videoHodoRef);
            }

        }else{
            $("#pnlPOD").show();
            panelType = POD_PANEL;
            $("#divPODmeter").removeClass("disable-form");
            startWebCamera(videoPODRef);

        }
    }

    let onClickRetunrHome = ()=>{
       // navigate('processReserve');
        hidePanels();
        props.returnToHome();
    }

    let onClickRervationDetails = ()=>{
        //navigate('processview');
        hidePanels();
        props.onBack();
        //navigate(-1);
    }

    let onClickBackHodometer = ()=>{
        $("#pnlHodometer").hide();
        $("#damageImages").show();
        stopWebCamera(videoHodoRef);
    }

    let onClickBackEngineHours = ()=>{
        //hodoMeterPath = "";
        //$("#pnlHodometer").show();
        $("#pnlReefer").hide();
        stopWebCamera(videoEngineRef);

        $("#pnlPOD").show();
        panelType = POD_PANEL;
        $("#divPODmeter").removeClass("disable-form");
        startWebCamera(videoPODRef);
    }
    let onClickBackPOD = ()=>{
        if(CommonUtil.SELECT_EQUIPMENT_TYPE == CommonUtil.REEFER_EQUIP_TYPE){
            /*reeferPath = "";
            $("#pnlReefer").show();
            $("#pnlPOD").hide();
            panelType = REEFER_PANEL;
            startWebCamera(videoEngineRef);
            $("#divEngineHour").removeClass("disable-form");*/
            hodoMeterPath = "";
            $("#pnlHodometer").show();
            $("#pnlPOD").hide();
            panelType = HODOMETER_PANEL;
            stopWebCamera(videoPODRef);
        }else{
            hodoMeterPath = "";
            $("#pnlHodometer").show();
            $("#pnlPOD").hide();
            panelType = HODOMETER_PANEL;
            stopWebCamera(videoPODRef);
        }

    }
    let onClickBackNewDamages = ()=>{
        stopWebCamera(videodmgRef);
        $("#damageNewImages").hide();
        $("#damageImages").show();
    }

    let onClickStar = (val:any)=>{
        rating = val;
        let ratings:any = [{key:1,Value:'spnStar1'},{key:2,Value:'spnStar2'},{key:3,Value:'spnStar3'},{key:4,Value:'spnStar4'},{key:5,Value:'spnStar5'}];
        for(var i:number = 0;i<5;i++){
            $("#"+ratings[i].Value).removeClass("fa-solid fa-star").addClass("fa-regular fa-star");
        }
        for(var j:number = 0;j<val;j++){
            $("#"+ratings[j].Value).removeClass("fa-regular fa-star").addClass("fa-solid fa-star");
        }
    }

    let onClickDSideReTakePhoto = ()=>{
        $("#driversideoutput").show();
        $("#driversideoutput2").hide();
        $("#divDSide").removeClass("disable-form");
        startWebCamera(videoDSideRef);
    }

    let onClickAddDriverSideImages = ()=>{


    }

    let onClickDSideUploadPhoto = ()=>{
        panelType = INSPECTION_PANEL;
        onClickBrowse();
    }

    let onClickDSideTakePhoto = ()=>{
        $("#divDSide").addClass("disable-form");
        var photo = document.getElementById('driversideoutput2');
        var video = document.getElementById('driversideoutput');
        var context = null;
        if(canvasDSideRef && canvasDSideRef.current) {
            context = canvasDSideRef.current.getContext('2d');
            canvasDSideRef.current.width = picWidth;
            canvasDSideRef.current.height = picHeight;
            if (context && video && videoDSideRef.current && photo) {
                context.drawImage(videoDSideRef.current, 0, 0, picWidth, picHeight);
                var data = canvasDSideRef.current.toDataURL('image/png');
                //console.log(data);
                imageBinaryData = data;
                photo.setAttribute('src', data);

                $("#driversideoutput").hide();
                $("#driversideoutput2").show();

                stopWebCamera(videoDSideRef);

                imageFileName = new Date().getTime() + ".png";
                imagePath = 'images/equipment/inspection/' + imageFileName;
                category = CommonUtil.RESERVATION_CATEGORY;

                if(inspectionScreenTitle == CommonUtil.DRIVER_SIDE_EXTERIOR){
                    documentType = CommonUtil.EQUIP_DRIVER_SIDE;
                }else if(inspectionScreenTitle == CommonUtil.EQUIP_DRIVER_TIRES){
                    documentType = CommonUtil.EQUIP_DRIVER_TIRES;
                }else if(inspectionScreenTitle == CommonUtil.EQUIP_REAR_EXTERIOR){
                    documentType = CommonUtil.EQUIP_REAR_EXTERIOR;
                }else if(inspectionScreenTitle == CommonUtil.VAN_INTERIOR){
                    documentType = CommonUtil.VAN_INTERIOR;
                }else if(inspectionScreenTitle == CommonUtil.PASSENGER_SIDE){
                    documentType = CommonUtil.PASSENGER_SIDE;
                }else if(inspectionScreenTitle == CommonUtil.PASSENGER_SIDE_TIRE){
                    documentType = CommonUtil.PASSENGER_SIDE_TIRE;
                }else if(inspectionScreenTitle == CommonUtil.FRONT_EXTERIOR){
                    documentType = CommonUtil.FRONT_EXTERIOR;
                }

                uploadType = UPLOAD_TYPE_CAMERA;
                uploadDriverSideImages();
            }
        }
    }

    let uploadDriverSideImages = ()=>{
        BusyLoader.showLoader();
        var qParam: string = 'objectPath=' + imagePath //images/equipment/"+CommonUtil.getSelectedOrgId()+"/"+fileName;;
        NetworkManager.sendJsonRequest('api/session/uploadUrl', '', qParam, 'GET', false, onUploadDriverSide, onLoginError)
    }

    let onUploadDriverSide = (dataObj: any) => {
        if (dataObj) {
            var strUploadPath = dataObj;
            const formData = new FormData();
            //  formData.append("content", imageBinaryData);
            var myHeaders = new Headers();
            //var file = filedocref.current.files[0];
            // var imagebase64data = imageBinaryData.replace('data:image/png;base64,', '');
           // var file = dataURLtoFile(imageBinaryData, imageFileName);

            var file = null;//dataURLtoFile(imageBinaryData, imageFileName);
            if(uploadType == UPLOAD_TYPE_CAMERA){
                file = dataURLtoFile(imageBinaryData, imageFileName);
            }else{
                file = dropFileObj.current;
            }
            //console.log(file);
            var requestOptions: any = {method: 'PUT', headers: myHeaders, body: file, redirect: 'follow'};
            fetch(strUploadPath, requestOptions)
                .then((response) => response.text())
                .then((result) => {
                   // BusyLoader.hideLoader();
                    // console.log(result);
                    uploadInpsectionImages();
                })
                .catch((error) => {
                    BusyLoader.hideLoader();
                    console.log('error', error)
                })
        }
    }

    let uploadInpsectionImages = ()=>{
        let reqObj: any = {}
        let obj: any = {}
        obj.documentType = documentType;//CommonUtil.LICENSE_IMAGES;
        obj.Type = documentType;//CommonUtil.LICENSE_IMAGES;;
        obj.fullFilePath = imagePath;
        obj.saasOrgId = CommonUtil.getSelectedOrgId();
        obj.entityId = CommonUtil.SELECT_EQUIP_ID;
        obj.reservationCode = CommonUtil.SELECT_RESERVATION_CODE;
        obj.reservationId = CommonUtil.SELECT_RESERVATION_ID;
        obj.attachmentCategory = CommonUtil.RESERVATION_CATEGORY;//CommonUtil.LICENSE_IMAGES;
        obj.attachmentDescription = description;//licenseNum;
        obj.UOM = unitMeasurement;
        obj.visibility = "public";

        reqObj.attachments = [obj];
        let arr: any = [];
        arr.push(obj);

        let attachmentUrl: string = 'api/session/rp/equipment/attachments?rpKey=' + CommonUtil.RP_KEY;//localStorage.getItem('saasorgId');
        NetworkManager.sendJsonRequest(attachmentUrl, arr, '', 'PUT', false, onuploadInpsectionSuccess, onLoginError);
    }
    let onuploadInpsectionSuccess = (dataObj: any) => {
        //console.log(dataObj);
        BusyLoader.hideLoader();
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if(restData){
               // getEquipmentnspectionDetails();
                let qParam: string = 'objectPath=' + imagePath;
                NetworkManager.sendJsonRequest('api/session/downloadUrl', '', qParam, 'GET', true, (dataObj: any) => {
                        let obj: any = {};
                        obj.Type = documentType;
                        obj.Path = dataObj;
                         obj.attachementId = restData[0]?restData[0].attachementId:"";//restDataattachmentList1[attchIdx1].attachementId;
                        obj.attachmentDescription = description;//attachmentList1[attchIdx1].attachmentDescription;
                        obj.attachmentCategory = CommonUtil.RESERVATION_CATEGORY;
                        equiImages1.push(obj);
                        //setEquipDriverSideImagesPath(equiImages1);
                        getEquipmentnspectionDetails();
                        setPage(""+Math.random()*100000);
                       // $("#damageImages").show();
                       // $("#damageNewImages").hide();
                        // attchIdx1 = attchIdx1 + 1;
                        //showDriverSideAttachments();
                    },
                    onLoginError,
                )
            }else{

            }
        }
        // addDamageImages = false;
    }
    let getEquipmentnspectionDetails = ()=>{
        equiImages1 = [];
        attchIdx1 = 0;
        attachmentList1 = [];
        damageImagesCount = 0;
        let equipmentAPI:string = "api/session/rp/equipment/"+CommonUtil.SELECT_EQUIP_ID+"?rpKey="+CommonUtil.RP_KEY;
        NetworkManager.sendJsonRequest(equipmentAPI,"","","GET",true,onGetEquipmentInspectonDetails,onLoginError);
    }
    let onGetEquipmentInspectonDetails = (dataObj:any)=>{
        console.log(dataObj);
        if(dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                setEquipType(restData.equipmentType ? restData.equipmentType : "-");
                setEquipName(restData.genericEquipmentName ? restData.genericEquipmentName : "-");
                setUnitNumber(restData.unitNumber ? restData.unitNumber : "-");
                setVinNumber(restData.VIN ? restData.VIN : "-");

                setYear(restData.year ? restData.year : "-");
                setModel(restData.model ? restData.model : "-");
                setBrand(restData.brand ? restData.brand : "-");
                setNumOfAxes(restData.numberOfAxles ? restData.numberOfAxles : "-");
                setTrailerLength(restData.lengthOfTrailer ? restData.lengthOfTrailer : "-");
                equipLicensePlateNumber = (restData.licensePlateNumber ? restData.licensePlateNumber : "");
                setELicense(restData.licensePlateNumber ? restData.licensePlateNumber : "-");
                setExpDate(restData.expDate ? restData.expDate : "-");
                setAssetType(restData.assetType ? restData.assetType : "-");
                setExpDate(restData.annualMechanicalInspExpDate ? restData.annualMechanicalInspExpDate : "-");

                setActivative(restData.activationInstructions?restData.activationInstructions:"");
                setDeActive(restData.deactivationInstructions?restData.deactivationInstructions:"");

                if (restData.specifications) {
                    if (restData.specifications.general) {
                        setAxelSpread(restData.specifications.general.axleSpread ? restData.specifications.general.axleSpread : "-");
                        setTireInflation(restData.specifications.general.tireInflationSystem ? restData.specifications.general.tireInflationSystem : "-");
                        setSkirts(restData.specifications.general.skirts ? restData.specifications.general.skirts : "-");
                        setPintleHook(restData.specifications.general.pintleHook ? restData.specifications.general.pintleHook : "-");
                        setHazmat(restData.specifications.general.hazmatPlacardHolder ? restData.specifications.general.hazmatPlacardHolder : "-");
                        setSuspension(restData.specifications.general.suspensionType ? restData.specifications.general.suspensionType : "-");
                    }
                    if (restData.specifications.trailer) {
                        setRearDoor(restData.specifications.trailer.rearDoor ? restData.specifications.trailer.rearDoor : "-");
                        setSideDoor(restData.specifications.trailer.sideDoor ? restData.specifications.trailer.sideDoor : "-");
                        setRoof(restData.specifications.trailer.roof ? restData.specifications.trailer.roof : "-");
                        setFloor(restData.specifications.trailer.floor ? restData.specifications.trailer.floor : "-");
                        setFloorType(restData.specifications.trailer.floorType ? restData.specifications.trailer.floorType : "-");
                        setInterior(restData.specifications.trailer.interiorFinish ? restData.specifications.trailer.interiorFinish : "-");
                        setLogistics(restData.specifications.trailer.logisticPostsDistance ? restData.specifications.trailer.logisticPostsDistance : "-");
                        setDeckBeams(restData.specifications.trailer.doubleDeckBeams ? restData.specifications.trailer.doubleDeckBeams : "-");
                    }
                    if (restData.specifications.lift) {
                        setLift(restData.specifications.lift.lift);
                        setLiftType(restData.specifications.lift.liftType);
                        setLiftCap(restData.specifications.lift.liftCapacity);
                    }

                    if (restData.specifications.reefer) {
                        setMultiTemp(restData.specifications.reefer.multiTemperature);
                        setUnitModal(restData.specifications.reefer.unitMake);
                        setUnitMake(restData.specifications.reefer.unitModel);
                        setUnitYear(restData.specifications.reefer.unitYear);
                    }
                }

                if (restData.attachments) {
                    var list = [];
                    attachmentList = []
                    attchIdx = 0;
                    if (Array.isArray(restData.attachments)) {
                        list = restData.attachments
                    } else {
                        list.push(restData.attachments)
                    }
                    list.forEach((item:any,idx:number)=>{
                        if(item.documentType && item.fullFilePath && item.attachmentCategory == CommonUtil.RESERVATION_CATEGORY &&  CommonUtil.SELECT_RESERVATION_ID == item.reservationId && (item.documentType == CommonUtil.EQUIP_DAMAGES || item.documentType == CommonUtil.FRONT_EXTERIOR || item.documentType == CommonUtil.PASSENGER_SIDE_TIRE || item.documentType == CommonUtil.PASSENGER_SIDE || item.documentType == CommonUtil.VAN_INTERIOR  || item.documentType == CommonUtil.EQUIP_REAR_EXTERIOR  || item.documentType == CommonUtil.EQUIP_DRIVER_SIDE || item.documentType == CommonUtil.EQUIP_DRIVER_TIRES)){
                            attachmentList1.push(item);
                        }

                        if(item.documentType && item.fullFilePath && item.attachmentCategory == CommonUtil.RESERVATION_CATEGORY &&  CommonUtil.SELECT_RESERVATION_ID == item.reservationId && item.documentType == CommonUtil.EQUIP_DAMAGES){
                            damageImagesCount =  damageImagesCount+1;
                        }

                    })
                    if(damageImagesCount>0){
                        setDamages(true);
                    }else{
                        setDamages(false);
                    }
                    if (attachmentList1.length > 0) {
                        BusyLoader.showLoader()
                        showDriverSideAttachments()
                    }else{
                        setEquipDriverSideImagesPath([]);
                        setPage(""+Math.random()*10000);
                    }
                }
            }
        }
    }

    let showDriverSideAttachments = () => {
        if (attachmentList1.length > attchIdx1) {
            let qParam: string = 'objectPath=' + attachmentList1[attchIdx1].fullFilePath
            NetworkManager.sendJsonRequest('api/session/downloadUrl', '', qParam, 'GET', false, (dataObj: any) => {
                    let obj: any = {};
                    obj.Type = attachmentList1[attchIdx1].documentType;
                    obj.Path = dataObj;
                    obj.attachementId = attachmentList1[attchIdx1].attachementId;
                    obj.attachmentDescription = attachmentList1[attchIdx1].attachmentDescription;
                    obj.attachmentCategory = attachmentList1[attchIdx1].attachmentCategory;
                    equiImages1.push(obj);
                    attchIdx1 = attchIdx1 + 1;
                    showDriverSideAttachments();
                },
                onLoginError,
            )
        } else {
            BusyLoader.hideLoader();
            setEquipDriverSideImagesPath(equiImages1);
            setPage(""+Math.random()*10000);
            if(addDamageImages){
                $("#damageImages").show();
                $("#damageNewImages").hide();
            }
            addDamageImages = false;
        }

    }



    let onClickRemoveImage = (attachId:string)=>{
        Confirm.show('Confirm', 'Do you want remove Image?', [{label: 'Yes', callback: function () {
                if (attachId) {
                    //fileAttachmentId = attachId;
                    let removeAPI: string = 'api/session/rp/equipment/attachment/' + attachId + '?rpKey=' +CommonUtil.RP_KEY;
                    //console.log(removeAPI)
                    NetworkManager.sendJsonRequest(removeAPI, '', '', 'DELETE', true, onDeleteEquipmentImage, onLoginError);
                }
            },
        },
            {label: 'No'},
        ])
    }
    let onDeleteEquipmentImage = (dataObj: any) => {
        //console.log(dataObj)
        if(dataObj == "success"){
            ToastManager.showToast("Removed Successfully", 'Info');
            // removeSelectedImage(fileAttachmentId);
        }else{
            ToastManager.showToast("Error", 'Error')
        }

        getEquipmentnspectionDetails();
    }


    let onClickAddDriverSideExterior = ()=>{
        inspectionScreenTitleCopy = CommonUtil.DRIVER_SIDE_EXTERIOR;
        setInspectionScreenTitle(CommonUtil.DRIVER_SIDE_EXTERIOR);
        setInspectionImageFormat(CommonUtil.EQUIP_DRIVER_SIDE);
        showInspectionAddScreen();
    }
    let onClickAddDriverSideTires = ()=>{
        inspectionScreenTitleCopy = CommonUtil.EQUIP_DRIVER_TIRES;
        setInspectionScreenTitle(CommonUtil.EQUIP_DRIVER_TIRES);
        setInspectionImageFormat(CommonUtil.EQUIP_DRIVER_TIRES);
        showInspectionAddScreen();
    }

    let onClickAddDriverRearExterior = ()=>{
        inspectionScreenTitleCopy = CommonUtil.EQUIP_REAR_EXTERIOR;
        setInspectionScreenTitle(CommonUtil.EQUIP_REAR_EXTERIOR);
        setInspectionImageFormat(CommonUtil.EQUIP_REAR_EXTERIOR);
        showInspectionAddScreen();
    }
    let onClickAddVanInterior = ()=>{
        inspectionScreenTitleCopy = CommonUtil.VAN_INTERIOR;
        setInspectionScreenTitle(CommonUtil.VAN_INTERIOR);
        setInspectionImageFormat(CommonUtil.VAN_INTERIOR);
        showInspectionAddScreen();
    }
    let onClickAddExteriorPassSide = ()=>{
        inspectionScreenTitleCopy = CommonUtil.PASSENGER_SIDE;
        setInspectionScreenTitle(CommonUtil.PASSENGER_SIDE);
        setInspectionImageFormat(CommonUtil.PASSENGER_SIDE);
        showInspectionAddScreen();
    }
    let onClickAddExteriorPassSideTire = ()=>{
        inspectionScreenTitleCopy = CommonUtil.PASSENGER_SIDE_TIRE;
        setInspectionScreenTitle(CommonUtil.PASSENGER_SIDE_TIRE);
        setInspectionImageFormat(CommonUtil.PASSENGER_SIDE_TIRE);
        showInspectionAddScreen();
    }
    let onClickAddFrontExterior = ()=>{
        inspectionScreenTitleCopy = CommonUtil.FRONT_EXTERIOR;
        setInspectionScreenTitle(CommonUtil.FRONT_EXTERIOR);
        setInspectionImageFormat(CommonUtil.FRONT_EXTERIOR);
        showInspectionAddScreen();
    }
    let showInspectionAddScreen = ()=>{
        BusyLoader.showLoader();
        setTimeout(()=>{
            $("#driversideoutput").show();
            $("#driversideoutput2").hide();
            $("#pnlInspection").hide();
            $("#pnlAddInspection").show();
            panelType = INSPECTION_PANEL;
            $("#divDSide").removeClass("disable-form");
            startWebCamera(videoDSideRef);
            BusyLoader.hideLoader();
        },2000);
    }


    let onClickBackDriversideExterior = ()=>{
        $("#driversideoutput").show();
        $("#driversideoutput2").hide();
        $("#pnlInspection").show();
        $("#pnlAddInspection").hide();
        stopWebCamera(videoDSideRef);
    }

    let hidePanels = ()=>{
        $("#licensePlate").hide();
        $("#pnlInspection").hide();
        $("#damageImages").hide();
        $("#damageNewImages").hide();
        $("#pnlHodometer").hide();
        $("#pnlPOD").hide();
        $("#pnlConfirmation").hide();
        $("#pnlComplete").hide();
        $("#pnlAddInspection").hide();
        $("#pnlReefer").hide();
    }

    let onSelectFiles = (e: any) => {
        //console.log(e);
        uploadType = UPLOAD_TYPE_GALLARY;
        var photo = null;
        if(panelType == LICENSE_PANEL){
            stopLicensePanelCamera();
            photo = document.getElementById('output2');
        }else if(panelType == DAMAGES_PANEL){
            stopDamagesPanelCamera();
            photo = document.getElementById('dgmoutput2');
        }else if(panelType == HODOMETER_PANEL){
            stopHodometerPanelCamers();
            photo = document.getElementById('hodooutput2');
        }else if(panelType == POD_PANEL){
            stopPOSPanelCamera();
            photo = document.getElementById('podooutput2');
        }else if(panelType == REEFER_PANEL){
            stopReeferPanelCamera();
            photo = document.getElementById('engineoutput2');
        }else if(panelType == INSPECTION_PANEL){
            stopInspectionPanelCamera();
            photo = document.getElementById('driversideoutput2');
        }

        if (e && e.target && e.target.files[0]) {
            let fileName = e.target.files[0].name;
            dropFileObj.current = e.target.files[0];
            uploadType = UPLOAD_TYPE_GALLARY;
            showLicensePhoto(photo,dropFileObj);
        }
    }

    const handleDragOver = (e:any) => {
        e.preventDefault()
        e.stopPropagation()
    }

    let stopLicensePanelCamera = ()=>{
        uploadType = UPLOAD_TYPE_GALLARY;
        $("#divTakePhoto").addClass("disable-form");
        $("#output").hide();
        $("#output2").show();
        stopWebCamera(videoRef);
    }

    let stopDamagesPanelCamera = ()=>{
        uploadType = UPLOAD_TYPE_GALLARY;
        $("#divDamageNewImages").addClass("disable-form");
        $("#dmgoutput").hide();
        $("#dgmoutput2").show();
        stopWebCamera(videodmgRef);
    }

    let stopHodometerPanelCamers = ()=>{
        uploadType = UPLOAD_TYPE_GALLARY;
        $("#divHodometer").addClass("disable-form");
        $("#hodooutput").hide();
        $("#hodooutput2").show();

        stopWebCamera(videoHodoRef);
    }

    let stopPOSPanelCamera = ()=>{
        uploadType = UPLOAD_TYPE_GALLARY;
        $("#divPODmeter").addClass("disable-form");
        $("#podoutput").hide();
        $("#podooutput2").show();

        stopWebCamera(videoPODRef);
    }

    let stopReeferPanelCamera = ()=>{
        uploadType = UPLOAD_TYPE_CAMERA;
        $("#divEngineHour").addClass("disable-form");
        $("#engineoutput").hide();
        $("#engineoutput2").show();

        stopWebCamera(videoEngineRef);
    }

    let stopInspectionPanelCamera = ()=>{
        uploadType = UPLOAD_TYPE_CAMERA;
        $("#divDSide").addClass("disable-form");
        $("#driversideoutput").hide();
        $("#driversideoutput2").show();

        stopWebCamera(videoDSideRef);
    }
    let showLicensePhoto = (photo:any,divobj:any)=>{
        uploadType = UPLOAD_TYPE_GALLARY;
        var oFReader = new FileReader();
        oFReader.readAsDataURL(divobj.current);
        oFReader.onload = function (oFREvent) {
            if (photo && oFREvent.target && oFREvent.target.result) {
                photo.setAttribute('src', oFREvent.target?.result.toString());
                console.log(divobj.current);
                if(panelType == LICENSE_PANEL) {
                    imageFileName = new Date().getTime() + divobj.current.name;
                    imagePath = 'images/equipment/license/' + imageFileName;
                    licensePath = imagePath;
                    licenseNumber = licenseNum;
                    documentType = CommonUtil.LICENSE_IMAGES;
                    imagesUpload();
                }else if(panelType == DAMAGES_PANEL){
                    imageFileName = new Date().getTime() + divobj.current.name;
                    imagePath = 'images/equipment/license/'+ imageFileName;
                    documentType = CommonUtil.EQUIP_DAMAGES;
                    // category = CommonUtil.RESERVATION_CATEGORY;
                    description = desc;
                    imagesUpload();
                }else if(panelType == HODOMETER_PANEL){
                    imageFileName = new Date().getTime()+divobj.current.name;
                    imagePath = 'images/equipment/vodometer/'+ imageFileName;
                    category = CommonUtil.RESERVATION_CATEGORY;
                    description = "";
                    documentType = CommonUtil.EQUIP_HODOMETER;
                    hodoMeterPath = imagePath;
                    hodometerNumber = hodometer;
                    imagesUpload();
                }else if(panelType == POD_PANEL){
                    imageFileName = new Date().getTime()+divobj.current.name;
                    imagePath = 'images/equipment/pod/'+ imageFileName;
                    category = CommonUtil.RESERVATION_CATEGORY;
                    description = "";
                    documentType = CommonUtil.EQUIP_POD;

                    podPath = imagePath;
                    hodometerNumber = "";
                    imagesUpload();
                }else if(panelType == REEFER_PANEL){
                    imageFileName = new Date().getTime()+divobj.current.name;
                    imagePath = 'images/equipment/refer/'+ imageFileName;
                    category = CommonUtil.RESERVATION_CATEGORY;
                    description = "";
                    documentType = CommonUtil.EQUIP_REEFER_TYPE;

                    reeferPath = imagePath;
                    reeferNumber = enginehours;
                    imagesUpload();
                }else if(panelType == INSPECTION_PANEL){
                    imageFileName = new Date().getTime()+divobj.current.name;
                    imagePath = 'images/equipment/inspection/' + imageFileName;
                    category = CommonUtil.RESERVATION_CATEGORY;

                    if(inspectionScreenTitle == CommonUtil.DRIVER_SIDE_EXTERIOR || inspectionScreenTitleCopy == CommonUtil.DRIVER_SIDE_EXTERIOR) {
                        documentType = CommonUtil.EQUIP_DRIVER_SIDE;
                    }else if(inspectionScreenTitle == CommonUtil.EQUIP_DRIVER_TIRES || inspectionScreenTitleCopy == CommonUtil.EQUIP_DRIVER_TIRES){
                        documentType = CommonUtil.EQUIP_DRIVER_TIRES;
                    }else if(inspectionScreenTitle == CommonUtil.EQUIP_REAR_EXTERIOR || inspectionScreenTitleCopy == CommonUtil.EQUIP_REAR_EXTERIOR){
                        documentType = CommonUtil.EQUIP_REAR_EXTERIOR;
                    }else if(inspectionScreenTitle == CommonUtil.VAN_INTERIOR || inspectionScreenTitleCopy == CommonUtil.VAN_INTERIOR){
                        documentType = CommonUtil.VAN_INTERIOR;
                    }else if(inspectionScreenTitle == CommonUtil.PASSENGER_SIDE || inspectionScreenTitleCopy == CommonUtil.PASSENGER_SIDE){
                        documentType = CommonUtil.PASSENGER_SIDE;
                    }else if(inspectionScreenTitle == CommonUtil.PASSENGER_SIDE_TIRE || inspectionScreenTitleCopy == CommonUtil.PASSENGER_SIDE_TIRE){
                        documentType = CommonUtil.PASSENGER_SIDE_TIRE;
                    }else if(inspectionScreenTitle == CommonUtil.FRONT_EXTERIOR || inspectionScreenTitleCopy == CommonUtil.FRONT_EXTERIOR){
                        documentType = CommonUtil.FRONT_EXTERIOR;
                    }
                    uploadDriverSideImages();
                }



            }
        }
    }


    const handleDrop = (e:any) => {
        e.preventDefault()
        e.stopPropagation()

        console.log(e.dataTransfer);
        const { files } = e.dataTransfer;
        if (files && files.length) {
            var photo:any = null;
            dropFileObj.current = files[0];
            if(panelType == LICENSE_PANEL) {
                stopLicensePanelCamera();
                photo = document.getElementById('output2');
            }else if(panelType == DAMAGES_PANEL){
                stopDamagesPanelCamera();
                photo = document.getElementById('dgmoutput2');
            }else if(panelType == HODOMETER_PANEL){
                stopHodometerPanelCamers()
                photo = document.getElementById('hodooutput2');
            }else if(panelType == POD_PANEL){
                stopPOSPanelCamera();
                photo = document.getElementById('podooutput2');
            }else if(panelType == REEFER_PANEL){
                stopReeferPanelCamera();
                photo = document.getElementById('engineoutput2');
            }else if(panelType == INSPECTION_PANEL){
                stopInspectionPanelCamera();
                photo = document.getElementById('driversideoutput2');
            }
            //console.log(inspectionScreenTitle);
            //console.log(inspectionScreenTitleCopy);
            if(inspectionScreenTitleCopy == CommonUtil.DRIVER_SIDE_EXTERIOR){
                setInspectionScreenTitle(CommonUtil.DRIVER_SIDE_EXTERIOR);
            }else if(inspectionScreenTitleCopy == CommonUtil.EQUIP_DRIVER_TIRES){
                setInspectionScreenTitle(CommonUtil.EQUIP_DRIVER_TIRES);
            }else if(inspectionScreenTitleCopy == CommonUtil.EQUIP_REAR_EXTERIOR){
                setInspectionScreenTitle(CommonUtil.EQUIP_REAR_EXTERIOR);
            }else if(inspectionScreenTitleCopy == CommonUtil.VAN_INTERIOR){
                setInspectionScreenTitle(CommonUtil.VAN_INTERIOR);
            }else if(inspectionScreenTitleCopy == CommonUtil.PASSENGER_SIDE){
                setInspectionScreenTitle(CommonUtil.PASSENGER_SIDE);
            }else if(inspectionScreenTitleCopy == CommonUtil.PASSENGER_SIDE_TIRE){
                setInspectionScreenTitle(CommonUtil.PASSENGER_SIDE_TIRE);
            }else if(inspectionScreenTitleCopy == CommonUtil.FRONT_EXTERIOR){
                setInspectionScreenTitle(CommonUtil.FRONT_EXTERIOR);
            }
            setTimeout(()=>{
                showLicensePhoto(photo,dropFileObj);
            })

        }
    }

    const handleDragEnter = (e:any) => {
        e.preventDefault()
        e.stopPropagation()
    }
    const handleDragLeave = (e:any) => {
        e.preventDefault()
        e.stopPropagation()
    }

    const onSelectDrop = (e: any) => {
        //console.log(e);
        if (e && e.name) {
            let fileName = e.name;
            setFileName(fileName);
            /*BusyLoader.showLoader();
            if (fileType == CommonUtil.EQUIP_DOCS) {
                imagePath = 'documents/equipment/' + CommonUtil.getSelectedOrgId() + '/' + fileName + '-' + new Date().getTime()
            } else {
                imagePath = 'images/equipment/' + CommonUtil.getSelectedOrgId() + '/' + fileName + '-' + new Date().getTime()
            }
            let qParam: string = 'objectPath=' + imagePath;
            NetworkManager.sendJsonRequest("api/session/uploadUrl", "", qParam, "GET", false, onUploadSuccessDrop, onLoginError);*/
        }
    }

    let onLoginError = (data:any)=>{
        console.log(data);
    }

    return(
        <>
            <input type="file" ref={fileref} className="custom-file-input " id="inputGroupFile"
                   accept="image/*" style={{display: 'none'}} onChange={(e) => {
                onSelectFiles(e)
            }}></input>
            <div id="wrapper" className="enlarged">
                <div id="dropLocation"  className="content-page">
                    <div className="content m-0">
                        <div className="resa-vation bg-dark text-white p-2 mb-1">
                            <div className="row m-0">
                                <div className="col-6 pr-0">
                                    <h6 className="m-0"  onClick={onClickBackPickLoc} style={{paddingTop:"5px"}}>
                                        <a className="text-white" style={{textDecoration:"none"}}>
                                            <i className="fa fa-arrow-left"> </i> &nbsp; Pickup Location</a></h6>
                                </div>
                                <div className="col-6 text-right">
                                    <button data-toggle="modal" data-target=".bs-example-modal-center-offer-details"
                                            type="submit"
                                            className="btn-sm btn-warning waves-effect waves-light d-inline-block">{CommonUtil.SELECT_RESERVATION_STATUS}
                                    </button>
                                    <h6 className="mb-0 ml-2 d-inline-block">{CommonUtil.SELECT_RESERVATION_CODE}</h6>
                                </div>
                            </div>
                        </div>
                        <div className="page-content-wrapper" style={{height:"85vh",overflow:"auto",overflowX:"hidden"}}>
                            <div className="row">
                                <div className="col-12">
                                    {/*<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d26372240.94072088!2d-113.7346605533982!3d36.20868787736577!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54eab584e432360b%3A0x1c3bb99243deb742!2sUnited%20States!5e0!3m2!1sen!2sin!4v1667188564105!5m2!1sen!2sin"
                                        width="100%" height="300"  loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>*/}
                                    <div className="responsive-map" style={{height:"50vh",overflow:"none"}}>
                                        <div className="map-container">
                                            <div ref={mapRef} className="map-container__map" style={{height:"50vh"}}></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <section>
                                <div className="row">
                                    <div className="col-12">
                                        <h6 className="mb-4">Parking Lot Details</h6>
                                    </div>
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-5">Parking Lot</div>
                                            <div className="col-7 colon"><strong>{parkingLot}</strong></div>
                                        </div>
                                        <div className="row pt-1">
                                            <div className="col-5">Address</div>
                                            <div className="col-7 colon"><strong>{address}</strong></div>
                                        </div>
                                        <div className="row pt-1">
                                            <div className="col-5">Phone Number</div>
                                            <div className="col-7 colon"><strong>{phoneNum?('+'+phoneNum):"-"}</strong></div>
                                        </div>
                                        <div className="row pt-1">
                                            <div className="col-5">Instructions</div>
                                            <div className="col-7 colon"><strong>{instructions}</strong></div>
                                        </div>
                                        <div className="row pt-1" style={{display:(twicAccess?"":"none")}}>
                                            <div className="col-5"></div>
                                            <div className="col-7 colon"><span className="details-head" style={{fontWeight:"bold"}}>{CommonUtil.getMessageText(Constants.TWIC_ACCESS_REQUIRED, "This pickup location requires TWIC Access.")}</span></div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section>
                                <div className="row">
                                    <div className="col-12">
                                        <h6 className="mb-2">Owner Information</h6>
                                    </div>
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-12"><strong className="mb-2 d-block">{orgName}</strong></div>
                                            <div className="col-3 pr-0">{userName}</div>
                                            <div className="col-3 pr-0">{userPhoneNum}</div>
                                            <div className="col-6 pr-0">{userEmail}</div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section>
                                <div className="row">
                                    <div className="col-12">
                                        <h6 className="mb-4">Trailer Information</h6>
                                    </div>
                                    <div className="col-6 col-sm-6 col-md-4">
                                        <p>Equipment Type: <strong className="d-block">{equipType}</strong></p>
                                    </div>

                                    <div className="col-6 col-sm-6 col-md-4">
                                        <p>Reservation Code: <strong className="d-block">{CommonUtil.SELECT_RESERVATION_CODE}</strong></p>
                                    </div>

                                    <div className="col-6 col-sm-6 col-md-4">
                                        <p>Year: <strong className="d-block">{year}</strong></p>
                                    </div>
                                    <div className="col-6 col-sm-6 col-md-4">
                                        <p>Length: <strong className="d-block">{trailerLength}</strong></p>
                                    </div>
                                    <div className="col-6 col-sm-6 col-md-4">
                                        <p>Axles: <strong className="d-block">{numOfAxes}</strong></p>
                                    </div>
                                    <div className="col-6 col-sm-6 col-md-4">
                                        <p>Unit Number: <strong className="d-block">{unitNumber}</strong></p>
                                    </div>
                                    <div className="col-6 col-sm-6 col-md-4">
                                        <p>Model: <strong className="d-block">{model}</strong></p>
                                    </div>
                                    <div className="col-6 col-sm-6 col-md-4">
                                        <p>VIN: <strong className="d-block">{vinNumber}</strong></p>
                                    </div>
                                </div>
                            </section>
                            <section>
                                <div className="row">
                                    <div className="col-12">
                                        <h6 className="mb-4">General Specifications</h6>
                                    </div>
                                    <div className="col-6">
                                        <p>Axle Spread: <strong className="d-block">{axelSpread}</strong></p>
                                    </div>
                                    <div className="col-6">
                                        <p>Tire Inflation System: <strong className="d-block">{tireInflation}</strong></p>
                                    </div>
                                    <div className="col-6">
                                        <p>Suspension: <strong className="d-block">{suspension}</strong></p>
                                    </div>
                                    <div className="col-6" style={{display:"none"}}>
                                        <button type="button" className="btn btn-primary waves-effect waves-light"
                                                data-toggle="modal" data-target=".bs-example-modal-center">popup button
                                        </button>
                                    </div>


                                </div>
                            </section>
                        </div>
                        <div className="row fixed">
                            <div className="col-12 text-center">
                                <button type="button" className="btn btn-primary waves-effect mobile-button-height" data-dismiss="modal" onClick={onClickNextDropLocation}>Next</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="licensePlate"  style={{display:"none"}} className="content-page">
                    <div className="content m-0">
                        <div className="page-content-wrapper">
                            <div className="resa-vation bg-dark text-white p-2 mb-1">
                                <div className="row m-0">
                                    <div className="col-8 pr-0">
                                        <h6 className="m-0" onClick={onClickBackLicense} style={{paddingTop:"5px"}} >
                                            <a  className="text-white" style={{textDecoration:"none"}}>
                                                <i className="fa fa-arrow-left"></i> &nbsp; License Plate Photo
                                            </a>
                                        </h6>
                                    </div>
                                    <div className="col-4 text-right">
                                        <button data-toggle="modal" data-target=".bs-example-modal-center-offer-details"
                                                type="submit"
                                                className="btn-sm btn-warning waves-effect waves-light d-inline-block">{CommonUtil.SELECT_RESERVATION_STATUS}
                                        </button>
                                        <h6 className="mb-0 ml-2 d-inline-block">{CommonUtil.SELECT_RESERVATION_CODE}</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="row m-0 pt-2" style={{fontWeight:"bold"}}>
                                <div className="col-12 text-center">Please take a photo of the License plate</div>
                                <div className="col-12 text-center" style={{display:(CommonUtil.IS_VIN_EXIST?"":"none")}}>Trailer Unit Number: <strong>{unitNumber}</strong></div>
                            </div>
                            <div className="row m-0">
                                <div className="col-12 col-sm-12" style={{textAlign:"center"}}>
                                    <video id="output" autoPlay={true} muted={true} playsInline={true} loop={true}  ref={videoRef} style={{width:picWidth,height:picHeight,objectFit:"cover"}}>Video stream not available.</video>
                                    <img id="output2" ref={imgLicenseRef} className={"camera-class"}  style={{display:"none",width:picWidth,height:picHeight}}/>
                                    <canvas id="output1" ref={canvasRef} style={{display:"none"}}/>
                                </div>

                                <div className="col-12 flex-spacebetween">
                                    <div className="col-3 text-right" style={{"display":"none"}}></div>
                                    <div className="col-4 text-right retake-photo" id="divGallery">
                                        <label className="btn-sm btn-success take-camata-class text-center mb-0" title={"Gallery"} onClick={onClickUploadLicensePhoto}>
                                            <i className="fas fa-camera"></i>
                                        </label>
                                        <span>Gallery</span>
                                    </div>
                                    <div className="col-4 text-right retake-photo" id="divTakePhoto">
                                        <label className="btn-sm btn-success take-camata-class text-center mb-0" title={"Take Photo"} onClick={onClickTakeLicensePhoto}>
                                            <i className="fas fa-camera"></i>
                                        </label>
                                        <span>Take Photo</span>
                                    </div>
                                    <div className="col-1 text-right"></div>
                                    <div className="col-3 text-center" style={{paddingRight:"0px"}}>
                                        <label className="btn-sm retake-image text-center mb-0" title={"Retake Photo"} onClick={onClickStartCamera}>
                                            <i className="fas fa-camera"></i>
                                        </label>
                                        <span>Retake</span>
                                    </div>

                                </div>
                                <div className="col-12" style={{paddingTop:"20px"}}>
                                    <div className="form-group row" style={{display:"flex",justifyContent:"center"}}>
                                        <label className="col-sm-12 col-form-label text-center">Enter the License Plate Number:</label>
                                        <div className="col-sm-12" style={{display:"flex",justifyContent:"center"}}>
                                            <input ref={licenseRef} type="text" className="form-control text-center licese-class"
                                                   placeholder="" value={licenseNum} maxLength={20}
                                                   onChange={(e) => {setLicenseNum(e.currentTarget.value);CommonUtil.isMandatory(licenseRef)}} />
                                        </div>
                                    </div>

                                    <div className="form-group row" style={{display:(CommonUtil.IS_VIN_EXIST?"none":"flex"),justifyContent:"center"}}>
                                        <label className="col-sm-12 col-form-label text-center">Enter the VIN or Trailer Id:</label>
                                        <div className="col-sm-12" style={{display:"flex",justifyContent:"center"}}>
                                            <input ref={vinRef} type="text" className="form-control text-center licese-class"
                                                   placeholder="" value={trailerIdNum} maxLength={20}
                                                   onChange={(e) => {setTrailerIdNum(e.currentTarget.value);CommonUtil.isMandatory(vinRef)}} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <p>&nbsp;</p>
                            <p>&nbsp;</p>


                            <div className="row fixed">
                                <div className="col-12 text-center">
                                    <button type="button" className="btn btn-primary waves-effect mobile-button-height" data-dismiss="modal" onClick={onClickNextLicense}>Next
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div id="pnlInspection" style={{display:"none"}} className="content-page">
                    <div className="content m-0">
                        <div className="page-content-wrapper ">
                            <div className="resa-vation bg-dark text-white p-2 mb-1">
                                <div className="row m-0">
                                    <div className="col-8 pr-0">
                                        <h6 className="m-0 mt-1" onClick={onClickBackInspection}>
                                            <a className="text-white" style={{textDecoration:"none"}}>
                                                <i className="fa fa-arrow-left"></i> &nbsp; Inspection
                                            </a>
                                        </h6>
                                    </div>
                                    <div className="col-4 text-right">
                                        <button data-toggle="modal" type="submit" className="btn-sm btn-warning waves-effect waves-light d-inline-block">
                                            {CommonUtil.SELECT_RESERVATION_STATUS}
                                        </button>
                                        <h6 className="mb-0 ml-2 d-inline-block">{CommonUtil.SELECT_RESERVATION_CODE}</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="row m-0 " id="multiple-capture-image">
                                <div className="col-12">
                                    <h6>Driver side Exterior</h6>
                                    <div className="field flex" >
                                        <label className="btn p-2 text-left tire-class" style={{marginBottom:"1px"}} id="file-input2" onClick={onClickAddDriverSideExterior}>
                                            <i className="fas fa-camera"></i>
                                        </label>
                                        <div style={{display: 'flex',overflow:"auto"}}>
                                            {equipDriverSidePath.map((item: any, idx: number) => {
                                                if (item && item.Type == CommonUtil.EQUIP_DRIVER_SIDE) {
                                                    var list = (
                                                        <div className={'upload-image-item'} key={idx}>
                                                            <a style={{color: 'blue', paddingTop: '0px',paddingLeft:"80px",zIndex:99}}>
                                                                <i className="remove-btn fa fa-remove upload-thumbnail-remove"
                                                                   onClick={() => {onClickRemoveImage(item.attachementId)}} ></i>
                                                            </a>
                                                            <img src={item.Path} className="mobile-thumbnail"></img>

                                                        </div>
                                                    )
                                                    return list
                                                }
                                            })}

                                        </div>
                                    </div>

                                </div>
                                <div className="col-12"><h6>Driver side Tires</h6>
                                    <div className="field flex">
                                        <label className="btn p-2 text-left tire-class" id="file-input3" onClick={onClickAddDriverSideTires}>
                                            <i className="fas fa-camera "></i>
                                        </label>
                                        <div style={{display: 'flex',overflow:"auto"}}>
                                            {equipDriverSidePath.map((item1: any, idx1: number) => {
                                                if (item1 && item1.Type == CommonUtil.EQUIP_DRIVER_TIRES) {
                                                    var list1 = (
                                                        <div className={'upload-image-item'} key={idx1}>
                                                            <a style={{color: 'blue', paddingTop: '5px',zIndex:99}}>
                                                                <i className=" remove-btn fa fa-remove upload-thumbnail-remove"
                                                                   onClick={() => {onClickRemoveImage(item1.attachementId)}} ></i>
                                                            </a>
                                                            <img src={item1.Path} className="mobile-thumbnail"></img>

                                                        </div>
                                                    )
                                                    return list1
                                                }
                                            })}

                                        </div>
                                    </div>
                                </div>
                                <div className="col-12"><h6>Rear Exterior</h6>
                                    <div className="field flex">
                                        <label className="btn p-2 text-left tire-class" id="file-input4" onClick={onClickAddDriverRearExterior}>
                                            <i className="fas fa-camera "></i>
                                        </label>
                                        <div style={{display: 'flex',overflow:"auto"}}>
                                            {equipDriverSidePath.map((item2: any, idx2: number) => {
                                                if (item2 && item2.Type == CommonUtil.EQUIP_REAR_EXTERIOR) {
                                                    var list2 = (
                                                        <div className={'upload-image-item'} key={idx2}>
                                                            <a style={{color: 'blue', paddingTop: '5px',zIndex:99}}>
                                                                <i className=" remove-btn fa fa-remove upload-thumbnail-remove"
                                                                   onClick={() => {onClickRemoveImage(item2.attachementId)}} ></i>
                                                            </a>
                                                            <img src={item2.Path} className="mobile-thumbnail"></img>

                                                        </div>
                                                    )
                                                    return list2
                                                }
                                            })}

                                        </div>
                                    </div>
                                </div>
                                <div className="col-12"><h6>Van Interior / Platform Floor</h6>
                                    <div className="field flex">
                                        <label className="btn p-2 text-left tire-class" id="file-input5" onClick={onClickAddVanInterior}>
                                            <i className="fas fa-camera "></i>
                                        </label>
                                        <div style={{display: 'flex',overflow:"auto"}}>
                                            {equipDriverSidePath.map((item1: any, idx1: number) => {
                                                if (item1 && item1.Type == CommonUtil.VAN_INTERIOR) {
                                                    var list1 = (
                                                        <div className={'upload-image-item'} key={idx1}>
                                                            <a style={{color: 'blue', paddingTop: '5px',zIndex:99}}>
                                                                <i className=" remove-btn fa fa-remove upload-thumbnail-remove"
                                                                   onClick={() => {onClickRemoveImage(item1.attachementId)}} ></i>
                                                            </a>
                                                            <img src={item1.Path} className="mobile-thumbnail"></img>

                                                        </div>
                                                    )
                                                    return list1
                                                }
                                            })}

                                        </div>
                                    </div>
                                </div>
                                <div className="col-12"><h6>Exterior Passenger Side</h6>
                                    <div className="field flex" >
                                        <label className="btn p-2 text-left tire-class" id="file-input6" onClick={onClickAddExteriorPassSide}>
                                            <i className="fas fa-camera "></i>
                                        </label>
                                        {equipDriverSidePath.map((item1: any, idx1: number) => {
                                            if (item1 && item1.Type == CommonUtil.PASSENGER_SIDE) {
                                                var list1 = (
                                                    <div className={'upload-image-item'} key={idx1}>
                                                        <a style={{color: 'blue', paddingTop: '5px',zIndex:99}}>
                                                            <i className=" remove-btn fa fa-remove upload-thumbnail-remove"
                                                               onClick={() => {onClickRemoveImage(item1.attachementId)}} ></i>
                                                        </a>
                                                        <img src={item1.Path} className="mobile-thumbnail"></img>

                                                    </div>
                                                )
                                                return list1
                                            }
                                        })}
                                    </div>

                                </div>
                                <div className="col-12"><h6>Passenger Side Tire</h6>
                                    <div className="field flex" >
                                        <label className="btn p-2 text-left tire-class" id="file-input7" onClick={onClickAddExteriorPassSideTire}>
                                            <i className="fas fa-camera "></i>
                                        </label>
                                        {equipDriverSidePath.map((item1: any, idx1: number) => {
                                            if (item1 && item1.Type == CommonUtil.PASSENGER_SIDE_TIRE) {
                                                var list1 = (
                                                    <div className={'upload-image-item'} key={idx1}>
                                                        <a style={{color: 'blue', paddingTop: '5px',zIndex:99}}>
                                                            <i className=" remove-btn fa fa-remove upload-thumbnail-remove"
                                                               onClick={() => {onClickRemoveImage(item1.attachementId)}} ></i>
                                                        </a>
                                                        <img src={item1.Path} className="mobile-thumbnail"></img>

                                                    </div>
                                                )
                                                return list1
                                            }
                                        })}
                                    </div>

                                </div>
                                <div className="col-12"><h6>Front Exterior</h6>
                                    <div className="field flex">
                                        <label className="btn p-2 text-left tire-class" id="file-input8" onClick={onClickAddFrontExterior}>
                                            <i className="fas fa-camera"></i>
                                        </label>
                                        {equipDriverSidePath.map((item1: any, idx1: number) => {
                                            if (item1 && item1.Type == CommonUtil.FRONT_EXTERIOR) {
                                                var list1 = (
                                                    <div className={'upload-image-item'} key={idx1}>
                                                        <a style={{color: 'blue', paddingTop: '5px',zIndex:99}}>
                                                            <i className=" remove-btn fa fa-remove upload-thumbnail-remove"
                                                               onClick={() => {onClickRemoveImage(item1.attachementId)}} ></i>
                                                        </a>
                                                        <img src={item1.Path} className="mobile-thumbnail"></img>

                                                    </div>
                                                )
                                                return list1
                                            }
                                        })}
                                    </div>

                                </div>

                            </div>
                            <p>&nbsp;</p>
                            <div className="row fixed">
                                <div className="col-12 text-center">
                                    <button type="button" className="btn btn-primary waves-effect mobile-button-height" data-dismiss="modal"
                                    onClick={onClickNextInspection}>Next
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="damageImages" style={{display:"none"}} className="content-page">
                    <div className="content m-0">
                        <div className="page-content-wrapper ">
                            <div className="resa-vation bg-dark text-white p-2 mb-1">
                                <div className="row m-0">
                                    <div className="col-6 pr-0">
                                        <h6 className="m-0" style={{paddingTop:"5px"}} onClick={onClickBackKnowImages}>
                                            <a className="text-white" style={{textDecoration:"none"}}>
                                                <i className="fa fa-arrow-left"></i> &nbsp; Known Damages
                                            </a>
                                        </h6>
                                    </div>
                                    <div className="col-6 text-right pl-0">
                                        <button data-toggle="modal" type="submit"
                                                className="btn-sm btn-warning waves-effect waves-light d-inline-block">
                                            {CommonUtil.SELECT_RESERVATION_STATUS}
                                        </button>
                                        <h6 className="mb-0 ml-1 d-inline-block">{CommonUtil.SELECT_RESERVATION_CODE}</h6>
                                    </div>
                                </div>
                            </div>
                            <section style={{height:"80vh",overflow:"auto",paddingTop:"5px"}}>
                                 <div className="row">
                                        <div className="col-12 text-right mb-4">
                                            <button type="button" className="btn btn-warning waves-effect" data-dismiss="modal"
                                            onClick={onClickAddNewDamagesPanel}>
                                                <i className="fa fa-plus" style={{paddingRight:"5px"}}></i>
                                                Add Damages
                                            </button>
                                        </div>
                                    </div>
                                <div className="row">
                                    {!damages?<h6>Pictures to report the new damages can be added here.</h6>:""}
                                </div>

                                        {equipDriverSidePath.map((item: any, idx: number) => {
                                            if(item.Type == CommonUtil.EQUIP_DAMAGES){
                                                var liste = (
                                                    <div className="row flex" style={{flexDirection:"row"}}>
                                                        <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3" style={{paddingBottom:"10px"}}>
                                                            <img  src={item.Path} alt="" style={{width:"150px",height:"100px"}} />
                                                        </div>
                                                        <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                                            {item.attachmentDescription}
                                                        </div>
                                                    </div>

                                                )
                                                return liste
                                            }

                                        })}

                            </section>
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                            <div className="row fixed">
                                <div className="col-12 text-center">
                                    <button type="button" className="btn btn-primary waves-effect mobile-button-height" data-dismiss="modal" onClick={onClickNextDamages}>Next
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="damageNewImages" style={{display:"none"}} className="content-page">
                    <div className="content m-0">
                        <div className="page-content-wrapper ">
                            <div className="resa-vation bg-dark text-white p-2 mb-1">
                                <div className="row m-0">
                                    <div className="col-5 pr-0">
                                        <h6 className="m-0" onClick={onClickBackNewDamages}>
                                            <a className="text-white" style={{textDecoration:"none"}}>
                                            <i className="fa fa-arrow-left"></i> &nbsp; New Damages</a>
                                        </h6>
                                    </div>
                                    <div className="col-7 text-right">
                                        <button data-toggle="modal" data-target=".bs-example-modal-center-offer-details"
                                                type="submit" className="btn-sm btn-warning waves-effect waves-light d-inline-block">
                                            {CommonUtil.SELECT_RESERVATION_STATUS}
                                        </button>
                                        <h6 className="mb-0 ml-2 d-inline-block">{CommonUtil.SELECT_RESERVATION_CODE}</h6>
                                    </div>
                                </div>
                            </div>
                                <div className="row m-0">
                                    <div className="col-12" style={{textAlign:"center"}}>
                                        <video id="dmgoutput" autoPlay={true} muted={true} playsInline={true} className={"camera-class"} ref={videodmgRef} style={{width:picWidth,height:picHeight,objectFit:"cover"}} >Video stream not available.</video>
                                        <canvas id="dmgoutput1" ref={canvasdmgRef} style={{display:"none"}}/>
                                        <img id="dgmoutput2" ref={imgdmgRef} className={"camera-class"} style={{display:"none",width:picWidth,height:picHeight}}/>
                                    </div>

                                    <div className="col-12 flex-spacebetween">
                                        <div className="col-4 text-right retake-photo" id="divamageGallery">
                                            <label className="btn-sm btn-success take-camata-class text-center mb-0" title={"Gallery"} onClick={onClickUploadDmgPhoto}>
                                                <i className="fas fa-camera"></i>
                                            </label>
                                            <span>Gallery</span>
                                        </div>
                                        <div className="col-4 text-right retake-photo" id="divDamageNewImages">
                                            <label className="btn-sm btn-success take-camata-class text-center mb-0" title={"Take Photo"} onClick={onClickDmgTakePhoto}>
                                                <i className="fas fa-camera"></i>
                                            </label>
                                            <span>Take Photo</span>
                                        </div>
                                        <div className="col-1 text-right"></div>
                                        <div className="col-3 text-center" style={{paddingRight:"0px"}}>
                                            <label className="btn-sm retake-image text-center mb-0" title={"Retake Photo"} onClick={onClickDmgReTakePhoto}>
                                                <i className="fas fa-camera"></i>
                                            </label>
                                            <span>Retake</span>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Description</label>
                                            <div className="col-sm-12">
                                                <textarea  ref={descRef} className="form-control" rows={5} placeholder="Enter your Description here"
                                                           value={desc} onChange={(e) => {setDesc(e.currentTarget.value);CommonUtil.isMandatory(descRef)}}>
                                                </textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p>&nbsp;</p>
                                <div className="row fixed">
                                    <div className="col-12 text-center">
                                        <button type="button" className="btn btn-primary waves-effect button-height mobile-button-height" data-dismiss="modal" onClick={onClickAddDamages}>Add
                                        </button>
                                    </div>
                                </div>


                        </div>
                    </div>
                </div>
                <div id="pnlPOD" style={{display:"none"}} className="content-page">
                    <div className="content m-0">
                        <div className="page-content-wrapper">
                            <div className="resa-vation bg-dark text-white p-2 mb-1">
                                <div className="row m-0">
                                    <div className="col-8 pr-0">
                                        <h6 className="m-0" onClick={onClickBackPOD} style={{paddingTop:"5px"}}>
                                            <a  className="text-white" style={{textDecoration:"none"}}>
                                                <i className="fa fa-arrow-left"></i> &nbsp; Proof of Delivery</a></h6>
                                    </div>
                                    <div className="col-4 text-right">
                                        <button data-toggle="modal" type="submit"
                                                className="btn-sm btn-warning waves-effect waves-light d-inline-block">
                                            {CommonUtil.SELECT_RESERVATION_STATUS}
                                        </button>
                                        <h6 className="mb-0 ml-1 d-inline-block">{CommonUtil.SELECT_RESERVATION_CODE}</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="row m-0">
                                <div className="col-12 pt-2" style={{fontWeight:"bold"}}>
                                    <p className="mb-2 text-muted"><strong><i>This is an optional step:</i></strong>
                                        <i>  Please take a picture of the Proof of Delivery.</i></p>
                                </div>
                                <div className="row text-center">
                                    <div className="col-12" style={{textAlign:"center"}}>
                                        <video id="podoutput" autoPlay={true} muted={true} playsInline={true} className={"camera-class"} ref={videoPODRef} style={{width:picWidth,height:picHeight,objectFit:"cover"}}>Video stream not available.</video>
                                        <canvas id="podooutput1" ref={canvasPODRef} style={{display:"none"}}/>
                                        <img id="podooutput2" ref={imgPODRef} className={"camera-class"} style={{display:"none",width:picWidth,height:picHeight}}/>
                                    </div>

                                </div>
                                <div className="col-12 flex-spacebetween">
                                    <div className="col-4 text-right retake-photo" id="divamageGallery">
                                        <label className="btn-sm btn-success take-camata-class text-center mb-0" title={"Gallery"} onClick={onClickUploadPODPhoto}>
                                            <i className="fas fa-camera"></i>
                                        </label>
                                        <span>Gallery</span>
                                    </div>
                                    <div className="col-4 text-right retake-photo" id="divPODmeter">
                                        <label className="btn-sm btn-success take-camata-class text-center mb-0" title={"Take Photo"} onClick={onClickPODStartCamera}>
                                            <i className="fas fa-camera"></i>
                                        </label>
                                        <span>Take Photo</span>
                                    </div>
                                    <div className="col-1 text-right"></div>
                                    <div className="col-3 text-center" style={{paddingRight:"0px"}}>
                                        <label className="btn-sm retake-image text-center mb-0" title={"Retake Photo"} onClick={onClickPODTakePhoto}>
                                            <i className="fas fa-camera"></i>
                                        </label>
                                        <span>Retake</span>
                                    </div>
                                </div>
                            </div>

                            {/*<div className="row m-0">
                                <div className="col-12 mt-3">
                                    <div className="form-group row" style={{display:"flex",justifyContent:"center"}}>
                                        <label className="col-sm-12 col-form-label text-center">Enter the Hubodometer value:</label>
                                        <div className="col-sm-12" style={{display:"flex",justifyContent:"center"}}>
                                            <input type="number" ref={hodometerRef} className="form-control text-center licese-class"
                                                   value={hodometer} maxLength={6}
                                                   onChange={(e) => {setHodometer(e.currentTarget.value);CommonUtil.isMandatory(hodometerRef)}} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 units">
                                    <label className="col-form-label d-inline-block">Unit of Measure:</label>
                                    <ul className="nav nav-tabs d-inline-block border-0 ml-2" role="tablist">
                                        <li className="nav-item d-inline-block ">
                                            <a id={"lnkKM"} className="nav-link  border" data-toggle="tab"  role="tab"
                                               data-placement="top" title="Grid View" aria-selected="true" onClick={onClickKM}>Kilometers</a>
                                        </li>
                                        <li className="nav-item d-inline-block ">
                                            <a id={"lnkMiles"} className="nav-link border active" data-toggle="tab"
                                               role="tab" data-placement="top" title="Tile View" aria-selected="false" onClick={onClickMiles}>Miles</a>
                                        </li>
                                    </ul>
                                    <div className="row">
                                        <div className="col-6"></div>
                                        <div className="col-6"></div>
                                    </div>
                                </div>
                            </div>*/}

                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                            <br />


                            <div className="row fixed">
                                <div className="col-12 text-center">
                                    <button type="button" className="btn btn-primary waves-effect mobile-button-height" data-dismiss="modal"
                                            onClick={onClickNextPODmeter}>Next
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="pnlHodometer" style={{display:"none"}} className="content-page">
                    <div className="content m-0">
                        <div className="page-content-wrapper">
                            <div className="resa-vation bg-dark text-white p-2 mb-1">
                                <div className="row m-0">
                                    <div className="col-8 pr-0">
                                        <h6 className="m-0" onClick={onClickBackHodometer} style={{paddingTop:"5px"}}>
                                            <a  className="text-white" style={{textDecoration:"none"}}>
                                                <i className="fa fa-arrow-left"></i> &nbsp; Hubodometer</a></h6>
                                    </div>
                                    <div className="col-4 text-right">
                                        <button data-toggle="modal" type="submit"
                                                className="btn-sm btn-warning waves-effect waves-light d-inline-block">
                                            {CommonUtil.SELECT_RESERVATION_STATUS}
                                        </button>
                                        <h6 className="mb-0 ml-1 d-inline-block">{CommonUtil.SELECT_RESERVATION_CODE}</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="row m-0">
                                <div className="col-12 pt-2" style={{fontWeight:"bold"}}>
                                    <p className="mb-2 text-muted"><strong><i>This is an optional step:</i></strong>
                                    <i>  Please take a picture of the Hubodometer and enter the mileage below.</i></p>
                                </div>
                                <div className="row text-center">
                                    <div className="col-12" style={{textAlign:"center"}}>
                                        <video id="hodooutput" autoPlay={true} muted={true} playsInline={true} className={"camera-class"} ref={videoHodoRef} style={{width:picWidth,height:picHeight,objectFit:"cover"}}>Video stream not available.</video>
                                        <canvas id="hodooutput1" ref={canvasHodoRef} style={{display:"none"}}/>
                                        <img id="hodooutput2" ref={imgHodoRef} className={"camera-class"} style={{display:"none",width:picWidth,height:picHeight}}/>
                                    </div>

                                </div>
                                <div className="col-12 flex-spacebetween">
                                    <div className="col-4 text-right retake-photo" id="divHodometerGallary">
                                        <label className="btn-sm btn-success take-camata-class text-center mb-0" title={"Gallery"} onClick={onClickUploadHodometer}>
                                            <i className="fas fa-camera"></i>
                                        </label>
                                        <span>Gallery</span>
                                    </div>
                                    <div className="col-4 text-right retake-photo" id="divHodometer">
                                        <label className="btn-sm btn-success take-camata-class text-center mb-0" title={"Take Photo"} onClick={onClickHodoStartCamera}>
                                            <i className="fas fa-camera"></i>
                                        </label>
                                        <span>Take Photo</span>
                                    </div>
                                    <div className="col-1 text-right"></div>
                                    <div className="col-3 text-center" style={{paddingRight:"0px"}}>
                                        <label className="btn-sm retake-image text-center mb-0" title={"Retake Photo"} onClick={onClickHodoTakePhoto}>
                                            <i className="fas fa-camera"></i>
                                        </label>
                                        <span>Retake</span>
                                    </div>
                                </div>
                            </div>

                            <div className="row m-0">
                                <div className="col-12 mt-3">
                                    <div className="form-group row" style={{display:"flex",justifyContent:"center"}}>
                                        <label className="col-sm-12 col-form-label text-center">Enter the Hubodometer value:</label>
                                        <div className="col-sm-12" style={{display:"flex",justifyContent:"center"}}>
                                            <input type="number" ref={hodometerRef} className="form-control text-center licese-class"
                                                    value={hodometer} maxLength={6}
                                                   onChange={(e) => {setHodometer(e.currentTarget.value);CommonUtil.isMandatory(hodometerRef)}} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 units">
                                    <label className="col-form-label d-inline-block">Unit of Measure:</label>
                                    <ul className="nav nav-tabs d-inline-block border-0 ml-2" role="tablist">
                                        <li className="nav-item d-inline-block ">
                                            <a id={"lnkKM"} className={CommonUtil.getMesurementByCountry() == "KM"?"nav-link border active":"nav-link border"}  data-toggle="tab"  role="tab"
                                               data-placement="top" title="Grid View" aria-selected="true">Kilometers</a>
                                        </li>
                                        <li className="nav-item d-inline-block ">
                                            <a id={"lnkMiles"} className={CommonUtil.getMesurementByCountry() != "KM"?"nav-link border active":"nav-link border"} data-toggle="tab"
                                               role="tab" data-placement="top" title="Tile View" aria-selected="false">Miles</a>
                                        </li>
                                    </ul>
                                    <div className="row">
                                        <div className="col-6"></div>
                                        <div className="col-6"></div>
                                    </div>
                                </div>
                            </div>

                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                            <br />


                            <div className="row fixed">
                                <div className="col-12 text-center">
                                    <button type="button" className="btn btn-primary waves-effect mobile-button-height" data-dismiss="modal"
                                            onClick={onClickNextHodometer}>Next
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="pnlConfirmation" style={{display:"none"}} className="content-page">
                    <div className="content m-0">
                        <div className="page-content-wrapper">
                            <div className="resa-vation bg-dark text-white p-2 mb-1">
                                <div className="row m-0">
                                    <div className="col-6 pr-0">
                                        <h6 className="m-0" onClick={onClickBackConfirmation} style={{paddingTop:"5px"}} >
                                            <a className="text-white" style={{textDecoration:"none"}}>
                                                <i className="fa fa-arrow-left"></i> &nbsp; Confirmation
                                            </a>
                                        </h6>
                                    </div>
                                    <div className="col-6 text-right pl-0">
                                        <button data-toggle="modal" type="submit" className="btn-sm btn-warning waves-effect waves-light d-inline-block">
                                            {CommonUtil.SELECT_RESERVATION_STATUS}
                                        </button>
                                        <h6 className="mb-0 ml-1 d-inline-block">{CommonUtil.SELECT_RESERVATION_CODE}</h6>
                                    </div>
                                </div>
                            </div>
                            <section style={{paddingTop:"10%"}}>
                                <div className="row mt-5">
                                    <div className="col-12" id={"pnlCheckboxes"} style={{display:"none"}}>
                                        <div id="check-box">
                                            <label style={{color:"red"}}>Please select the checkboxes</label>
                                        </div>
                                    </div>
                                    <div  className="col-12" style={{display:(((CommonUtil.RESERVATION_CATEGORY == CommonUtil.RESERVATION_ACTIVATION && activeInstruction)||(CommonUtil.RESERVATION_CATEGORY == CommonUtil.RESERVATION_IN_ACTIVATION && deActiveInstruction))?"":"none")}}>
                                        <div style={{display:"inline"}}>
                                            <input ref={chkLocal} type="checkbox" className="checkBox" style={{float:"left"}}/>
                                            <div style={{paddingLeft:"10px",height:"100px",overflow:"auto"}}>
                                                <label>{CommonUtil.RESERVATION_CATEGORY == CommonUtil.RESERVATION_ACTIVATION?activeInstruction:deActiveInstruction}</label>
                                            </div>

                                        </div>
                                        {/* <div id="check-box">
                                            <label className="checkbox-inline checkbox-bootstrap checkbox-lg pl-3 flex">
                                                <input  ref={chkLocal} type="checkbox" className="checkBox" /> &nbsp;&nbsp;
                                                <span className="checkbox-placeholder" style={{height:"100px",overflow:"auto"}}>{CommonUtil.RESERVATION_CATEGORY == CommonUtil.RESERVATION_ACTIVATION?activeInstruction:deActiveInstruction}</span></label>
                                        </div>*/}
                                    </div>
                                    <div className="col-12" style={{paddingTop:"15px"}}>
                                        <div id="check-box">
                                            <label className="checkbox-inline checkbox-bootstrap checkbox-lg pl-3 flex">
                                                <input ref={chkConfirm} type="checkbox" className="checkBox" style={{width:"40px"}}  />&nbsp;&nbsp;
                                                    <span className="checkbox-placeholder"></span>
                                                    I confirm that I thoroughly inspected the van and that all Damages have been identified
                                                </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-4" style={{paddingBottom:"50%",paddingLeft:"10%"}}>
                                    <div className="col-12 pl-5"><h6>How satisfied are you with the condition of the trailer</h6></div>
                                    <div className="col-12 pl-4">
                                        <div className="star-rating">
                                            <span id="spnStar1" className="fa-regular fa-star" data-rating="1" style={{color:"orange"}} onClick={()=>{onClickStar(1)}}></span>
                                            &nbsp;
                                            <span id="spnStar2" className="fa-regular fa-star" data-rating="2" style={{color:"orange"}} onClick={()=>{onClickStar(2)}}></span>
                                            &nbsp;
                                            <span id="spnStar3" className="fa-regular fa-star" data-rating="3" style={{color:"orange"}} onClick={()=>{onClickStar(3)}}></span>
                                            &nbsp;
                                            <span id="spnStar4" className="fa-regular fa-star" data-rating="4" style={{color:"orange"}} onClick={()=>{onClickStar(4)}}></span>
                                            &nbsp;
                                            <span id="spnStar5" className="fa-regular fa-star" data-rating="5" style={{color:"orange"}} onClick={()=>{onClickStar(5)}}></span>
                                            <input type="hidden" name="whatever1" className="rating-value" value="5" />
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <p>&nbsp;</p>
                            <div className="row fixed">
                                <div className="col-12 text-center">
                                    <button type="button" className="btn btn-primary waves-effect mobile-button-height" style={{width:"150px"}} data-dismiss="modal"
                                    onClick={onClickEndMyRental}>{CommonUtil.RESERVATION_CATEGORY == CommonUtil.RESERVATION_ACTIVATION?"Start My "+CommonUtil.SELECT_EQUIP_AVAIL_TYPE:"End My "+CommonUtil.SELECT_EQUIP_AVAIL_TYPE} </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id={"pnlComplete"} style={{display:"none"}} className="content-page">
                    <div className="page-content-wrapper">
                        <div className="resa-vation bg-dark text-white p-2 mb-1">
                            <div className="row m-0">
                                <div className="col-6 pr-0">
                                    <h6 className="m-0" style={{paddingTop:"5px"}}>
                                        <a className="text-white" style={{textDecoration:"none",pointerEvents:"none"}}>
                                            {/* <i className="fa fa-arrow-left"></i>  */}
                                            {CommonUtil.SELECT_RESERVATION_STATUS == CommonUtil.RESERVATION_UPCOMING || CommonUtil.SELECT_RESERVATION_STATUS == CommonUtil.RESERVATION_ACTIVATION_DUE?"Activation":"In Activation"} </a>
                                    </h6>
                                </div>
                                <div className="col-6 text-right pl-0">
                                    {/*<button data-toggle="modal" type="submit"
                                            className="btn-sm btn-warning waves-effect waves-light d-inline-block">
                                        {CommonUtil.SELECT_RESERVATION_STATUS}
                                    </button>*/}
                                    <h6 className="mb-0 ml-1 d-inline-block">{CommonUtil.SELECT_RESERVATION_CODE}</h6>
                                </div>
                            </div>
                        </div>
                        <div className="row m-0">
                            <div className="col-12 text-center">
                                <div className="row m-0  bg-gray p-5 ">
                                    <div className="col-12 text-center mb-3">
                                        <div className="icon1">
                                            <i className="fa fa-check"></i>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        {(CommonUtil.SELECT_RESERVATION_STATUS == CommonUtil.RESERVATION_UPCOMING || CommonUtil.SELECT_RESERVATION_STATUS == CommonUtil.RESERVATION_ACTIVATION_DUE)?<h6>Your reservation has been activated!</h6>
                                        :<h6>Your reservation has been Deactivated!</h6>}
                                    </div>
                                </div>
                            </div>
                            <div className="col-2"></div>
                            <div className="col-8 text-center mb-3 mt-4">
                                <button type="button" className="btn btn-primary waves-effect  w-100 mobile-button-height" data-dismiss="modal"  onClick={onClickRetunrHome}>
                                    Return to Home Screen
                                </button>
                            </div>
                            <div className="col-2"></div>
                            <div className="col-2"></div>
                            <div className="col-8 text-center">
                                <button type="button" className="btn btn-primary waves-effect  w-100 mobile-button-height" data-dismiss="modal"  onClick={onClickRervationDetails}>
                                    Reservation Details
                                </button>
                            </div>
                            <div className="col-2"></div>
                        </div>
                    </div>
                </div>
                <div id="pnlAddInspection" style={{display:"none"}} className="content-page">
                    <div className="content m-0">
                        <div className="page-content-wrapper ">
                            <div className="resa-vation bg-dark text-white p-2 mb-1">
                                <div className="row m-0">
                                    <div className="col-5 pr-0">
                                        <h6 className="m-0" onClick={onClickBackDriversideExterior}>
                                            <a className="text-white" style={{textDecoration:"none"}}>
                                            <i className="fa fa-arrow-left"></i> &nbsp; {inspectionScreenTitle}</a></h6>
                                    </div>
                                    <div className="col-7 text-right">
                                        <button data-toggle="modal" data-target=".bs-example-modal-center-offer-details"
                                                type="submit" className="btn-sm btn-warning waves-effect waves-light d-inline-block">
                                            {CommonUtil.SELECT_RESERVATION_STATUS}
                                        </button>
                                        <h6 className="mb-0 ml-2 d-inline-block">{CommonUtil.SELECT_RESERVATION_CODE}</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="row m-0">
                                <div className="col-12" style={{textAlign:"center"}}>
                                    <video id="driversideoutput" autoPlay={true} muted={true} playsInline={true} className={"camera-class"} ref={videoDSideRef} style={{width:picWidth,height:picHeight,objectFit:"cover"}}>Video stream not available.</video>
                                    <canvas id="driversideoutput1" ref={canvasDSideRef} style={{display:"none"}} />
                                    <img id="driversideoutput2" ref={imgDSideRef} className={"camera-class"} style={{display:"none",width:picWidth,height:picHeight}} />
                                </div>

                                <div className="col-12 flex-spacebetween">
                                    <div className="col-4 text-right retake-photo" id="divDSide">
                                        <label className="btn-sm btn-success take-camata-class text-center mb-0" title={"Gallery"} onClick={onClickDSideUploadPhoto}>
                                            <i className="fas fa-camera"></i>
                                        </label>
                                        <span>Gallery</span>
                                    </div>
                                    <div className="col-4 text-right retake-photo" id="divDSide">
                                        <label className="btn-sm btn-success take-camata-class text-center mb-0" title={"Take Photo"} onClick={onClickDSideTakePhoto}>
                                            <i className="fas fa-camera"></i>
                                        </label>
                                        <span>Take Photo</span>
                                    </div>
                                    <div className="col-1 text-right"></div>
                                    <div className="col-3 text-center" style={{paddingRight:"0px"}}>
                                        <label className="btn-sm retake-image text-center mb-0" title={"Retake Photo"} onClick={onClickDSideReTakePhoto}>
                                            <i className="fas fa-camera"></i>
                                        </label>
                                        <span>Retake</span>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div style={{display: 'flex',overflow:"auto"}}>
                                        {equipDriverSidePath.map((item2: any, idx: number) => {
                                            if (item2 && item2.Type == inspectionImageFormat) {
                                                var list2 = (
                                                    <div className={'upload-image-item'} key={idx}>
                                                        <a style={{color: 'blue', paddingTop: '5px',zIndex:99}}>
                                                            <i className=" remove-btn fa fa-remove upload-thumbnail-remove"
                                                               onClick={() => {onClickRemoveImage(item2.attachementId)}} ></i>
                                                        </a>
                                                        <img src={item2.Path} className="mobile-thumbnail" style={{marginTop:"-25px"}}></img>

                                                    </div>
                                                )
                                                return list2
                                            }
                                        })}

                                    </div>
                                </div>
                            </div>
                            <p>&nbsp;</p>
                            <div className="row fixed">
                                <div className="col-12 text-center">
                                    <button type="button" className="btn btn-primary waves-effect mobile-button-height" data-dismiss="modal" onClick={onClickBackDriversideExterior}>Add
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="pnlReefer" style={{display:"none"}} className="content-page">
                    <div className="content m-0">
                        <div className="page-content-wrapper">
                            <div className="resa-vation bg-dark text-white p-2 mb-1">
                                <div className="row m-0">
                                    <div className="col-8 pr-0">
                                        <h6 className="m-0" onClick={onClickBackEngineHours} style={{paddingTop:"5px"}}>
                                            <a  className="text-white" style={{textDecoration:"none"}}>
                                                <i className="fa fa-arrow-left"></i> &nbsp; Engine Hours</a></h6>
                                    </div>
                                    <div className="col-4 text-right">
                                        <button data-toggle="modal" type="submit"
                                                className="btn-sm btn-warning waves-effect waves-light d-inline-block">
                                            {CommonUtil.SELECT_RESERVATION_STATUS}
                                        </button>
                                        <h6 className="mb-0 ml-1 d-inline-block">{CommonUtil.SELECT_RESERVATION_CODE}</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="row m-0">
                                <div className="col-12 pt-2" style={{fontWeight:"bold",display:"none"}}>
                                    <p className="mb-2 text-muted"><strong><i>This is an mandatory step:</i></strong>
                                        <i>  Please take a picture of the Engine Hours</i></p>
                                </div>
                                <div className="row text-center">
                                    <div className="col-12" style={{textAlign:"center"}}>
                                        <video id="engineoutput" autoPlay={true} muted={true} playsInline={true} className={"camera-class"} ref={videoEngineRef} style={{width:picWidth,height:picHeight,objectFit:"cover"}}>Video stream not available.</video>
                                        <canvas id="engineoutput1" ref={canvasEngineRef} style={{display:"none"}}/>
                                        <img id="engineoutput2" ref={imgEngineRef} className={"camera-class"} style={{display:"none",width:picWidth,height:picHeight}}/>
                                    </div>

                                </div>
                                <div className="col-12 flex-spacebetween">
                                    <div className="col-4 text-right retake-photo" id="divEngineHour">
                                        <label className="btn-sm btn-success take-camata-class text-center mb-0" title={"Gallery"} onClick={onClickEngineUploadPhoto}>
                                            <i className="fas fa-camera"></i>
                                        </label>
                                        <span>Gallery</span>
                                    </div>
                                    <div className="col-4 text-right retake-photo" id="divEngineHour">
                                        <label className="btn-sm btn-success take-camata-class text-center mb-0" title={"Take Photo"} onClick={onClickEngineStartCamera}>
                                            <i className="fas fa-camera"></i>
                                        </label>
                                        <span>Take Photo</span>
                                    </div>
                                    <div className="col-1 text-right"></div>
                                    <div className="col-3 text-center" style={{paddingRight:"0px"}}>
                                        <label className="btn-sm retake-image text-center mb-0" title={"Retake Photo"} onClick={onClickEngineTakePhoto}>
                                            <i className="fas fa-camera"></i>
                                        </label>
                                        <span>Retake</span>
                                    </div>
                                </div>
                            </div>

                            <div className="row m-0">
                                <div className="col-12 mt-3">
                                    <div className="form-group row" style={{display:"flex",justifyContent:"center"}}>
                                        <label className="col-sm-12 col-form-label text-center">Enter the Engine Hours value:</label>
                                        <div className="col-sm-12" style={{display:"flex",justifyContent:"center"}}>
                                            <input type="number" ref={engineHoursRef} className="form-control text-center licese-class"
                                                   value={enginehours} maxLength={6}
                                                   onChange={(e) => {setEngineHours(e.currentTarget.value);CommonUtil.isMandatory(engineHoursRef)}} />
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                            <br />


                            <div className="row fixed">
                                <div className="col-12 text-center">
                                    <button type="button" className="btn btn-primary waves-effect mobile-button-height" data-dismiss="modal"
                                            onClick={onClickNextEngineHours}>Next
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ReservationActivationLocation;
