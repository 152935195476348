import React, {useEffect, useRef, useState} from "react";
import AGGrid from "../../Components/AGGrid";
import orgUserRenderer from "../Organization/OrgUserRenderer";
import {IAGrid} from "../../Components/IInterfaces";
import useStateWithCallback from "use-state-with-callback";
import {CommonUtil} from "../../utils/CommonUtil";
import {NetworkManager} from "../../Components/NetworkManager";
import ToastManager from "../../Components/ToastManager";
import {StatusCodeUtil} from "../../utils/StatusCodeUtil";
import OrgUserFieldRenderer from "../Organization/OrgUserFieldRenderer";
import {Constants} from "../Languages/Constants";
import DetectDevice from "../../utils/DetectDevice";
import MGrid from "../../Components/MGrid";
import {Events} from "../Languages/Events";
import { Modal } from "react-bootstrap";
import $ from 'jquery'
import DraggableComponent from "../../Components/DraggableComponent";

const DNUDetails = ()=>{
    const [weeklyRate, setWeeklyRate] = useState<any>("");
    const [monthlyRate,setMonthlyRate]= useState<any>("");
    const [weekly, setWeekRate] = useState<any>("");
    const [monthly,setMonthRate]= useState<any>("");
    const [daily, setDailyRate] = useState<any>("");
    const [yearly, setYearlyRate] = useState<any>("");
    const [distance,setDistanceRate]= useState<any>("");
    const [engine, setEngineRate] = useState<any>("");
    const [modelYear,setModelYear]= useState<any>("");
    const [equipment,setEquipment]= useState<any>([""]);
    const [isDetailsPopup,setIsDetailsPopup]= useState(false);

    const priceGridRef = useRef<IAGrid>(null);

    const equipmentDetailsPopup = (params:any) => {
        let onClickAdd = ()=>{
            setIsDetailsPopup(true);
            setEquipment(params.data);
            populateEquipmentData(params.data);
        }
        let onClickModify = ()=>{
            setIsDetailsPopup(true);
            setEquipment(params.data);
            populateEquipmentData(params.data);
        }

        return(
            <div className={"cellRenderHeader d-flex mx-2 my-1"}>
                {!params.data.isChild ? (
                    <a
                    className="btn btn-default login-btn"
                    title={`Add New ${params.data.equipmentType}`}
                    style={{
                        background: "rgb(239, 195, 0)",
                        width: "40px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                    onClick={onClickAdd}
                    >
                    <span>
                        <i className="fas fa-plus fa-sm "></i>
                    </span>
                    </a>
                ) : (
                    <a onClick={onClickModify}>
                    <span className={"cellRender"}>Edit</span>
                    </a>
                )}
            </div>
        )
    }

    let priceColumns: any = [];
        priceColumns =[
        { field: "equipmentType", headerName: CommonUtil.getMessageText(Constants.EQUIP_TYPE,"Equipment Type"),resizable:true,flex:1,width:100,sortable:true,filter:'agTextColumnFilter'},
        { field: "modelYear", headerName: "Model Year", resizable: true, flex: 1, width: 100,},
        { field: "dailyRate", headerName: CommonUtil.getMessageText(Constants.DAILY_RATE, "Daily Rate"), resizable: true, flex: 1, width: 100, sortable: true, filter: 'agTextColumnFilter', cellRenderer: OrgUserFieldRenderer },
        { field: "weeklyRate", headerName: CommonUtil.getMessageText(Constants.WEEKLY_RATE, "Weekly Rate"), resizable: true, flex: 1, width: 100, sortable: true, filter: 'agTextColumnFilter', cellRenderer: OrgUserFieldRenderer },
        { field: "monthlyRate", headerName: CommonUtil.getMessageText(Constants.MONTHLY_RATE,"Monthly Rate"),resizable:true,flex:1,width:100,sortable:true,filter:'agTextColumnFilter',cellRenderer: OrgUserFieldRenderer  },
        { field: "yearlyRate", headerName: "Annual Monthly Rate",resizable:true,flex:1,width:100,sortable:true,filter:'agTextColumnFilter' ,cellRenderer: OrgUserFieldRenderer  },
        { field: "distanceRate", headerName: CommonUtil.getMessageText(Constants.DIST_RATE,"Distance Rate"),resizable:true,flex:1,width:100,sortable:true,filter:'agTextColumnFilter', cellRenderer: OrgUserFieldRenderer },
        { field: "engineRate", headerName: CommonUtil.getMessageText(Constants.ENGINE_RATE,"Engine Rate"),resizable:true,flex:1,width:100,sortable:true,filter:'agTextColumnFilter', cellRenderer: OrgUserFieldRenderer },
    ];
    if(CommonUtil.isRootUser() || CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_OWNER || CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_OWNER_RENTER){
        priceColumns.push(
            { field: "Action", headerName: CommonUtil.getMessageText(Constants.ACTION,"Action"),resizable:true,flex:1,width:80, cellRenderer: equipmentDetailsPopup });
    }

    const [priceData,setPriceData] = useStateWithCallback<any>([],()=>{
        if(priceGridRef.current){
            priceGridRef.current.columnFittoSize();
        }
    });

    const processHierarchyData = (pData:any) => {
        let tempdata: any = [];

        const newroot = pData.map((ele: any) => {
            tempdata.push({...ele});
          if (ele?.pricingTrendsByYearDtoList?.length > 0) {
            ele?.pricingTrendsByYearDtoList.map((item: any) => {
              tempdata.push({
                id: item.id,
                equipmentType:ele.equipmentType,
                modelYear: item.modelYear,
                distanceRate: item.distanceRate,
                engineRate: item.engineRate,
                dailyRate: item.dailyRate,
                weeklyRate: item.weeklyRate,
                monthlyRate: item.monthlyRate,
                yearlyRate: item.yearlyRate,
                orgEquipmentPriceId: item.orgEquipmentPriceId,
                isChild:true
              });
            });
          }
    
          return { ...ele};
        });
        setPriceData(tempdata);
      };

    const [conditions,setConditions] = useState("");
    const [equipments,setEquipments] = useState("");
    const { isMobile } = DetectDevice();
    var priceArr:any[] = [
        { equipmentType: "Dry Van", dailyRate: "CAD $20", distanceRate: "CAD $0.05", engineRate: "CAD $1.50"},
        { equipmentType: "Plate Dry Van", dailyRate: "CAD $20", distanceRate: "CAD $0.05", engineRate: "CAD $1.50"},
        { equipmentType: "Storage Van", dailyRate: "CAD $20", distanceRate: "CAD $0.05", engineRate: "CAD $1.50"},
        { equipmentType: "Reefer", dailyRate: "CAD $20", distanceRate: "CAD $0.05", engineRate: "CAD $1.50"},
        { equipmentType: "Heated Van", dailyRate: "CAD $20", distanceRate: "CAD $0.05", engineRate: "CAD $1.50"},
        { equipmentType: "Flatbed", dailyRate: "CAD $20", distanceRate: "CAD $0.05", engineRate: "CAD $1.50"}
    ];

    useEffect(()=>{
        getOrgDefaultPrices();
        document.addEventListener(Events.SWITCH_CUSTOMER_EVENT, onSwitchCustomer);
    },[]);

    let onSwitchCustomer = ()=>{
        if(CommonUtil.isRootUser()){
            Events.dispatchBreadCrumbEvent();
            //navigate("orgList1");
        }else{
            getOrgDefaultPrices();
        }
        Events.dispatchBreadCrumbEvent();
    }

    let getOrgDefaultPrices = ()=>{
        setPriceData([]);
        var listAPI:string = "api/organization/getEquipmentTypePricing?saasOrgId="+CommonUtil.getSaasOrgId();
        NetworkManager.sendJsonRequest(listAPI,"","","GET",true,onGetEquipmentTypePricing,onLoginError);
    }

    let onGetEquipmentTypePricing = (dataObj:any)=>{
        if(dataObj) {
            var restData = JSON.parse(dataObj);
            if (restData) {
                if (restData.statusCode) {
                    //setErrorMsg(StatusCodeUtil.getCodeMessage(restData.statusCode));
                    ToastManager.showToast(StatusCodeUtil.getCodeMessage(restData.statusCode),"Error");
                } else {
                    var orgs = [];
                    setConditions(restData.dnuConditions);
                    setEquipments(restData.dnuEqpConditions);
                    setWeeklyRate(restData.weeklyRate);
                    setMonthlyRate(restData.monthlyRate);
                    if(Array.isArray(restData.equipmentTypePricings)){
                        orgs = restData.equipmentTypePricings;
                    }else{
                        orgs.push(restData.equipmentTypePricings);
                    }
                    var currency:string = "";
                    if(CommonUtil.getCountry() == CommonUtil.CANADA){
                        //currency = CommonUtil.CANADA_CURRENCY;
                    }else{
                        //currency = CommonUtil.US_CURRENCY;
                    }
                    orgs.forEach((item:any,idx:number)=>{
                        //item.dailyRate = item.dailyRate;
                        //item.distanceRate = currency+" "+item.distanceRate;
                        //item.engineRate = currency+" "+item.engineRate;
                    })
                    setPriceData(orgs);
                    //setBlockOrgs(orgs);
                    processHierarchyData(orgs);
                }
            }
        }
    }
    
    let resetForm = ()=>{
        setModelYear("");
        setDailyRate("");
        setWeekRate("");
        setMonthRate("");
        setDistanceRate("");
        setEngineRate("");
        setYearlyRate("");
    }

    let populateEquipmentData = (data: any) => {
        setDailyRate(data?.dailyRate);
        setWeekRate(data?.weeklyRate);
        setMonthRate(data?.monthlyRate);
        setDistanceRate(data?.distanceRate);
        setYearlyRate(data?.yearlyRate)
        setEngineRate(data?.engineRate);
        setModelYear(data?.modelYear);
    }
    let onModelYearChange = (e:any) => {
        setModelYear(e.target.value);
    }
    let onDailyRateChange = (e:any) => {
        setDailyRate(e.target.value);
    }
    let onWeeklyRateChange = (e:any) => {
        setWeekRate(e.target.value);
    }
    let onMonthlyRateChange = (e:any) => {
        setMonthRate(e.target.value);
    }
    let onYearlyRateChange = (e:any) => {
        setYearlyRate(e.target.value);
    }
    let onDistanceRateChange = (e:any) => {
        setDistanceRate(e.target.value);
    }
    let onEngineRateChange = (e:any) => {
        setEngineRate(e.target.value);
    }

     let handleClosePopUp = ()=>{
        setIsDetailsPopup(false);
    }

    let handleSavePopUp = () => {
        let reqObj: any = {};
        reqObj.yearlyRate = yearly;
        reqObj.modelYear = modelYear;
        reqObj.dailyRate = daily;
        reqObj.weeklyRate = weekly;
        reqObj.monthlyRate = monthly;
        reqObj.distanceRate = distance;
        reqObj.engineRate = engine;
        
        let year = equipment?.pricingTrendsByYearDtoList?.map((item: any) => item.modelYear);
        let newYear = Number(reqObj.modelYear);
    
        if (isNaN(newYear)) {
            ToastManager.showToast("Invalid model year", "Error");
            return;
        }
    
        if (!equipment?.isChild && year?.includes(newYear)) {
            ToastManager.showToast("Year is already entered", "Error");
            return;
        }
    
        resetForm();
        var userUrl = `api/organization/savePricingTrendsByYear/${CommonUtil.getSaasOrgId()}?orgEquipmentPriceId=${equipment?.orgEquipmentPriceId}`;
        var method = "POST";
        NetworkManager.sendJsonRequest(userUrl, reqObj, '', method, true, onSaveEquipmentDetails, onLoginError);
    }
    
    let onSaveEquipmentDetails = (data:any)=>{
        if (data) {
          var restData = CommonUtil.parseData(data);
          if (restData) {
              let strSuccess = CommonUtil.getMessageText(Constants.UPDATED_SUCCESSFULLY,"updated Successfully");
              ToastManager.showToast(strSuccess, "Info");
              setIsDetailsPopup(false);
                getOrgDefaultPrices();
          }else{
            ToastManager.showToast("Error","Please select Organization");
          }
        }
      }

    let onClickSavePrice = ()=>{
        setTimeout(()=>{
            saveDailyPrices();
        })
    }

    let saveDailyPrices = ()=>{
        var prices:any[] = [];
        var gridAPI:any = priceGridRef.current?.getGridAPI();
        if(gridAPI){
            //console.log(priceGridRef.current?.getGridAPI());
            var rows:any [] =  gridAPI.rowModel.rowsToDisplay;
            rows.forEach((item,idx)=>{
                //console.log(item.data);
                let data:any = {...item.data};
                if(!data.modelYear){
                    delete data.pricingTrendsByYearDtoList;
                    prices.push(data);
                }
            })
        }

        var reqObj:any = {};
        reqObj["dnuConditions"] = conditions;
        reqObj["dnuEqpConditions"] = equipments;
        reqObj["weeklyRate"] = weeklyRate;
        reqObj["monthlyRate"] = monthlyRate;
        reqObj.equipmentTypePricings = prices;
       

        var listAPI:string = "api/organization/saveEquipmentTypePricing?saasOrgId="+CommonUtil.getSelectedOrgId();
        NetworkManager.sendJsonRequest(listAPI,reqObj,"","POST",true,onSaveEquipmentTypePricing,onLoginError);
    }

    let onSaveEquipmentTypePricing = (dataObj:any)=>{
        if(dataObj) {
            var restData = JSON.parse(dataObj);
            if (restData) {
                if (restData.statusCode) {
                    //setErrorMsg(StatusCodeUtil.getCodeMessage(restData.statusCode));
                    ToastManager.showToast(StatusCodeUtil.getCodeMessage(restData.statusCode),"Error");
                } else {
                    ToastManager.showToast(CommonUtil.getMessageText(Constants.UPDATED_SUCCESSFULLY,"Updated Successfully"),"Info");
                }
            }
        }
    }

    let onClickUserDetails = ()=>{

    }
    let onClickEditPrice = ()=>{

    }
    let onLoginError = (err:any)=>{
        console.log(err);
    }

    let onDevAvailShowPopup = () => {
        setTimeout(function () {
            setPopupCenter('devAvailPopup', 'devAvailContentPopup')
        }, 100)
    }

    let setPopupCenter = (popId: string, cId: string) => {
        let windowHeight = window.innerHeight
        let windowWidth = window.innerWidth
        //windowHeight = windowHeight - 170

        if (cId) {
            let popupTop = windowHeight - 650;
            popupTop = popupTop / 2
            let popupLeft = windowWidth - 600;
            popupLeft = popupLeft / 2
            //if(popupTop<50){
            //popupTop = 10
            //}
            $('#' + popId).css({ margin: '10px', top: popupTop, left: popupLeft })
        }
    }

    return(
        <>
            <div className="container-fluid container-fluid-padding" style={{background:"#f9f9f9"}}>

                <div  className="row  pt-3">
                    <div className="col-md-10">
                        <label style={{display:"none"}}>Default Pricing</label>
                        <label style={{display:"none"}}> All the pricing is in Canadian Dollar </label>
                    </div>
                    {
                        isMobile ? (
                        <MGrid id="CustDnuPriceGrid"  cols={priceColumns} rows={priceData} pageSize={10}
                                headerHeight={CommonUtil.GRID_HEADER_HEIGHT} rowHeight={CommonUtil.GRID_ROW_HEIGHT} ref={priceGridRef} method={{onClickUserDetails}}  />
                        ):(
                         <div className="ag-theme-alpine gridHeight  pt-2 mb-15"
                         style={{ height: "calc(100vh - 250px)", width: "100%",paddingRight:"5px",marginBottom:"20px" }}>
                        <AGGrid id="CustDnuPriceGrid"  cols={priceColumns} rows={priceData} pageSize={500}
                                headerHeight={CommonUtil.GRID_HEADER_HEIGHT} rowHeight={CommonUtil.GRID_ROW_HEIGHT} ref={priceGridRef} method={{onClickUserDetails}}  />
                    </div>
                        )
                    }
                </div>
                <div className="row">
                    <div className="">
                        <div className="my-3 float-end">
                            <button style={{display:"none"}} className="btn btn-outline-dark">Discard Changes</button> &nbsp;&nbsp;
                            {CommonUtil.getSelectedOrgName() == "vHub Inc" ?
                            <button type="button" className="btn btn-success" onClick={onClickSavePrice}>{CommonUtil.getMessageText(Constants.SAVE,"Save")}</button> : ""
                            }

                        </div>
                    </div>
                </div>

            </div>
            <Modal show={isDetailsPopup} centered dialogAs={DraggableComponent} id="devAvailPopup" onShow={onDevAvailShowPopup}>
                <div className="modal-content" id="devAvailContentPopup" style={{ width: "650px", height: "500px" }}>
                <div className="modal-header">
                        <h6 className="modal-title mt-0 fw-bold text-center black-text">{equipment?.isChild ? `EDIT` : `ADD - ${equipment?.equipmentType}`}</h6>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                onClick={handleClosePopUp} style={{marginLeft: "0px"}}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                </div>
                <div className="modal-body" style={{overflowY:"auto"}}>
                <div className="row">
                    <div className="col-md-6 col-6">
                        <div className="form-group row">
                            <label className="col-sm-12 col-form-label">
                            Model Year<span className="text-danger"></span>
                            </label>
                            <div className="col-sm-12">
                                <input type="text"  className="form-control clsFormInputControl" disabled={equipment?.isChild}
                                    placeholder={"Model Year"} value={modelYear} onChange={onModelYearChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 col-6">
                        <div className="form-group row">
                            <label className="col-sm-12 col-form-label">
                                {CommonUtil.getMessageText(Constants.DAILY_RATE, 'Daily Rate')} <span className="text-danger"></span>
                            </label>
                            <div className="col-sm-12">
                                <input type="text"  className="form-control clsFormInputControl" 
                                    placeholder={CommonUtil.getMessageText(Constants.DAILY_RATE, 'Daily Rate')} value={daily} onChange={onDailyRateChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-6">
                        <div className="form-group row">
                            <label className="col-sm-12 col-form-label">
                                {CommonUtil.getMessageText(Constants.WEEKLY_RATE, 'Weekly Rate')} <span className="text-danger"></span>
                            </label>
                            <div className="col-sm-12">
                                <input type="text"  className="form-control clsFormInputControl" 
                                    placeholder={CommonUtil.getMessageText(Constants.WEEKLY_RATE, 'Weekly Rate')} value={weekly} onChange={onWeeklyRateChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 col-6">
                        <div className="form-group row">
                            <label className="col-sm-12 col-form-label">
                                {CommonUtil.getMessageText(Constants.MONTHLY_RATE, 'Monthly Rate')} <span className="text-danger"></span>
                            </label>
                            <div className="col-sm-12">
                                <input type="text"  className="form-control clsFormInputControl" 
                                    placeholder={CommonUtil.getMessageText(Constants.MONTHLY_RATE, 'Monthly Rate')} value={monthly} onChange={onMonthlyRateChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-6">
                        <div className="form-group row">
                            <label className="col-sm-12 col-form-label">
                                Yearly Rate<span className="text-danger"></span>
                            </label>
                            <div className="col-sm-12">
                                <input type="text"  className="form-control clsFormInputControl" 
                                    placeholder={"Yearly Rate"} value={yearly} onChange={onYearlyRateChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 col-6">
                        <div className="form-group row">
                            <label className="col-sm-12 col-form-label">
                                {CommonUtil.getMessageText(Constants.DISTANCE_RATE, 'Distance Rate')} <span className="text-danger"></span>
                            </label>
                            <div className="col-sm-12">
                                <input type="text"  className="form-control clsFormInputControl" 
                                    placeholder={CommonUtil.getMessageText(Constants.DISTANCE_RATE, 'Distance Rate')} value={distance} onChange={onDistanceRateChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-6">
                        <div className="form-group row">
                            <label className="col-sm-12 col-form-label">
                                {CommonUtil.getMessageText(Constants.ENGINE_RATE, 'Engine Rate')} <span className="text-danger"></span>
                            </label>
                            <div className="col-sm-12">
                                <input type="text"  className="form-control clsFormInputControl" 
                                    placeholder={CommonUtil.getMessageText(Constants.ENGINE_RATE, 'Engine Rate')} value={engine} onChange={onEngineRateChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                <div className="modal-footer" style={{padding:"5px"}}>
                        <button type="button" className="btn btn-outline-dark"
                                data-dismiss="modal" onClick={handleClosePopUp}>
                            Close
                        </button>
                        <button type="submit" className="btn btn-success"
                                onClick={handleSavePopUp}>
                            Save
                        </button>
                </div>
            </div>
            </Modal>
        </>
    )
}

export default DNUDetails;
