import React, {useEffect, useRef, useState} from "react";
import {CommonUtil} from "../../utils/CommonUtil";
import EquipmentAvail from "./EquipmentAvail";
import {NetworkManager} from "../../Components/NetworkManager";
import ToastManager from "../../Components/ToastManager";
import {StatusCodeUtil} from "../../utils/StatusCodeUtil";
import EquipmentPrices from "./EquipmentPrices";
import EquipmentDocuments from "./EquipmentDocuments";
import EquipmentDamages from "./EquipmentDamages";

import $ from "jquery";
import BusyLoader from "../../Components/BusyLoader";
import {useNavigate} from "react-router-dom";
import EquipmentReservation from "./EquipmentReservation";
import EquipmentHistory from "./EquipmentHistory";
import ReservationDetails from "./ReservationDetails";
import ReserveActivation from "./ReserveActivation";
import ReserveDeActivation from "./ReserveDeActivation";
import ReserveClaims from "./ReserveClaims";
import DraggableComponent from "../../Components/DraggableComponent";
import SingleSelectComp from "../../Components/SingleSelectComp";
import {Modal} from "react-bootstrap";
import ReservationProcessDetails from "./ReservationProcessDetails";
import ReservationProcessDamages from "./ReservationProcessDamages";
import ReservationActivationLocation from "./ReservationActivationLocation";

var equiImages:any = [];
var attchIdx:number = 0;
var attachmentList: any = [];
const ReservationProcessView = ()=>{

    //const[equipImagesPath,setEquipmentImagesPath] = useState([]);
    const [equipImagesPath, setEquipImagesPath] = useState<any>([]);
    const [reservationData,setReservationData] = useState(null);
    const [equipmentList,setEquipmentList] = useState<any>([]);

    const [genInfo,setGenInfo] = useState(true);
    const [docs,setDocs] = useState(false);
    const [equipments,setEquipments] = useState(false);
    const [activation,setActivation] = useState(false);
    const [deactivation,setDeactivation] = useState(false);
    const [claims,setClaims] = useState(false);
    const [damages,setDamages] = useState(false);

    const [driver,setDriver] = useState("");
    const [reservationCode,setReservationCode] = useState("");

    const [reservationStatus,setReservationStatus] = useState("");


    const [equipmentData,setEquipmentData] = useState<any>(null);

    const [showVINPopup,setShowVINPopup] = useState(false);
    const [equipList,setEquipList] = useState<any>([]);
    const [equipObj,setEquipObj] = useState<any>(null);

    const [assetType,setAssetType] = useState("");
    const [equipType,setEquipType] = useState("");
    const [unitNumber,setUnitNumber] = useState("");
    const [vinNumber,setVinNumber] = useState("");

    const [rvinNumber,setRVinNumber] = useState("");
    const [isVinAvailable,setVinAvailable] = useState(false);
    const [brand,setBrand] = useState("");
    const [model,setModel] = useState("");
    const [year,setYear] = useState("");
    const [equipName,setEquipName] = useState("");

    const [numOfAxes,setNumOfAxes] = useState("");
    const [trailerLength,setTrailerLength] = useState("");
    const [license,setELicense] = useState("");
    const [expDate,setExpDate] = useState("");

    const [axelSpread,setAxelSpread] = useState("");
    const [tireInflation,setTireInflation] = useState("");
    const [skirts,setSkirts] = useState("");
    const [pintleHook,setPintleHook] = useState("");
    const [hazmat,setHazmat] = useState("");
    const [suspension,setSuspension] = useState("");

    const [rearDoor,setRearDoor] = useState("");
    const [sideDoor,setSideDoor] = useState("");
    const [roof,setRoof] = useState("");
    const [floor,setFloor] = useState("");
    const [floorType,setFloorType] = useState("");
    const [interior,setInterior] = useState("");
    const [logistics,setLogistics] = useState("");
    const [deckBeams,setDeckBeams] = useState("");

    const [liftType,setLiftType] = useState("");
    const [lift,setLift] = useState("");
    const [liftCap,setLiftCap] = useState("");

    const [unitCapacity,setUnitCapacity] = useState("");
    const [unitMake,setUnitMake] = useState("");
    const [unitModal,setUnitModal] = useState("");
    const [unitYear,setUnitYear] = useState("");
    const [multiTemp,setMultiTemp] = useState("");

    const [deActiveInstruction,setDeActive] = useState("");
    const [activeInstruction,setActivative] = useState("");

    const [emails,setEmails] = useState("");
    const [deposit,setDeposit] = useState("");
    const [imagePath,setImagePath] = useState("");

    const [showDriverPopup,setShowDriverPopup] = useState(false);
    const [driverList,setDriverList] = useState<any>([]);
    const [driverObj,setDriverObj] = useState<any>(null);
    const [startReservation,setStartReservation] = useState(false);
    // const [driversList, setDriversList] = useState<any>([]);


    const genRef = useRef<HTMLButtonElement>(null);
    const avlRef = useRef<HTMLButtonElement>(null);
    const priceRef = useRef<HTMLButtonElement>(null);
    const docRef = useRef<HTMLButtonElement>(null);
    const damageRef = useRef<HTMLButtonElement>(null);
    const reserveRef = useRef<HTMLButtonElement>(null);
    const historyRef = useRef<HTMLButtonElement>(null);

    const navigate = useNavigate();

    let deSelectButtons = ()=>{
        setGenInfo(false);
        setDocs(false);
        setEquipments(false);
        setActivation(false);
        setDeactivation(false);
        setClaims(false);
        setDamages(false);
    }

    /*let onClickGenInfo = ()=>{
        deSelectButtons();
        setGenInfo(true);
        genRef.current?.classList.add("equi-view-select-btn");
    }

    let onClickAvailability = ()=>{
        deSelectButtons();
        //setAvailability(true);
        avlRef.current?.classList.add("equi-view-select-btn");
    }

    let onClickPrice = ()=>{
        deSelectButtons();
        //setPrices(true);
        priceRef.current?.classList.add("equi-view-select-btn");
    }

    let onClickDocs = ()=>{
        deSelectButtons();
        setDocs(true);
        docRef.current?.classList.add("equi-view-select-btn");
    }

    let onClickDamages = ()=>{
        deSelectButtons();
        //setDamages(true);
        damageRef.current?.classList.add("equi-view-select-btn");
    }

    let onClickReservations = ()=>{
        deSelectButtons();
       // setReservations(true);
        reserveRef.current?.classList.add("equi-view-select-btn");
    }

    let onClickHistory = ()=>{
        deSelectButtons();
        //setEHistory(true);
        historyRef.current?.classList.add("equi-view-select-btn");
    }*/

    useEffect(()=>{
        //console.log(CommonUtil.SELECT_EQUIP_ID);
        //getEquipmentDetails();
        //CommonUtil.SELECT_EQUIP_ID = "32";
        //CommonUtil.SELECT_RESERVATION_ID = "5"

        $("#btnStartMyRental").show();
        if(CommonUtil.SELECT_RESERVATION_STATUS == CommonUtil.RESERVATION_COMPLETED){
            $("#btnStartMyRental").hide();
        }

        setStartReservation(false);
        getReservationDetails();
        getEquipmentDetails();
        //getAllDrivers();
    },[])



    let getAllDrivers = () => {
        let qParam = CommonUtil.getSaasOrgId();
        let orgUrl = "api/driver/all?saasOrgId=" + qParam;
        NetworkManager.sendJsonRequest(orgUrl, "", "", "GET", true, onGetAllDrivers, onLoginError);
    }

    let onGetAllDrivers = (data: any) => {
        if (data) {
            let restData = JSON.parse(data);
            if (restData) {
                if (restData.statusCode) {
                    console.log('error')
                } else {
                    let drivers = [];
                    if(Array.isArray(restData)){
                        drivers = restData;
                    }else{
                        drivers.push(restData);
                    }
                    setDriverList(drivers);
                }
            }
        }
    }

    let getReservationDetails = ()=>{
        let reservationAPI:string = "api/session/rp/reservation/"+CommonUtil.SELECT_RESERVATION_ID+"?rpKey="+CommonUtil.RP_KEY;
        NetworkManager.sendJsonRequest(reservationAPI,"","","GET",true,onGetReservationDetails,onLoginError);
    }

    let onGetReservationDetails = (dataObj:any)=>{
        console.log(dataObj);
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj)
            if (restData) {
                setReservationData(restData);
                setReservationCode(restData.reservationCode?restData.reservationCode:"-");
                // setDriver(restData.driverName?restData.driverName:"");
                setDriver(restData.driverName?restData.driverName:"");
                setRVinNumber(restData.vin?restData.vin:"");
                setVinAvailable(restData.vin?true:false);
                setReservationCode(restData.reservationCode);
                setReservationStatus(restData.reservationStatus);

                CommonUtil.EQUIP_LISTING_TYPE = restData.listingType;
                CommonUtil.ACTIVATION_HODO_METER = restData.activationOdometer;
            }
        }
    }

    let getEquipmentDetails = ()=>{
        attchIdx = 0;
        equiImages = [];
        if(CommonUtil.SELECT_EQUIP_ID){
            let equipmentAPI:string = "api/session/rp/equipment/"+CommonUtil.SELECT_EQUIP_ID+"?rpKey="+CommonUtil.RP_KEY;;
            let obj:any = {};
            NetworkManager.sendJsonRequest(equipmentAPI,"","","GET",true,onGetEquipmentDetails,onLoginError);
        }
    }

    let onGetEquipmentDetails = (dataObj:any)=>{
        //console.log(dataObj);
        if(dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                CommonUtil.IS_GENERIC_EQUIP = (restData.isGeneric == "Y"?true:false);
                setEquipmentData(restData);
                setEquipType(restData.equipmentType?restData.equipmentType:"-");
                setEquipName(restData.genericEquipmentName?restData.genericEquipmentName:"-");
                setUnitNumber(restData.unitNumber?restData.unitNumber:"-");
                setVinNumber(restData.VIN?restData.VIN:"-");

                setYear(restData.year?restData.year:"-");
                setModel(restData.model?restData.model:"-");
                setBrand(restData.brand?restData.brand:"-");
                setNumOfAxes(restData.numberOfAxles?restData.numberOfAxles:"-");
                setTrailerLength(restData.lengthOfTrailer?restData.lengthOfTrailer:"-");
                setELicense(restData.licensePlateNumber?restData.licensePlateNumber:"-");
                setExpDate(restData.expDate?restData.expDate:"-");
                setAssetType(restData.assetType?restData.assetType:"-");
                setExpDate(restData.annualMechanicalInspExpDate?restData.annualMechanicalInspExpDate:"-");

                setActivative(restData.activationInstructions?restData.activationInstructions:"-");
                setDeActive(restData.deactivationInstructions?restData.deactivationInstructions:"-");

                setEmails(restData.additionalEmailIds?restData.additionalEmailIds:"-");
                setDeposit(restData.securityDeposit?restData.securityDeposit:"-");

                CommonUtil.SELECT_EQUIPMENT_TYPE = restData.equipmentType;

                if(restData.equipmentType == "DRY VAN" ){
                    setImagePath("../../images/dryVan.png");
                }else if(restData.equipmentType == "PLATE DRY VAN" ){
                    setImagePath("../../images/plateDryVan1.png");
                }

                console.log(CommonUtil.getEquipemntImage(restData.equipmentType))
                setImagePath(CommonUtil.getEquipemntImage(restData.equipmentType));
                // getEquipemntImage
                CommonUtil.EQUIP_TRACKING_TYPE = restData.trackingType;
                if(restData.specifications){

                    if(restData.specifications.general){
                        setAxelSpread(restData.specifications.general.axleSpread?restData.specifications.general.axleSpread:"-");
                        setTireInflation(restData.specifications.general.tireInflationSystem?restData.specifications.general.tireInflationSystem:"-");
                        setSkirts(restData.specifications.general.skirts?restData.specifications.general.skirts:"-");
                        setPintleHook(restData.specifications.general.pintleHook?restData.specifications.general.pintleHook:"-");
                        setHazmat(restData.specifications.general.hazmatPlacardHolder?restData.specifications.general.hazmatPlacardHolder:"-");
                        setSuspension(restData.specifications.general.suspensionType?restData.specifications.general.suspensionType:"-");
                    }
                    if(restData.specifications.trailer){
                        setRearDoor(restData.specifications.trailer.rearDoor?restData.specifications.trailer.rearDoor:"-");
                        setSideDoor(restData.specifications.trailer.sideDoor?restData.specifications.trailer.sideDoor:"-");
                        setRoof(restData.specifications.trailer.roof?restData.specifications.trailer.roof:"-");
                        setFloor(restData.specifications.trailer.floor?restData.specifications.trailer.floor:"-");
                        setFloorType(restData.specifications.trailer.floorType?restData.specifications.trailer.floorType:"-");
                        setInterior(restData.specifications.trailer.interiorFinish?restData.specifications.trailer.interiorFinish:"-");
                        setLogistics(restData.specifications.trailer.logisticPostsDistance?restData.specifications.trailer.logisticPostsDistance:"-");
                        setDeckBeams(restData.specifications.trailer.doubleDeckBeams?restData.specifications.trailer.doubleDeckBeams:"-");
                    }
                    if (restData.specifications.lift) {
                        setLift(restData.specifications.lift.lift);
                        setLiftType(restData.specifications.lift.liftType);
                        setLiftCap(restData.specifications.lift.liftCapacity);
                    }

                    if (restData.specifications.reefer) {
                        setMultiTemp(restData.specifications.reefer.multiTemperature);
                        setUnitModal(restData.specifications.reefer.unitMake);
                        setUnitMake(restData.specifications.reefer.unitModel);
                        setUnitYear(restData.specifications.reefer.unitYear);
                    }
                }

                if (restData.attachments) {
                    attachmentList = []
                    let tempArray = [];
                    if (Array.isArray(restData.attachments)) {
                        tempArray = restData.attachments
                    } else {
                        tempArray.push(restData.attachments)
                    }

                    tempArray.forEach((tItem:any,idx:number)=>{
                        if(tItem.fullFilePath && tItem.documentType == CommonUtil.EQUIP_IMAGES){
                            attachmentList.push(tItem);
                        }
                    });

                    if (attachmentList.length > 0) {
                        BusyLoader.showLoader()
                        showAttachments()
                    }
                }
            }
        }
    }

    let showAttachments = () => {
        if (attachmentList.length > attchIdx) {
            let qParam: string = 'objectPath=' + attachmentList[attchIdx].fullFilePath
            NetworkManager.sendJsonRequest('api/session/downloadUrl', '', qParam, 'GET', false, (dataObj: any) => {
                    //if (attachmentList[attchIdx].documentType == CommonUtil.EQUIP_IMAGES) {
                        let obj: any = {}
                        obj.Type = attachmentList[attchIdx].documentType
                        obj.Path = dataObj
                        obj.attachementId = attachmentList[attchIdx].attachementId
                        equiImages.push(obj)
                   // }

                    attchIdx = attchIdx + 1
                    showAttachments()
                },
                onLoginError,
            )
        } else {
            BusyLoader.hideLoader()
            setEquipImagesPath(equiImages)
        }
    }



    let onClickTabs = (divTag:string)=>{
        let tabs:any = ['lnkDetails','lnkEquipment','lnkDamages'];
        let tabScreens:any = ['pnlDetails','pnlEquipment','pnlDamages'];

        tabs.forEach((tab:any,idx:number)=>{
            $("#"+tab).removeClass("active");
        });
        tabScreens.forEach((tab:any,idx:number)=>{
            $("#"+tab).removeClass("show active");
            $("#"+tab).addClass("fade");
        });

        $("#"+divTag).addClass("active");
        let tabScreen:string = "pnlDetails";

        if(divTag == "lnkDetails"){
            deSelectButtons();
            setGenInfo(true);
            tabScreen = 'pnlDetails';
        }else if(divTag == "lnkEquipment"){
            deSelectButtons();
            setEquipments(true);
            tabScreen = 'pnlEquipment';
        }else if(divTag == "lnkDamages"){
            deSelectButtons();
            setDamages(true);
            tabScreen = 'pnlDamages';
        }

        $("#"+tabScreen).addClass("show active");
    }

    let onEquipChange = (dataObj:any)=>{
        setEquipObj(dataObj);
    }

    let onCloseDriverPopup = ()=>{
        setShowDriverPopup(false);
    }

    let onDriverChange = (data:any)=>{
        setDriverObj(data);
    }

    let onClickAssignDriver = ()=>{
        setShowDriverPopup(true);
    }

    let onClickSaveDriver = ()=>{
        if(driverObj){
            let qParam:string = "driverId="+driverObj.driverId+"&reservationId="+CommonUtil.SELECT_RESERVATION_ID;
            let assignAPI:string = "api/reservation/assignDriver?"+qParam;

            NetworkManager.sendJsonRequest(assignAPI, "", "", "POST", true, onGetAssignDriver, onLoginError);
        }
    }

    let onGetAssignDriver = (dataObj:any)=>{
        //console.log(dataObj);
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj)
            if (restData) {
                ToastManager.showToast('Assign driver to reservation Successfully', 'Info');
                onCloseDriverPopup();
                setDriver(restData.driverName);
            }
        }
    }

    let onCloseVINPopup = ()=>{
        setShowVINPopup(false);
    }

    let onClickShowVIN = ()=>{
        //  if(!isVinAvailable){
        setShowVINPopup(true);
        getEquipmentList();
        //}
    }

    let getEquipmentList = ()=>{
        let equipAllAPI: string = 'api/equipment/available?saasOrgId='+CommonUtil.getSaasOrgId();
        NetworkManager.sendJsonRequest(equipAllAPI, '', '', 'GET', true, onGetAllEquipments, onLoginError);
    }

    let onGetAllEquipments = (dataObj:any)=>{
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj)
            if (restData) {
                let equips:any = []
                if (Array.isArray(restData)) {
                    equips = restData
                } else {
                    equips.push(restData)
                }
                setEquipmentList(equips);
                //setEquipData(equips);
            }
        }
    }

    let onClickSaveVIN = ()=>{
        if(equipObj){
            let qParam:string = "equipmentId="+equipObj.key+"&reservationId="+CommonUtil.SELECT_RESERVATION_ID;
            let assignAPI:string = "api/reservation/assignEquipment?"+qParam;

            NetworkManager.sendJsonRequest(assignAPI, "", "", "POST", true, onGetAssignEquipment, onLoginError);
        }
    }

    let onGetAssignEquipment = (dataObj:any)=>{
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj)
            if (restData) {
                setRVinNumber(restData.vin);
                setVinAvailable(restData.vin?true:false);
                ToastManager.showToast('Assign Equipment to reservation Successfully', 'Info');
                setShowVINPopup(false);
            }
        }
    }

    let onClickStartMyRental = ()=>{
        if(CommonUtil.SELECT_RESERVATION_IS_ANY_AVAILABLE || CommonUtil.IS_VIN_EXIST){
           // setStartReservation(true);
            let currentDayMS = new Date().getTime();
            let scheduleMs = Number(CommonUtil.SCHEDULED_START_DATE_MILLI_SECONDS);
            if(currentDayMS >=scheduleMs){
                setStartReservation(true);
            }else{
                let strUserStatus = "A reservation cannot be activated before the actual start date.";
                ToastManager.showToast(strUserStatus,"Info");
            }

        }else{
            let strUserStatus = "VIN number assignment is mandatory to proceed with the Reservation activation.";
            ToastManager.showToast(strUserStatus,"Info");
        }
    }

    let onClickDropLocBack = ()=>{
        getEquipmentDetails();
        getReservationDetails();
        setStartReservation(false);
    }

    let onReturnHome = ()=>{
        //navigate(-1);
        goBacktoHome();
    }

    let onClickBack = ()=>{
        //navigate(-1);
        goBacktoHome();
    }

    let goBacktoHome = ()=>{
        let siteOrigin:string = window.location.origin;
        // window.location.assign(siteOrigin);
        siteOrigin = siteOrigin+CommonUtil.RESERVATION_TOKEN;
        console.log(siteOrigin);
        //window.location.href = "http://www.google.com"
        //window.location.assign(siteOrigin);

        navigate(-1);

        /*setTimeout(()=>{
            navigate(-1);
        },1000);*/

    }

    let onLoginError = (err:any)=>{
        console.log(err);
    }
    return(
        <div className="no-margin">
            {startReservation?<ReservationActivationLocation onBack={onClickDropLocBack} returnToHome={onReturnHome}  />:""}
            {!startReservation?<div className="page-content-wrapper">
                <div className="resa-vation bg-dark text-white p-2 mb-1 d-block">
                    <div className="row m-0">
                        <div className="col-6 pr-0 pt-1" style={{paddingLeft:"0px"}}>
                            <h6 className="m-0">
                                <a  className="text-white" onClick={onReturnHome} style={{textDecoration:"none"}}>
                                    <i className="fa fa-arrow-left" ></i> &nbsp;
                                    My Reservations
                                </a>
                            </h6>
                        </div>
                        <div className="col-6 text-end">
                            <div className="row">
                                <div className="col-"></div>
                                <div className="col-"></div>
                            </div>

                            <button data-toggle="modal" data-target=".bs-example-modal-center-offer-details"
                                    type="submit"
                                    className="btn-sm btn-warning waves-effect waves-light d-inline-block">{CommonUtil.SELECT_RESERVATION_STATUS}
                            </button>
                            &nbsp;
                            <h6 className="mb-0 ml-2 d-inline-block">{reservationCode}</h6>
                        </div>
                    </div>
                </div>
                <div className="slider  p-1  d-block">
                    <div className="m-0">
                        <div className="col-12">
                            <div id="carouselExampleControls" className="carousel slide col-md-12 col-lg-4" data-bs-pause='true' style={{ height:150, overflow:'hidden', margin:'auto' }} >
                                <ol className="carousel-indicators">
                                    {equipImagesPath && equipImagesPath.map((image:any, index:any)=>{
                                        return(
                                            <li data-bs-target="#carouselExampleControls" data-bs-slide-to={`${index}`} className={index === 0 ? 'active': ''}></li>
                                        )
                                    })}
                                </ol>
                                <div className="carousel-inner" role="listbox">
                                    {equipImagesPath && equipImagesPath.map((image:any, index:any)=>{
                                        return(
                                            <div className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                                                <img className="d-block img-fluid mx-auto col-12" style={{objectFit:'fill',maxWidth:"60%" }} src={`${image?.Path}`} alt="" />
                                            </div>
                                        )
                                    })}
                                </div>
                                <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-bs-slide="prev">
                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span className="sr-only">Previous</span>
                                </a>
                                <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-bs-slide="next">
                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span className="sr-only">Next</span>
                                </a>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="container p-0" >
                    <div className="reservation-tabs1 indi" style={{height:"auto",overflow:"auto"}}>
                        <div className="d-xl-block m-0">
                            <ul className="nav nav-tabs nav-justified bg-primary pl-3 pr-3" id="myTab" role="tablist">
                                <li className="nav-item waves-effect waves-light col-4">
                                    <a className="nav-item nav-link active" data-toggle="tab"
                                       role="tab" aria-controls="public" aria-expanded="true" id="lnkDetails"
                                       onClick={()=>onClickTabs('lnkDetails')}>{CommonUtil.SELECT_EQUIP_AVAIL_TYPE}</a>
                                </li>
                                <li className="nav-item waves-effect waves-light col-4">
                                    <a className="nav-item nav-link" data-toggle="tab"
                                       role="tab" aria-controls="public" aria-expanded="true" id="lnkEquipment"
                                       onClick={()=>onClickTabs('lnkEquipment')}>Equipment</a>
                                </li>
                                <li className="nav-item waves-effect waves-light col-4">
                                    <a className="nav-item nav-link"  role="tab" data-toggle="tab" id="lnkDamages"
                                       onClick={()=>onClickTabs('lnkDamages')}>Damages</a>
                                </li>

                            </ul>
                        </div>
                        <div className="profile-tabs">
                            <div className="tab-content p-3" id="myTabContent" style={{width:"100%"}}>
                                <div className="tab-pane show active  mt-2" id="pnlDetails" role="tabpanel" style={{ marginBottom:100 }}
                                     aria-labelledby="group-dropdown2-tab" aria-expanded="false" >
                                    <ReservationProcessDetails reservationData={reservationData} />
                                </div>
                                <div role="tabpanel" className="tab-pane fade mt-2" id="pnlEquipment"
                                     aria-labelledby="public-tab" aria-expanded="true">
                                    {equipments &&  <div className="container">
                                        <section>
                                            <h5>General Details</h5>
                                            <hr />
                                            <div className="row">
                                                <div className="col-sm-2 col-6"><span className="details-head">Asset Type</span><label>{assetType}</label>
                                                </div>
                                                <div className="col-sm-2 col-12"><span className="details-head">Unit Number</span><label>{unitNumber}</label>
                                                </div>
                                                <div className="col-sm-2 col-12"><span className="details-head">VIN Number</span><label>{vinNumber}</label>
                                                </div>
                                                <div className="col-sm-2 col-6"><span
                                                    className="details-head">Brand </span><label>{brand}</label>
                                                </div>
                                                <div className="col-sm-2 col-6"><span
                                                    className="details-head">Model</span><label>{model}</label>
                                                </div>
                                                <div className="col-sm-2 col-6"><span
                                                    className="details-head">Year</span><label>{year}</label></div>
                                                <div className="col-sm-2 col-6"><span className="details-head">Number of Axles</span><label>{numOfAxes}</label>
                                                </div>
                                                <div className="col-sm-2 col-12"><span className="details-head">Length of Trailer</span><label>{trailerLength}</label>
                                                </div>
                                                <div className="col-sm-2 col-12"><span className="details-head">License Plate Number</span><label>{license}</label></div>
                                                <div className="col-sm-6 col-12"><span className="details-head">Annual Mechanical Expiration Date</span><label>{expDate}</label></div>
                                            </div>
                                        </section>
                                        <section>
                                            <h5>Specifications</h5>
                                            <hr />
                                            <h6>Specifications</h6>
                                            <div className="row">
                                                <div className="col-sm-2 col-6"><span className="details-head">Axle Spread</span><label>{axelSpread}</label>
                                                </div>
                                                <div className="col-sm-2 col-6"><span className="details-head">Tire Inflation System</span><label>{tireInflation}</label>
                                                </div>
                                                <div className="col-sm-2 col-6"><span
                                                    className="details-head">Skirts</span><label>{skirts}</label></div>
                                                <div className="col-sm-2 col-6"><span className="details-head">Pintle Hook</span><label>{pintleHook}</label>
                                                </div>
                                                <div className="col-sm-2 col-6"><span className="details-head">Hazmat Placard</span><label>{hazmat}</label>
                                                </div>
                                                <div className="col-sm-2 col-6">
                                                    <span className="details-head">Suspension</span><label>{suspension}</label></div>
                                            </div>
                                            <h6>Trailers</h6>
                                            <div className="row">
                                                <div className="col-sm-2 col-6"><span className="details-head">Rear Door</span><label>{rearDoor}</label>
                                                </div>
                                                <div className="col-sm-2 col-6"><span className="details-head">Side Door</span><label>{sideDoor}</label>
                                                </div>
                                                <div className="col-sm-2 col-6"><span
                                                    className="details-head">Roof</span><label>{roof}</label></div>
                                                <div className="col-sm-2 col-6"><span
                                                    className="details-head">Floor</span><label>{floor}</label></div>

                                                <div className="col-sm-2 col-6"><span className="details-head">Interior Finish</span><label>{interior}</label>
                                                </div>

                                                <div className="col-sm-4 col-6"><span className="details-head">Logistics Posts Distance</span><label>{logistics}</label>
                                                </div>
                                                <div className="col-sm-4 col-6"><span className="details-head">Double Deck Beams</span><label>{deckBeams}</label>
                                                </div>
                                            </div>
                                            <h6>Lift</h6>
                                            <div className="row">
                                                <div className="col-sm-2 col-6"><span
                                                    className="details-head">Lift</span><label>{lift}</label></div>
                                                <div className="col-sm-2 col-6"><span className="details-head">Lift Type</span><label>{liftType}</label>
                                                </div>
                                                <div className="col-sm-2 col-6"><span className="details-head">Unit Capacity</span><label>{liftCap}</label>
                                                </div>
                                                <div className="col-sm-2 col-6">
                                                    <span className="details-head">Floor</span><label>{floor}</label></div>
                                            </div>
                                            <h6>Temperature Control</h6>
                                            <div className="row">
                                                <div className="col-sm-2 col-6"><span className="details-head">Unit Make</span><label>{unitMake}</label>
                                                </div>
                                                <div className="col-sm-2 col-6"><span className="details-head">Unit Model</span><label>{unitModal}</label>
                                                </div>
                                                <div className="col-sm-2 col-6"><span className="details-head">Unit Year</span><label>{unitYear}</label>
                                                </div>
                                                <div className="col-sm-2 col-6"><span className="details-head">Multi Temperature</span><label>{multiTemp}</label>
                                                </div>
                                            </div>

                                        </section>
                                        <section></section>

                                    </div>
                                    }

                                </div>
                                <div className="tab-pane fade mt-2" id="pnlDamages" role="tabpanel"
                                     aria-labelledby="group-dropdown2-tab" aria-expanded="false">
                                    {damages && <ReservationProcessDamages equipData={equipmentData} getEquipData={getEquipmentDetails} />}

                                </div>

                                <div className="row fixed">
                                    <div className={`col-12 text-center ${reservationStatus == CommonUtil.RESERVATION_COMPLETED ? "d-none":""}`} style={{minHeight:"50px"}}>
                                        <button type="button" id={"btnStartMyRental"} className="btn btn-primary waves-effect mobile-button-height" style={{width:"150px"}} data-dismiss="modal" onClick={onClickStartMyRental}>
                                            {CommonUtil.RESERVATION_CATEGORY == CommonUtil.RESERVATION_ACTIVATION?"Start My "+CommonUtil.SELECT_EQUIP_AVAIL_TYPE:"End My "+CommonUtil.SELECT_EQUIP_AVAIL_TYPE}
                                        </button>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>

                </div>
            </div>:""}


        </div>
    )
}

export default ReservationProcessView;
