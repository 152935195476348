import useStateWithCallback from "use-state-with-callback";
import React, { useEffect, useRef, useState, useMemo } from "react";
import { IAGrid } from '../../Components/IInterfaces';
import { CommonUtil, timeZoneValues } from '../../utils/CommonUtil';
import { Constants } from '../Languages/Constants';
import Confirm from '../../Components/confirm';
import './Interchange.css'
import { Events } from '../Languages/Events';
import InspectionRenderer from '../InspectionGrid/InspectionRenderer';
import InterchangeEditRenderer from './InterchangeEditRenderer';
import SingleSelectComp from '../../Components/SingleSelectComp';

import ExitSubmitted from './ExitSubmitted';
import Flatpickr from "react-flatpickr";
import { loadMapApi } from '../../utils/GoogleMapsUtils';
import { useNavigate } from "react-router-dom";
import BusyLoader from '../../Components/BusyLoader';
import { NetworkManager } from '../../Components/NetworkManager';
import ToastManager from '../../Components/ToastManager';
import { Modal } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import InspectionGrid from '../InspectionGrid/InspectionGrid';
import DraggableComponent from '../../Components/DraggableComponent';
import './Interchange.css'
import InterchangeDocumentStatusRenderer from './InterchangeDocumentStatusRenderer';
import EntrySubmitted from './EntrySubmitted';
import ActiveClaim from './ActiveClaim';
import ClosedDocument from "./ClosedDocument";
import EntryInitiated from "./EntryInitiated";
import ExitInitiated from "./ExitInitiated";
import DelayedEntry from "./DelayedEntry";
import DelayedExit from "./DelayedExit";
import { forEachChild } from "typescript";
import AllClaims from "./AllClaims";
import InspectionNotMatch from "./InspectionNotMatch";
import CSVFiles from "./CSVFIles";
import IncEqpDetails from "./IncEqpDetails";
import BrokersWidget from "./BrokersWidget";
import AddBrokerDetailsModal from "./AddBrokerDetailsModal";
import CarriersWidget from "./CarriersWidget";
import AddCarrierDetailsModal from "./AddCarrierDetailsModal";

var userMode: string = "";
let userObj: any = null;

let truckAxels: any = [];
let trailerAxels: any = [];


var trailerDataList = [
    { Key: "1", Value: "1", position: "Axel-1", type: "single" },
    { Key: "2", Value: "2", position: "Axel-2", type: "single" },
    { Key: "3", Value: "3", position: "Axel-3", type: "single" },
    { Key: "4", Value: "4", position: "Axel-4", type: "single" },
    { Key: "5", Value: "5", position: "Axel-5", type: "single" },
    { Key: "6", Value: "6", position: "Axel-6", type: "single" },
    { Key: "7", Value: "7", position: "Axel-7", type: "single" },
    { Key: "8", Value: "8", position: "Axel-8", type: "single" },
    { Key: "9", Value: "9", position: "Axel-9", type: "single" },
    { Key: "10", Value: "10", position: "Axel-10", type: "single" }
];

var truckDataList = [
    { Key: "1", Value: "1", position: "Axel-1", type: "single" },
    { Key: "2", Value: "2", position: "Axel-2", type: "single" },
    { Key: "3", Value: "3", position: "Axel-3", type: "single" },
    { Key: "4", Value: "4", position: "Axel-4", type: "single" },
    { Key: "5", Value: "5", position: "Axel-5", type: "single" },
    { Key: "6", Value: "6", position: "Axel-6", type: "single" },
    { Key: "7", Value: "7", position: "Axel-7", type: "single" },
    { Key: "8", Value: "8", position: "Axel-8", type: "single" },
    { Key: "9", Value: "9", position: "Axel-9", type: "single" },
    { Key: "10", Value: "10", position: "Axel-10", type: "single" }
];

const InterchangeApplicationDetails = () => {

    const [users, setUsers] = useStateWithCallback([], () => {
        if (userGridRef1.current) {
            userGridRef1.current.columnFittoSize();
        }
    });

    const userGridRef1 = useRef<IAGrid>(null);
    const divRef = useRef<HTMLDivElement>(null);
    const insRef = useRef<HTMLInputElement>(null);

    const [showUserPopup, setShowUserPopup] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [modalShowCarr, setModalShowCarr] = useState(false);
    const [mode, setMode] = useState('new');

    const [phoneClass, setPhoneClass] = useState('mandatory');
    const [ownerContactClass, setOwnerContactClass] = useState('mandatory');

    const [documentId, setDocumentId] = useState("");
    const [referenceNo, setReferenceNo] = useState("");
    const [brokerName, setbrokerName] = useState("");
    const [carrierName, setCarrierName] = useState("");
    const [inspectionOrgId, setInspectionOrgId] = useState<any[]>([]);
    const [inspectionOrgNameObj, setInspectionOrgNameObj] = useState<any>(null);
    const [inspectionClass, setInspectionClass] = useState("mandatory");

    const [carrierDot, setCarrierDot] = useState("");
    const [carrierContactName, setCarrierContectName] = useState("");
    const [carrierContactNumber, setCarrierContactNumber] = useState<any>("");

    const [equipmentId, setEquipmentId] = useState<any[]>([]);
    const [equipmentObj, setEquipmentObj] = useState<any>(null);
    const [equipmentClass, setEquipmentClass] = useState("mandatory")

    const [manifestNumber, setManifestNumber] = useState<any>("");
    const [teBondNumber, setTeBondNumber] = useState("");
    const [sealNumber, setSealNumber] = useState<any>("");
    const [ownerContactNumber, setOwnerContactNumber] = useState<any>("");
    const [ownerCompanyContactName, setOwnerContactName] = useState("");
    const [expectedInterchangeEntryDate, setExpectedInterchangeEntryDate] = useState(new Date().toString());
    const [expectedinterchangeExitDate, setExpectedinterchangeExitDate] = useState(new Date().toString());
    const [expectedEntryLocation, setExpectedEntryLocation] = useState("");
    const [expectedExitLocation, setExpectedExitLocation] = useState("");
    const [expectedEntryId, setExpectedEntryId] = useState(0);
    const [expectedExitId, setExpectedExitId] = useState(0);
    const [expectedEntryTimeZone, setExpectedEntryTimeZone] = useState<any>({})
    const [expectedExitTimeZone, setExpectedExitTimeZone] = useState<any>({})

    const [scheduleCount, setScheduleCount] = useState("")
    const [entryCount, setEntryCount] = useState("")
    const [exitCount, setExitCount] = useState("");
    const [failedCount, setFailedCount] = useState("");
    const [claimCount, setClaimCount] = useState("");
    const [closedCount, setClosedCount] = useState("");
    const [entryInitiatedCount, setEntryInitiatedCount] = useState("")
    const [exitInitiatedCount, setExitInitiatedCount] = useState("")
    const [delayedEntryCount, setDelayedEntryCount] = useState("")
    const [delayedExitCount, setDelayedExitCount] = useState("")
    const [claimsCount, setClaimsCount] = useState("")
    const [inspNotMatchCount, setInspNotMatchCount] = useState("")
    const [incrrEqpDetails, setIncrrEqpDetails] = useState("")
    const [csvFilesCount, setCsvFilesCount] = useState("")
    const [brokersCount, setBrokersCount] = useState("")
    const [carriersCount, setCarriersCount] = useState("")

    const [isLoaded, setIsLoaded] = useState(CommonUtil.isLoaded);
    const [isLoadedObj, setIsLoadedObj] = useState<any>(null);
    const [isLoadedClass, setIsLoadedClass] = useState("mandatory");
    const [failedData, setFailedData] = useState(null);



    const [truckTire, setTruckTire] = useState(CommonUtil.truckAxles);
    const [truckTireObj, setTruckTireObj] = useState<any>(null);
    const [truckTireClass, setTruckTireClass] = useState("mandatory");

    const [trailerTire, setTrailerTire] = useState(CommonUtil.trailerAxles);
    const [trailerTireObj, setTrailerTireObj] = useState<any>(null);
    const [trailerTireClass, setTrailerTireClass] = useState("mandatory");

    const [truckReset, setTruckReset] = useState<any[]>([])
    const [trailerReset, setTrailerReset] = useState<any[]>([])
    const [truckData, setTruckData] = useState("");
    const [trailerData, setTrailerData] = useState("");
    const [truckList, setTruckList] = useState(false);
    const [trailerList, setTrailerList] = useState(false);

    const [isInternlUser, setIsInternlUser] = useState(false);
    const [isInspectionCompany, setIsInspectionCompany] = useState(false);




    const lnRef = useRef<HTMLInputElement>(null);
    const rnRef = useRef<HTMLInputElement>(null);
    const mnRef = useRef<HTMLInputElement>(null);
    const teRef = useRef<HTMLInputElement>(null);
    const snRef = useRef<HTMLInputElement>(null);
    const ocRef = useRef<HTMLInputElement>(null);
    const caRef = useRef<HTMLInputElement>(null);
    const cdRef = useRef<HTMLInputElement>(null);
    const ccRef = useRef<HTMLInputElement>(null);
    const entryAddress = useRef<HTMLInputElement>(null);
    const exitAddress = useRef<HTMLInputElement>(null);


    useEffect(() => {
        loadGooglePlaces();
    })

    useEffect(() => {
        let arr = [];
        for (let i = 0; i < 20; i++) {
            arr.push({ Key: (i + 1), Value: (i + 1) })
        }
        setTruckTire(arr);
        setTrailerTire(arr);
    }, [])

    let onClickSchedule = () => {
        getAllDocuments();
        localStorage.setItem("DocumentStatus", "SCHEDULED")
    }

    let onClickAudit = () => {
        getAllDocuments();
        localStorage.setItem("DocumentStatus", "EXIT")

    }

    let onClickEntrySUbmitted = () => {
        getAllDocuments();
        localStorage.setItem("DocumentStatus", "ENTRY")
    }

    const onClickCTPATFailed = () =>{
        getAllCTPATFailedDocuments();
        localStorage.setItem("DocumentStatus", "FAILED")
    }

    let onClickActiveClaim = () => {
        getAllDocuments();
        localStorage.setItem("DocumentStatus", "ACTIVE")

    }

    let onClickClosed = () => {
        getAllDocuments();
        localStorage.setItem("DocumentStatus", "CLOSED")

    }

    let onClickEntryInitiated = () => {
        getAllDocuments();
        localStorage.setItem("DocumentStatus", "ENTRYINITIATED")

    }

    let onClickExitInitiated = () => {
        getAllDocuments();
        localStorage.setItem("DocumentStatus", "EXITINITIATED")


    }

    let onClickDelayedEntry = () => {
        getAllDocuments();
        localStorage.setItem("DocumentStatus", "DELAYEDENTRY")


    }

    let onClickDelayedExit = () => {
        getAllDocuments();
        localStorage.setItem("DocumentStatus", "DELAYEDEXIT")

    }
    let onClickAllClaims = () => {
        getAllDocuments();
        localStorage.setItem("DocumentStatus", "CLAIMS")
    }
    let onClickAllCSVFiles = () => {
        getAllDocuments();
        localStorage.setItem("DocumentStatus", "CSVFILES")
    }
    let onClickAllInspNotMatch = () => {
        getAllDocuments();
        localStorage.setItem("DocumentStatus", "INSPNOTMATCH")

    }
    let onClickAllIncEqpDetails = () => {
        getAllDocuments();
        localStorage.setItem("DocumentStatus", "INCEQPDETAILS")
    }
    let onClickAllBrokerNames = () => {
        getAllDocuments();
        localStorage.setItem("DocumentStatus", "BROKERSWIDGET")
    }
    let onClickAllCarrierNames = () => {
        getAllDocuments();
        localStorage.setItem("DocumentStatus", "CARRIERSWIDGET")
    }

    let getDashboardCount = () => {
        var url = "api/interchangeDashBoard/dashBoarCount?ownerOrgId=" + CommonUtil.getSaasOrgId()
        NetworkManager.sendJsonRequest(url, "", "", "GET", true, onGetAllDashboardCount, onLoginError);

    }

    let onGetAllDashboardCount = (data: any) => {
        var restData = JSON.parse(data);
        if (localStorage.getItem('orgType') == "OWNER" || localStorage.getItem('orgType') == "OWNER_RENTER") {
            setScheduleCount(restData.scheduled)
            setEntryCount(restData.entryInspectionSubmiited)
            setExitCount(restData.returnInspectionSubmitted)
            setFailedCount(restData?.failedDocument)
            setClaimCount(restData.activeClaims)
            setClosedCount(restData.closed)
            setEntryInitiatedCount(restData.entryInitiated)
            setExitInitiatedCount(restData.exitInitiated)
            setDelayedEntryCount(restData.delayedEntry)
            setDelayedExitCount(restData.delayedExit)
            setClaimsCount(restData.claimsCount)
            setInspNotMatchCount(restData.mismatchedDocuments)
            setIncrrEqpDetails(restData.incorrectEquipment)
            setCsvFilesCount(restData.csvCount)
            setBrokersCount(restData.brokerCount)
            setCarriersCount(restData.carrierCount)
        } else {
            setScheduleCount("0")
            setEntryCount("0")
            setExitCount("0")
            setFailedCount("0")
            setClaimCount("0")
            setClosedCount("0")
            setEntryInitiatedCount("0")
            setExitInitiatedCount("0")
            setClaimsCount("0")
            setInspNotMatchCount("0")
            setIncrrEqpDetails("0")
            setCsvFilesCount("0")
            setBrokersCount("0")
            setCarriersCount("0")
        }
    }

    useEffect(() => {
        onClickEntrySUbmitted();
        getDashboardCount()
    }, [])

    let navigate = useNavigate();


    const [userColumns] = useState([
        { field: 'Actions', headerName: CommonUtil.getMessageText(Constants.EDIT, 'Actions'), resizable: true, width: 100, sortable: true, cellStyle: { 'fontWeight': '400', 'letterSpacing': '0px', 'fontSize': '12px', 'fontFamily': 'poppins', 'color': 'black', 'paddingTop': '5px' }, headerClass: 'header-white', cellRenderer: InterchangeEditRenderer },
        { field: 'documentCode', headerName: CommonUtil.getMessageText(Constants.DOCUMENT_ID, 'Document No'), resizable: true, flex: 1, width: 100, sortable: true, cellStyle: { 'fontWeight': '400', 'letterSpacing': '0px', 'fontSize': '12px', 'fontFamily': 'poppins', 'color': 'black', 'paddingTop': '5px' }, headerClass: 'header-white', cellRenderer: InspectionRenderer },
        { field: 'vinNumber', headerName: CommonUtil.getMessageText(Constants.VIN_NO, 'Vin Number'), resizable: true, flex: 1, width: 100, sortable: true, cellStyle: { 'fontWeight': '400', 'letterSpacing': '0px', 'fontSize': '13px', 'fontFamily': 'poppins', 'paddingTop': '5px' }, headerClass: 'header-white' },
        { field: 'unitNumber', headerName: CommonUtil.getMessageText(Constants.UNIT_NUMBER, 'Unit Number'), resizable: true, flex: 1, width: 100, sortable: true, cellStyle: { 'fontWeight': '400', 'letterSpacing': '0px', 'fontSize': '13px', 'fontFamily': 'poppins', 'paddingTop': '5px' }, headerClass: 'header-white' },
        { field: 'equipmentType', headerName: CommonUtil.getMessageText(Constants.EQUIP_TYPE, 'Equipment Type'), resizable: true, flex: 1, width: 100, sortable: true, cellStyle: { 'fontWeight': '400', 'letterSpacing': '0px', 'fontSize': '13px', 'fontFamily': 'poppins', 'paddingTop': '5px' }, headerClass: 'header-white' },
        { field: 'status', headerName: CommonUtil.getMessageText(Constants.STATUS, 'Status'), resizable: true, flex: 1, width: 100, sortable: true, cellStyle: { 'fontWeight': '400', 'letterSpacing': '0px', 'fontSize': '13px', 'fontFamily': 'poppins', 'paddingTop': '5px' }, headerClass: 'header-white', cellRenderer: InterchangeDocumentStatusRenderer },
        { field: 'expectedEntryTime', headerName: CommonUtil.getMessageText(Constants.ENTRY_DATE, 'Expected Release Date'), resizable: true, flex: 1, width: 100, sortable: true, cellStyle: { 'fontWeight': '400', 'letterSpacing': '0px', 'fontSize': '13px', 'fontFamily': 'poppins', 'paddingTop': '5px' }, headerClass: 'header-white', valueGetter: (params: any) => CommonUtil.getDateInterchange([params.data.expectedReciptDate]) },
        { field: 'expectedExitTime', headerName: CommonUtil.getMessageText(Constants.EXIT_DATE, 'Expected Receipt Date'), resizable: true, flex: 1, width: 100, sortable: true, cellStyle: { 'fontWeight': '400', 'letterSpacing': '0px', 'fontSize': '13px', 'fontFamily': 'poppins', 'paddingTop': '5px' }, headerClass: 'header-white', valueGetter: (params: any) => CommonUtil.getDateInterchange([params.data.expectedReleaseDate]) },
        { field: 'referenceNo', headerName: CommonUtil.getMessageText(Constants.REFERENCE_NUMBER, 'Reference No'), resizable: true, flex: 1, width: 100, sortable: true, cellStyle: { 'fontWeight': '400', 'letterSpacing': '0px', 'fontSize': '13px', 'fontFamily': 'poppins', 'paddingTop': '5px' }, headerClass: 'header-white' },
        { field: 'createdBy', headerName: CommonUtil.getMessageText(Constants.CREATED_BY, 'Created By'), resizable: true, flex: 1, width: 100, sortable: true, cellStyle: { 'fontWeight': '400', 'letterSpacing': '0px', 'fontSize': '13px', 'fontFamily': 'poppins', 'paddingTop': '5px' }, headerClass: 'header-white' },
        { field: 'creationDate', headerName: CommonUtil.getMessageText(Constants.CREATED_DATE, 'Created Date'), flex: 1, width: 100, sortable: true, cellStyle: { 'fontWeight': '400', 'letterSpacing': '0px', 'fontSize': '13px', 'fontFamily': 'poppins', 'paddingTop': '5px' }, headerClass: 'header-white', valueGetter: (params: any) => CommonUtil.getDateInterchange([params.data.creationDate]) },
    ]);


    let onClickEditDocument = (data: any) => {
        console.log(data)
        setExpectedEntryId(data.expectedEntryLocationId)
        setExpectedExitId(data.expectedExitLocationId)
        console.log(expectedEntryId)
        localStorage.setItem("documentId", data.documentId);
        let qParam = CommonUtil.getInterchangeDocumentId()
        let url = "api/InspectionDocuments/" + qParam
        NetworkManager.sendJsonRequest(url, "", "", "GET", true, editDocument, onLoginError);
        setShowUserPopup(true);
    }



    let onClickUserDetails = (userData: any) => {
        console.log(userData)
        localStorage.setItem("documentId", userData.documentId);
        localStorage.getItem("DocumentStatus")
        navigate("/menu/InterchangeApplication/interchangeDocumentDetails");
    }

    let onClickAddUser = () => {
        clearUserData();
        userMode = CommonUtil.CREATE_MODE;
        setModalShow(true);
    }
    let onClickAddCarrier = () => {
        clearUserData();
        userMode = CommonUtil.CREATE_MODE;
        setModalShowCarr(true);
    }

    let onCloseUserPopup = () => {
        setModalShow(false);
        setModalShowCarr(false)
    }


    let onIsLoadedChange = (data: any) => {
        setIsLoadedObj(data)
    }

    let onTruckChange = (data: any) => {
        if (data) {
            setTruckTireObj(data)
            setTruckList(true)
            setTruckData(data.Value)
            setTruckReset([]);
            try {
                const diffInAxleNo = equipmentObj?.noOfAxles - data.Value;
                if (diffInAxleNo > 0)
                    setTrailerTireObj("")
                // setTrailerList(false);
                setTrailerReset([]);
                setTimeout(() => {
                    if (diffInAxleNo > 0) {
                        setTrailerTireObj({ Key: diffInAxleNo, Value: diffInAxleNo });
                        setTrailerList(true)
                        setTrailerData(diffInAxleNo.toString());
                    }
                });

            } catch (e) { }
        } else {
            setTruckTireObj("")
            setTruckList(false)
            // setTrailerList(false);
            setTruckData("")
            setTruckReset([]);
            // setTrailerTireObj("")
            setTrailerReset([]);
            truckAxels = [];
        }

    }

    let onEntryTimezone = (data: any) => {
        if (data) {
            setExpectedEntryTimeZone(data);
        } else {
            setExpectedEntryTimeZone({})
        }
    }
    let onExitTimezone = (data: any) => {
        if (data) {
            setExpectedExitTimeZone(data);
        } else {
            setExpectedExitTimeZone({})
        }
    }

    let onTrailerChange = (data: any) => {
        if (data) {
            setTrailerTireObj(data)
            setTrailerList(true)
            setTrailerData(data.Value)
            setTrailerReset([]);
        } else {
            setTrailerTireObj("")
            setTrailerList(false);
            setTrailerData("");
            setTrailerReset([]);
            trailerAxels = [];
        }
    }

    let onEquipmentChange = (data: any) => {
        console.log("Send me eqipment id", data);
        setEquipmentObj(data);
    }

    let getAllInspectionCompanies = () => {
        var orgUrl = "api/ownerInspectionOrgAssociation/associatedInspection?OwnerOrgId=" + CommonUtil.getSaasOrgId();
        NetworkManager.sendJsonRequest(orgUrl, "", "", "GET", true, onGetAllInspectionCompanies, onLoginError);

    }

    let onGetAllInspectionCompanies = (data: any) => {
        if (data) {
            var restData = CommonUtil.parseData(data);
            if (restData) {
                console.log(restData)
                setInspectionOrgId(restData.map((d: any) => {
                    return { Value: d.inspectionOrgName, Key: d.saasOrgId }
                }))
                console.log(inspectionOrgId)
            }
        }
    }

    let getAllEquiment = () => {
        var qParam = CommonUtil.getSaasOrgId();
        var orgUrl = "api/equipment/getAllEquipmentNonGenerics?ownerOrgId=" + qParam
        NetworkManager.sendJsonRequest(orgUrl, "", "", "GET", true, onGetAllEquipments, onLoginError);
    }

    let onGetAllEquipments = (data: any) => {
        if (data) {
            var restData = CommonUtil.parseData(data);
            console.log(restData)
            if (restData) {
                setEquipmentId(restData.map((d: any) => {
                    // console.log(d.noOfAxles)
                    return {
                        Value: d.isGeneric === "N" || '' ? d.unitNumber
                            : "", Key: d.equipmentId, noOfAxles: d.noOfAxles
                    }
                }))
                console.log(equipmentId)
            }
        }
    }

    useEffect(() => {
        truckAxels = [];
        trailerAxels = [];
        getAllInspectionCompanies();
        getAllEquiment();
    }, [])

    useEffect(() => {
        if (showUserPopup)
            getInterchangeMenuEnabled();
    }, [showUserPopup])


    let getInterchangeMenuEnabled = () => {
        let interchangeAPI: string = 'api/organization/interchangeMenuEnabled?saasOrgId=' + CommonUtil.getSelectedOrgId();
        let obj: any = {};
        //  http://localhost:8080/api/organization/interchangeMenuEnabled?saasOrgId=45
        NetworkManager.sendJsonRequest(interchangeAPI, '', '', 'GET', true, onGetInterchangeMenuEnabled, onLoginError);
    }

    let onGetInterchangeMenuEnabled = (dataObj: any) => {
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                console.log("-----------", restData);
                setIsInternlUser(restData.isInternlUser == "Y" ? true : false);
                setIsInspectionCompany(restData.isInspectionCompany == "Y" ? true : false);
                if (restData.isInternlUser == "Y" || restData.isInspectionCompany == "Y") {
                    try {
                        let obj: any = inspectionOrgId.filter((ele: any) => ele.Key == CommonUtil.getSaasOrgId())[0];
                        if (obj) {
                            setInspectionOrgNameObj(obj);
                        } else {
                            setInspectionOrgNameObj({ Key: CommonUtil.getSaasOrgId(), Value: CommonUtil.getSelectedOrgName() });
                        }
                    }
                    catch (e) { }
                }

            }
        }
    }


    let onCreateDocument = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        var isLN: boolean = CommonUtil.isMandatory(lnRef);
        var isRN: boolean = CommonUtil.isMandatory(rnRef);
        var isMN: boolean = CommonUtil.isMandatory(mnRef);
        var isTE: boolean = CommonUtil.isMandatory(teRef);
        var isSE: boolean = CommonUtil.isMandatory(snRef);
        var isEA: boolean = CommonUtil.isMandatory(entryAddress);
        var isEE: boolean = CommonUtil.isMandatory(exitAddress);
        var isOC: boolean = true //CommonUtil.isMandatory(ocRef);
        var isCA: boolean = true//CommonUtil.isMandatory(caRef);
        var isCD: boolean = true // CommonUtil.isMandatory(cdRef);
        var isCC: boolean = true //CommonUtil.isMandatory(ccRef);
        var isContact: boolean = true;
        var isOwnerContact: boolean = true;
        //if(!isInternlUser && !isInspectionCompany){
        // (inspectionOrgNameObj)?setInspectionClass("select-box-valid"):setInspectionClass("select-box-invalid");
        if (!!inspectionOrgNameObj?.Key) {
            setInspectionClass("select-box-valid");
        } else {
            setInspectionClass("select-box-invalid");
            return;
        }
        //}

        (equipmentObj) ? setEquipmentClass("select-box-valid") : setEquipmentClass("select-box-invalid");
        (isLoadedObj) ? setIsLoadedClass("select-box-valid") : setIsLoadedClass("select-box-invalid");
        //   (truckTireObj)?setTruckTireClass("select-box-valid"):setTruckTireClass("select-box-invalid");
        (trailerTireObj) ? setTrailerTireClass("select-box-valid") : setTrailerTireClass("select-box-invalid");
        if (!expectedEntryTimeZone?.value) {
            return;
        }
        if (!expectedExitTimeZone?.value) {
            return;
        }

        if (!carrierContactNumber.trim() || carrierContactNumber.length < 11) {
            if (carrierContactNumber.length > 1) {
                isContact = false;
                setPhoneClass("is-invalid");
            }
        }

        //2473 if (!ownerContactNumber.trim() || ownerContactNumber.length < 11) {
        //     isOwnerContact = false
        //     setOwnerContactClass('is-invalid')
        // }  

        if (isLN && isOC && isCA && isCD && isCC && isEA && isEE && equipmentObj && isLoadedObj && trailerTireObj && isContact && isOwnerContact) {
            var restObj: any = {};
            restObj.inspectionDocument = {};
            restObj.inspectionDocument.carrierName = carrierName;
            if (inspectionOrgNameObj?.Key)
                restObj.inspectionDocument.inspectionOrgId = inspectionOrgNameObj ? inspectionOrgNameObj.Key : '';
            restObj.inspectionDocument.referenceNo = referenceNo;
            restObj.inspectionDocument.brokerName = brokerName;
            restObj.inspectionDocument.status = "SCHEDULED";
            restObj.inspectionDocument.isLoaded = isLoadedObj ? isLoadedObj.Key : "";
            restObj.inspectionDocument.carrierDot = carrierDot;
            restObj.inspectionDocument.carrierContactName = carrierContactName;
            restObj.inspectionDocument.carrierPhoneNumber = parseInt(carrierContactNumber);
            restObj.inspectionDocument.equipmentId = equipmentObj ? equipmentObj.Key : '';
            restObj.inspectionDocument.manifestNumber = manifestNumber;
            restObj.inspectionDocument.teBondNumber = teBondNumber;
            restObj.inspectionDocument.inspectionDocType = "inspection";
            restObj.inspectionDocument.interchangeId = 1;
            restObj.inspectionDocument.equipmentId = parseInt(equipmentObj ? equipmentObj.Key : '');
            restObj.inspectionDocument.sealNumber = sealNumber;
            restObj.inspectionDocument.ownerContactPhone = parseInt(ownerContactNumber);
            restObj.inspectionDocument.ownerOrgId = CommonUtil.getSaasOrgId();
            restObj.inspectionDocument.ownerCompanyContactName = ownerCompanyContactName;
            restObj.inspectionDocument.expectedEntryTime = expectedInterchangeEntryDate;
            restObj.inspectionDocument.expectedExitTime = expectedinterchangeExitDate;
            restObj.inspectionDocument.expectedEntryLocationId = 12;
            restObj.inspectionDocument.expectedExitLocationId = 13;
            restObj.inspectionDocument.expectedEntryTimeZone = expectedEntryTimeZone?.value;
            restObj.inspectionDocument.expectedExitTimeZone = expectedExitTimeZone?.value;
            restObj.expectedEntryLocation = {};
            restObj.expectedExitLocation = {};
            //   restObj.expectedEntryTimeZone = {};
            //   restObj.expectedExitTimeZone = {};
            restObj.expectedEntryLocation.rawAddress = expectedEntryLocation;
            restObj.expectedExitLocation.rawAddress = expectedExitLocation;

            restObj.axle = {};
            //   restObj.axle.track = [{position : truckTireObj?truckTireObj.Key:'',type:truckAxleType}];
            //   restObj.axle.trailer = [{position : trailerTireObj?trailerTireObj.Key:'',type:trailerAxleType}];
            restObj.axle.track = truckAxels;
            restObj.axle.trailer = trailerAxels;

            if (userMode == CommonUtil.UPDATE_MODE) {
                if (userObj) {
                    restObj.documentId = userObj.documentId;
                }
            }
            var userUrl = "";
            var method = "POST";
            var qParam = CommonUtil.getInterchangeDocumentId()
            if (userMode == CommonUtil.CREATE_MODE) {
                userUrl = "api/InspectionDocuments/";
            } else {
                method = "PUT";
                userUrl = "api/InspectionDocuments/" + qParam
            }
            BusyLoader.showLoader()
            NetworkManager.sendJsonRequest(userUrl, restObj, "", method, true, onDocumentCreate, onLoginError);
        }
    }


    let onDocumentCreate = (data: any) => {
        if (data) {
            var restData = CommonUtil.parseData(data);
            if (restData) {
                onCloseUserPopup();
                let strSuccess = CommonUtil.getMessageText(Constants.USER_CREATED_SUCCESS, "Document Created Successfully");
                ToastManager.showToast(strSuccess, "Info");
                getAllDocuments();
                getDashboardCount();
            }
        }
    }

    const todayDate = new Date();
    const formatDate = todayDate.getDate() < 10 ? `0${todayDate.getDate()}` : todayDate.getDate();
    const formatMonth = todayDate.getMonth() < 10 ? `0${todayDate.getMonth()}` : todayDate.getMonth();
    const formattedDate = [todayDate.getFullYear(), formatMonth, formatDate].join('-');
    const [expenseDate, setExpenseDate] = useState(formattedDate);

    const parsePhoneNumber = (phoneNumberString: any) => {
        try {
            let phoneNumber: any = phoneNumberString.replace(/\s/g, "");
            phoneNumber = phoneNumber.replace(/[^\w\s]/gi, '');
            if (!phoneNumber.match(/^[\d]+$/)) {
                return "";
            }
            return String(parseInt(phoneNumber));
        } catch (e) {
            return ''
        }
    }

    let editDocument = (userData: any) => {
        console.log(userData)
        userMode = CommonUtil.UPDATE_MODE;
        if (userData) {
            var restData = JSON.parse(userData);
            console.log(restData)
            setTruckTireObj({ Key: restData?.axle?.truck?.length, Value: restData?.axle?.truck?.length })
            setTrailerTireObj({ Key: restData?.axle?.trailer?.length, Value: restData?.axle?.trailer?.length })
            setEquipmentObj({ Key: restData.equipment.equipmentId, Value: restData.equipment.unitNumber, noOfAxles: restData.equipment.noOfAxles })
            setTruckData(restData.axle.truck.length);
            setTruckReset(restData.axle.truck);
            setTruckList(true);
            setTrailerData(restData.axle.trailer.length);
            setTrailerReset(restData.axle.trailer)
            setTrailerList(true);
            setIsLoadedObj({ Key: restData.inspectionDocument?.isLoaded, Value: restData.inspectionDocument?.isLoaded })
            setReferenceNo(restData.inspectionDocument?.referenceNo)
            setbrokerName(restData.inspectionDocument?.brokerName)
            setManifestNumber(restData.inspectionDocument?.manifestNumber)
            try {
                setExpectedEntryTimeZone(timeZoneValues.filter((ele: any) => ele.value === restData.inspectionDocument?.expectedEntryTimeZone)[0])
            } catch (e) { }
            try {
                setExpectedExitTimeZone(timeZoneValues.filter((ele: any) => ele.value === restData.inspectionDocument?.expectedExitTimeZone)[0])
            } catch (e) { }
            setTeBondNumber(restData.inspectionDocument?.teBondNumber)
            setSealNumber(restData.inspectionDocument?.sealNumber)
            setOwnerContactName(restData.inspectionDocument?.ownerCompanyContactName)
            setCarrierName(restData.inspectionDocument?.carrierName)
            setCarrierDot(restData.inspectionDocument?.carrierDot)
            setCarrierContectName(restData.inspectionDocument?.carrierContactName)
            setOwnerContactNumber(parsePhoneNumber(restData.inspectionDocument?.ownerContactPhone?.toString()))
            let entryTime = restData.inspectionDocument?.expectedEntryTime.indexOf("T") === -1 ? (restData.inspectionDocument?.expectedEntryTime?.split(' ')[0]) : (restData.inspectionDocument?.expectedEntryTime?.split('T')[0]);
            setExpectedInterchangeEntryDate(entryTime)
            let exitTime = restData.inspectionDocument?.expectedExitTime?.indexOf("T") === -1 ? (restData.inspectionDocument?.expectedExitTime?.split(' ')[0]) : (restData.inspectionDocument?.expectedExitTime?.split('T')[0]);
            setExpectedinterchangeExitDate(exitTime)
            setCarrierContactNumber(parsePhoneNumber(restData.inspectionDocument?.carrierPhoneNumber?.toString()))
            setExpectedEntryLocation(restData.expectedEntryLocation.rawAddress)
            setExpectedExitLocation(restData.expectedExitLocation.rawAddress)
            setExpectedEntryId(restData.inspectionDocument?.expectedEntryLocationId)
            setExpectedExitId(restData.inspectionDocument?.expectedExitLocationId)
            setInspectionOrgNameObj({ Key: restData.inspectionDocument?.inspectionOrgId, Value: restData.inspectionOrgName })
            console.log(userData)
            console.log(expectedInterchangeEntryDate)
            console.log(expectedinterchangeExitDate)
        }
    }



    let onInspectionChange = (dataObj: any) => {
        setInspectionOrgNameObj(dataObj)
        console.log(dataObj)
    }

    let onChangeEntryDate = (dateValue: any) => {

        let strDate: string = CommonUtil.getDate(dateValue)
        console.log(strDate)
        setExpectedInterchangeEntryDate(strDate);


    }

    let onChangeExitDate = (dateValue: any) => {
        let strDate: string = CommonUtil.getDate(dateValue)
        console.log(strDate)
        setExpectedinterchangeExitDate(strDate);

    }

    // Failed CTPAT
    let getAllCTPATFailedDocuments = () => {
        setUsers([]);
        var orgUrl = `api/InspectionDocuments/all?ownerOrgId=${CommonUtil.getSaasOrgId()}&status=FAILED`;
        NetworkManager.sendJsonRequest(orgUrl, "", "", "GET", true, onGetAllDocuments, onLoginError);
    }


    let getAllDocuments = () => {
        setUsers([]);
        var orgUrl = `api/InspectionDocuments/all?ownerOrgId=${CommonUtil.getSaasOrgId()}&status=SCHEDULED`;
        NetworkManager.sendJsonRequest(orgUrl, "", "", "GET", true, onGetAllDocuments, onLoginError);
    }

    let onLoginError = (err: any) => {
        console.log(err);
    }

    let clearUserData = () => {

        setReferenceNo("");
        setCarrierName("");
        setInspectionOrgNameObj(null);
        setCarrierDot("");
        setCarrierContectName("");
        setCarrierContactNumber("");
        setEquipmentObj(null);
        setIsLoadedObj(null);
        setManifestNumber("");
        setExpectedEntryTimeZone("");
        setExpectedExitTimeZone("");
        setTeBondNumber("");
        setSealNumber("");
        setOwnerContactNumber("");
        setOwnerContactName("");
        setExpectedInterchangeEntryDate("");
        setExpectedinterchangeExitDate("");
        setExpectedEntryLocation("");
        setExpectedExitLocation("");
        setTrailerTireObj(null);
        setTruckTireObj(null);
        setTruckList(false);
        setTrailerList(false);
        setTruckReset([]);
        setTrailerReset([]);

    }

    useEffect(() => {
        getAllDocuments();
    }, [])


    let onGetAllDocuments = (dataObj: any) => {
        //   console.log(dataObj);
        if (dataObj) {
            var restData = JSON.parse(dataObj);
            console.log(restData)
            if (restData) {
                restData.map((org: any) => {
                    //   console.log(org.documentId)
                })
            }
            setUsers(restData)
            if (restData) {
                var users = [];
                if (Array.isArray(restData)) {
                    users = restData;
                } else {
                    users.push(restData);

                }
                let ascendingUsers: any = [...restData].sort((a, b) => {
                    return b.documentId - a.documentId
                })
                setUsers(ascendingUsers)
                console.log(users)
            }

        }

    }


    let deleteDocument = (data: any) => {
        console.log(data)
        localStorage.setItem("documentId", data.documentId);
        Confirm.show('Confirm', 'Do you want delete the document?', [{
            label: 'Yes', callback: function () {
                let qParam = CommonUtil.getInterchangeDocumentId();
                let removeApi = "api/InspectionDocuments/" + qParam
                NetworkManager.sendJsonRequest(removeApi, '', '', 'DELETE', true, onDeleteDocument, onLoginError);
            },
        },
        { label: 'No' },
        ])
    }

    let onDeleteDocument = (dataObj: any) => {
        if (dataObj) {
            let strRemove = CommonUtil.getMessageText(Constants.REMOVED_SUCCESS, 'Document Deleted Successfully');
            ToastManager.showToast(strRemove, 'Info');
        } else {
            ToastManager.showToast("Error", 'Error')
        }
        getAllDocuments();
        getDashboardCount();
    }

    let loadGooglePlaces = () => {
        if (window.google) {
            getEntryGooglePlaces();
            getExitGooglePlaces();
        } else {
            const googleMapScript = loadMapApi();
            if (googleMapScript) {
                googleMapScript.addEventListener('load', function () {
                    getEntryGooglePlaces();
                    getExitGooglePlaces();
                })
            }
        }
    }

    let getEntryGooglePlaces = () => {

        const address = document.getElementById("address") as HTMLInputElement;
        CommonUtil.getPlaceAddressByMap(address, (addr: any) => {
            //console.log(result);
            setExpectedEntryLocation(addr['rawAddress']);
        });
    }

    let getExitGooglePlaces = () => {

        const address = document.getElementById("address1") as HTMLInputElement;
        CommonUtil.getPlaceAddressByMap(address, (addr: any) => {
            //console.log(result);
            setExpectedExitLocation(addr['rawAddress']);
        });
    }


    let onSwitchCustomer = (event: any) => {
        Events.dispatchBreadCrumbEvent();
    }

    useEffect(() => {
        document.addEventListener(Events.SWITCH_CUSTOMER_EVENT, onSwitchCustomer);
    })




    return (

        <>
            <div className='containData'>
                <div className="admin-wrapper container-fluid p-1">
                    <div className="container sec-wrapper py-3 px-3">
                        <div className="row">
                            {/* <div className="col-sm-6 col-xl-2">
                    <div className="card-features">
                        <a onClick={onClickSchedule}>
                            <div className={`card ${localStorage.getItem("DocumentStatus") === "SCHEDULED"?"active":""}`}>
                                <div className="card-body">
                                    <div className="product-pop">
                                        <span className="product-pop-label" onClick={onClickAddUser}> <i className="fa-solid fa-plus small"
                                                 ></i></span>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <div className="feature-icon">
                                            <img src="../../images/calender2.svg" alt="calender"/>
                                        </div>

                                        <div className="text-wrapper">
                                            <p className="count text-dark">{scheduleCount}</p>
                                            <span className="w400">Scheduled</span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </a>
                    </div>

            </div> */}

                            <div className="col-sm-6 col-xl-2">
                                <div className="card-features">
                                    <a onClick={onClickEntrySUbmitted}>
                                        <div className={`card ${localStorage.getItem("DocumentStatus") === "ENTRY" ? "active" : ""}`}>
                                            <div className="card-body">
                                                <div className="d-flex align-items-center">
                                                    <div className="feature-icon">
                                                        <img src="../../images/entryfile.svg" alt="calender" />
                                                    </div>
                                                    <div className="text-wrapper">
                                                        <p className="count text-dark">{entryCount}</p>
                                                        <span className="">Trailer Release Submitted</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>

                            <div className="col-sm-6 col-xl-2">
                                <div className="card-features">
                                    <a onClick={onClickAudit}>
                                        <div className={`card ${localStorage.getItem("DocumentStatus") === "EXIT" ? "active" : ""}`}>
                                            <div className="card-body">
                                                <div className="d-flex align-items-center">
                                                    <div className="feature-icon">
                                                        <img src="../../images/returnfile.svg" alt="calender" />
                                                    </div>
                                                    <div className="text-wrapper">
                                                        <p className="count text-dark">{exitCount}</p>
                                                        <span className="">Trailer Receipt Submitted/Pending Audit</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div className="col-sm-6 col-xl-2">
                                <div className="card-features">
                                    <a onClick={onClickAllInspNotMatch}>
                                        <div
                                            className={`card ${localStorage.getItem("DocumentStatus") === "INSPNOTMATCH" ? "active" : ""}`}
                                        >
                                            <div className="card-body">
                                                <div className="d-flex align-items-center">
                                                    <div className="feature-icon">
                                                        <img src="../../images/returnfile.svg" alt="calender" />
                                                    </div>

                                                    <div className="text-wrapper">
                                                        <p className="count text-dark">{inspNotMatchCount}</p>
                                                        <span className="">Inspection Mismatch</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>

                            {/* <div className="col-sm-6 col-xl-2">
                    <div className="card-features">
                        <a onClick={onClickActiveClaim}>
                            <div className={`card ${localStorage.getItem("DocumentStatus") === "ACTIVE"?"active":""}`}>
                                <div className="card-body">
                                    <div className="d-flex align-items-center">
                                        <div className="feature-icon">
                                            <img src="../../images/claimfile.svg" alt="calender"/>
                                        </div>

                                        <div className="text-wrapper">
                                            <p className="count text-dark">{claimCount}</p>
                                            <span className="">Active Claim</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                </div> */}

                            <div className="col-sm-6 col-xl-2">
                                <div className="card-features">
                                    <a onClick={onClickClosed}>
                                        <div className={`card ${localStorage.getItem("DocumentStatus") === "CLOSED" ? "active" : ""}`}>
                                            <div className="card-body">
                                                <div className="d-flex align-items-center">
                                                    <div className="feature-icon">
                                                        <img src="../../images/closed.svg" alt="calender" />
                                                    </div>

                                                    <div className="text-wrapper">
                                                        <p className="count text-dark">{closedCount}</p>
                                                        <span className="">Closed</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>

                            <div className="col-sm-6 col-xl-2">
                                <div className="card-features">
                                    <a onClick={onClickEntryInitiated}>
                                        <div className={`card ${localStorage.getItem("DocumentStatus") === "ENTRYINITIATED" ? "active" : ""}`}>
                                            <div className="card-body">
                                                <div className="d-flex align-items-center">
                                                    <div className="feature-icon">
                                                        <img src="../../images/MaskGroup35.svg" alt="calender" />
                                                    </div>

                                                    <div className="text-wrapper">
                                                        <p className="count text-dark">{entryInitiatedCount}</p>
                                                        <span className="">Trailer Release Initiated</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>

                            <div className="col-sm-6 col-xl-2">
                                <div className="card-features">
                                    <a onClick={onClickExitInitiated}>
                                        <div className={`card ${localStorage.getItem("DocumentStatus") === "EXITINITIATED" ? "active" : ""}`}>
                                            <div className="card-body">
                                                <div className="d-flex align-items-center">
                                                    <div className="feature-icon">
                                                        <img src="../../images/MaskGroup37.svg" alt="calender" />
                                                    </div>

                                                    <div className="text-wrapper">
                                                        <p className="count text-dark">{exitInitiatedCount}</p>
                                                        <span className="">Trailer Receipts Initiated</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>

                            {/* <div className="col-sm-6 col-xl-2">
                    <div className="card-features">
                        <a onClick={onClickDelayedEntry}>
                            <div className={`card ${localStorage.getItem("DocumentStatus") === "DELAYEDENTRY"?"active":""}`}>
                                <div className="card-body">
                                    <div className="d-flex align-items-center">
                                        <div className="feature-icon">
                                            <img src="../../images/delayentry.svg" alt="calender"/>
                                        </div>

                                        <div className="text-wrapper">
                                            <p className="count text-dark">{delayedEntryCount}</p>
                                            <span className="">Delayed Releases</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                          </div> */}


                            {/* <div className="col-sm-6 col-xl-2">
                    <div className="card-features">
                        <a onClick={onClickDelayedExit}>
                            <div className={`card ${localStorage.getItem("DocumentStatus") === "DELAYEDEXIT"?"active":""}`}>
                                <div className="card-body">
                                    <div className="d-flex align-items-center">
                                        <div className="feature-icon">
                                            <img src="../../images/delayexit.svg" alt="calender"/>
                                        </div>
                                        <div className="text-wrapper">
                                            <p className="count text-dark">{delayedExitCount}</p>
                                            <span className="">Delayed Receipts</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                          </div> */}
                            <div className="col-sm-6 col-xl-2">
                                <div className="card-features">
                                    <a onClick={onClickAllClaims}>
                                        <div
                                            className={`card ${localStorage.getItem("DocumentStatus") === "CLAIMS" ? "active" : ""}`}
                                        >
                                            <div className="card-body">
                                                <div className="d-flex align-items-center">
                                                    <div className="feature-icon">
                                                        <img src="../../images/claimfile.svg" alt="calender" />
                                                    </div>

                                                    <div className="text-wrapper">
                                                        <p className="count text-dark">{claimsCount}</p>
                                                        <span className="">Claims</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div className="col-sm-6 col-xl-2">
                                <div className="card-features">
                                    <a onClick={onClickAllCSVFiles}>
                                        <div
                                            className={`card ${localStorage.getItem("DocumentStatus") === "CSVFILES" ? "active" : ""}`}
                                        >
                                            <div className="card-body">
                                                <div className="d-flex align-items-center">
                                                    <div className="feature-icon">
                                                        <img src="../../images/claimfile.svg" alt="calender" />
                                                    </div>

                                                    <div className="text-wrapper">
                                                        <p className="count text-dark">{csvFilesCount}</p>
                                                        <span className="">CSV Files</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div className="col-sm-6 col-xl-2">
                                <div className="card-features">
                                    <a onClick={onClickAllIncEqpDetails}>
                                        <div
                                            className={`card ${localStorage.getItem("DocumentStatus") === "INCEQPDETAILS" ? "active" : ""}`}
                                        >
                                            <div className="card-body">
                                                <div className="d-flex align-items-center">
                                                    <div className="feature-icon">
                                                        <img src="../../images/claimfile.svg" alt="calender" />
                                                    </div>

                                                    <div className="text-wrapper">
                                                        <p className="count text-dark">{incrrEqpDetails}</p>
                                                        <span className="">Incorrect Equipment Details</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div className="col-sm-6 col-xl-2">
                                <div className="card-features">
                                    <a onClick={onClickAllBrokerNames}>
                                        <div
                                            className={`card ${localStorage.getItem("DocumentStatus") === "BROKERSWIDGET" ? "active" : ""}`}
                                        >
                                            <div className="card-body">
                                                <div className="product-pop">
                                                    <span className="product-pop-label" onClick={onClickAddUser}> <i className="fa-solid fa-plus small"
                                                    ></i></span>
                                                </div>
                                                <div className="d-flex align-items-center">
                                                    <div className="feature-icon">
                                                        <img src="../../images/claimfile.svg" alt="calender" />
                                                    </div>

                                                    <div className="text-wrapper">
                                                        <p className="count text-dark">{brokersCount}</p>
                                                        <span className="">Brokers</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div className="col-sm-6 col-xl-2">
                                <div className="card-features">
                                    <a onClick={onClickAllCarrierNames}>
                                        <div
                                            className={`card ${localStorage.getItem("DocumentStatus") === "CARRIERSWIDGET" ? "active" : ""}`}
                                        >
                                            <div className="card-body">
                                                <div className="product-pop">
                                                    <span className="product-pop-label" onClick={onClickAddCarrier}> <i className="fa-solid fa-plus small"
                                                    ></i></span>
                                                </div>
                                                <div className="d-flex align-items-center">
                                                    <div className="feature-icon">
                                                        <img src="../../images/claimfile.svg" alt="calender" />
                                                    </div>

                                                    <div className="text-wrapper">
                                                        <p className="count text-dark">{carriersCount}</p>
                                                        <span className="">Carriers</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            {/* <div className="col-sm-6 col-xl-2">
                                <div className="card-features">
                                    <a onClick={onClickCTPATFailed}>
                                        <div className={`card ${localStorage.getItem("DocumentStatus") === "EXIT" ? "active" : ""}`}>
                                            <div className="card-body">
                                                <div className="d-flex align-items-center">
                                                    <div className="feature-icon">
                                                        <img src="../../images/closed.svg" alt="calender" />
                                                    </div>
                                                    <div className="text-wrapper">
                                                        <p className="count text-dark">{failedCount}</p>
                                                        <span className="">Failed</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div> */}

                            {CommonUtil.getDocumentStatus() === "SCHEDULED" ? (
                                <div ref={divRef} className="container-fluid container-fluid-padding mt-3" style={{ backgroundColor: 'white' }}>
                                    <div className="row fs-7 pt-1">
                                        <div className="ag-theme-customeTheme gridHeight" style={{ height: "calc(100vh - 330px)", width: "100%", paddingLeft: "8px", paddingRight: "5px" }}>
                                            <InspectionGrid cols={userColumns} rows={users} pageSize={100} addNew={true} tooltip={"Add Company"} name={"Add Company"}
                                                headerHeight={CommonUtil.GRID_HEADER_HEIGHT} rowHeight={CommonUtil.GRID_ROW_HEIGHT} ref={userGridRef1} method={{ onClickUserDetails, onClickEditDocument, deleteDocument }} addNewRecord={onClickAddUser} />
                                        </div>
                                    </div>
                                </div>
                            ) : ""}

                            {CommonUtil.getDocumentStatus() === "EXIT" ? (
                                <ExitSubmitted />
                            ) : ""}
                            {CommonUtil.getDocumentStatus() === "ENTRY" ? (
                                <EntrySubmitted />
                            ) : ""}
                            {CommonUtil.getDocumentStatus() === "ACTIVE" ? (
                                <ActiveClaim />
                            ) : ""}
                            {
                                CommonUtil.getDocumentStatus() === "CLOSED" ? (
                                    <ClosedDocument />
                                ) : ""}
                            {
                                CommonUtil.getDocumentStatus() === "ENTRYINITIATED" ? (
                                    <EntryInitiated />
                                ) : ""}
                            {
                                CommonUtil.getDocumentStatus() === "EXITINITIATED" ? (
                                    <ExitInitiated />
                                ) : ""}
                            {
                                CommonUtil.getDocumentStatus() === "DELAYEDENTRY" ? (
                                    <DelayedEntry />
                                ) : ""}
                            {
                                CommonUtil.getDocumentStatus() === "DELAYEDEXIT" ? (
                                    <DelayedExit />
                                ) : ""}
                            {
                                CommonUtil.getDocumentStatus() === "CLAIMS" ? (
                                    <AllClaims />
                                ) : ""
                            }
                            {
                                CommonUtil.getDocumentStatus() === "INSPNOTMATCH" ? (
                                    <InspectionNotMatch />
                                ) : ""
                            }
                            {
                                CommonUtil.getDocumentStatus() === "CSVFILES" ? (
                                    <CSVFiles />
                                ) : ""
                            }
                            {
                                CommonUtil.getDocumentStatus() === "INCEQPDETAILS" ? (
                                    <IncEqpDetails />
                                ) : ""
                            }
                            {
                                CommonUtil.getDocumentStatus() === "BROKERSWIDGET" ? (
                                    <BrokersWidget brokersCount={brokersCount} />
                                ) : ""
                            }
                            {
                                CommonUtil.getDocumentStatus() === "CARRIERSWIDGET" ? (
                                    <CarriersWidget carriersCount={carriersCount} />
                                ) : ""
                            }
                             {CommonUtil.getDocumentStatus() === "FAILED" ? (
                                <EntrySubmitted isFailed={true} failedUsers={users}/>
                            ) : ""}
                            {<AddBrokerDetailsModal mode={mode} open={modalShow} handleClose={onCloseUserPopup} getDashboardCount={getDashboardCount} />}
                            {<AddCarrierDetailsModal mode={mode} open={modalShowCarr} handleClose={onCloseUserPopup} getDashboardCount={getDashboardCount} />}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default InterchangeApplicationDetails


const AxleComp = ({ startIndex, refid, axleNum, axleUpdated, editData }: any) => {
    const [truckReset, settruckReset] = useState([]);
    useEffect(() => {
        try {
            let tempArr: any = [];
            if (editData?.length > 0) {
                tempArr = editData.map((ele: any) => ele);
            } else {
                for (let i = 0; i < Number(axleNum); i++) {
                    tempArr.push({
                        position: `Axle-${i + Number(startIndex) + 1}`,
                        type: "single"
                    })
                }
            }
            settruckReset(tempArr);
        } catch (e) {
            console.log(e)
        }

    }, [axleNum, editData, startIndex]);


    useEffect(() => {
        //    console.log(truckReset);
        axleUpdated(truckReset);
    }, [truckReset])
    const onChangeType = (pos: any, selected: string) => {
        const arrayData: any = truckReset.map((ele: any) => {
            let obj: any = ele;
            if (pos === obj.position) {
                obj.type = selected;
            }
            return obj;
        })
        settruckReset(arrayData);
    }
    return (
        <>


            <table className="table" style={{ border: "none" }}>
                <thead>
                    <tr>
                        <th>Axles</th>
                        <th>Single</th>
                        <th>Dual</th>
                    </tr>
                </thead>
                <tbody>
                    {truckReset.map((data: any) => {
                        return (
                            <>
                                <tr>
                                    <td>{data.position}</td>
                                    <td ><input type="radio" value="single" name={refid + data.position} onChange={(e) => onChangeType(data.position, "single")} checked={data.type === "single"}></input></td>
                                    <td><input type="radio" value="dual" name={refid + data.position} onChange={(e) => onChangeType(data.position, "dual")} checked={data.type === "dual"}></input></td>
                                </tr>
                            </>)
                    })}
                </tbody>
            </table>

        </>
    )
}


