import React, { useState } from "react";
import { Constants } from "../Languages/Constants";
import { CommonUtil } from "../../utils/CommonUtil";

const InvoiceFilter = (props: any) => {
    const [duration,setDuration] = useState("7");
  return (
    <div className="row pt-2 pb-3">
      <div className="col-md-4 col-lg-4 pt-2">        
        <select
          name="duration"
          className={"form-control"}
          onChange={(e)=>{setDuration(e?.target?.value)}}
          value={duration}
        >
          <option value="7">Last 7 days</option>
          <option value="30">Last 30 days</option>
          <option value="90">Last 90 days</option>
        </select>
      </div>
      <div className="col-md-4 col-lg-4 pt-2">        
            <input  type="text" className="form-control "
                required={true} placeholder="Reservation Code"
                value={""}
                // onChange={(e)=>{setReservationCode(e.target.value)}}
            />
      </div>
      <div className="col-md-4 col-lg-4 pt-2">
        <button
          type="button"
          className="btn btn-warning cus-btn btn-sm button-height"
          // onClick={}
        >
          {CommonUtil.getMessageText(Constants.SEARCH, "Search")}
        </button>
      </div>
    </div>
  );
};

export default InvoiceFilter;
